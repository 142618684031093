import * as React from 'react';
import 'isomorphic-fetch';
import {ILoadingState} from '../common/common';
import IRouteProps from '../common/IRouteProps';
import {withRouter} from 'react-router';
import configurationService from "../common/services";

interface IWebHooksProps extends IRouteProps {
}

interface IWebHooksState extends ILoadingState<IWebHooks> {
}

interface IWebHooks {
    webHooks: Components.Schemas.StoredWebHook[];
}

class WebHooks extends React.Component<IWebHooksProps, IWebHooksState> {

    constructor(props: IWebHooksProps) {
        super(props);

        this.state = {
            loading: true,
            state: {
                webHooks: []
            }
        };
    }
    
    componentDidMount() {
        this.getAllWebHooks();
    }

    getAllWebHooks() {
        configurationService.getTenantToken()
            .then(tenantToken => {
                fetch(`/api/webHooks?messageTypes=Duplicates`, {
                    method: 'GET',
                    headers: {'Authorization': `Basic ${tenantToken}`}
                })
                    .then(response => response.json() as Promise<Paths.ApiWebHooks.Get.Responses.$200>)
                    .then(data => {
                        this.setState({loading: false, state: {webHooks: data}});
                    });
            });
    }

    removeWebHook(clientId: string, id: number) {
        configurationService.getTenantToken()
            .then(tenantToken => {
                fetch(`/api/webHooks/${id}`, {
                    method: 'DELETE',
                    headers: {'Authorization': `Basic ${tenantToken}`}
                }).then(response => {
                    if (response.status === 200) {
                        this.getAllWebHooks();
                    }
                });
            });
    }

    public render() {
        const {state} = this.state;
        return <div className="card mt-2">
            <div className="card-body">
                <h4 className="card-title">Registered web hooks</h4>
                <div className="row show-grid">
                    <div className="col-12">
                        <div className="row show-grid-no-line fw-bold">
                            <div className="col-md-2">Client</div>
                            <div className="col-md-8">URL</div>
                            <div className="col-md-2"></div>
                        </div>
                        {
                            state.webHooks.map(webHook => {
                                return <div key={webHook.id} className="row show-grid-no-line">
                                    <div className="col-md-2">{webHook.clientId}</div>
                                    <div className="col-md-8"><code>{webHook.details.url}</code></div>
                                    <div className="col-md-2 text-right">
                                        <button type="button" className="btn btn-default btn-xs"
                                                onClick={() => this.removeWebHook(webHook.clientId, webHook.id)}>Remove
                                        </button>
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(WebHooks);
