import React from 'react';
import 'isomorphic-fetch';
import IRouteProps from '../common/IRouteProps';
import {withRouter} from 'react-router';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Col,
    Row, Table, CardBody, Card
} from "reactstrap";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ApiVersion, getError} from "../common/common";
import moment from 'moment';
import 'moment-duration-format';
import EmyPagination from "../common/EmyPagination";
import Loader from "../common/Loader";
import ModalTrack from "./tracks/ModalTrack";

interface ITracksSearchProps extends IRouteProps {
    appConfig: Components.Schemas.EmyConfiguration,
    tenantToken: string
}

interface ITrackInsertValidation {
    trackIdValid? : boolean,
    titleValid?: boolean,
    artistValid?: boolean,
    mediaSourceFileValid? : boolean,
    mediaUrlValid?: boolean
}

interface ITrackMetaFields {
    key: string,
    value: string
    keyValid?: boolean,
    valueValid?: boolean,
    newTrackMetaFields?: {
        [name: string]: string;
    },
}

interface ITracksSearchState {
    searchResults: Components.Schemas.TrackInfos;
    searchTerm: string;
    minLength: number;
    maxLength: number;
    offset: number,
    searched: boolean;
    searching: boolean;
    modalTrack: Components.Schemas.TrackInfo | undefined;
    modalTrackDelete: Components.Schemas.TrackInfo | undefined;
    deletingTrack: boolean,
    deleteTrackError: string,
    newTrackId: string,
    newTrackTitle: string,
    newTrackArtist: string
    newTrackFile: File | null,
    newTrackMediaUrl: string,
    errorToDisplay: string,
    successToDisplay: string,
    newTrackSubmitting: boolean,
    newTrackMetaFields: ITrackMetaFields,
    newTrackValidation: ITrackInsertValidation
}

class Tracks extends React.Component<ITracksSearchProps, ITracksSearchState> {

    static readonly PageSize = 50;

    constructor(props: ITracksSearchProps) {
        super(props);

        this.state = {
            searchTerm: "",
            minLength: 0,
            maxLength: 10 * 60,
            offset: 0,
            searchResults: {
                results: [],
                totalCount: 0
            },
            searched: false,
            searching: false,
            modalTrack: undefined,
            modalTrackDelete: undefined,
            deletingTrack: false, 
            deleteTrackError: '',
            newTrackId: this.uuidv4(),
            newTrackTitle: '',
            newTrackArtist: '',
            newTrackFile: null,
            newTrackMediaUrl: '',
            errorToDisplay: '',
            successToDisplay: '',
            newTrackSubmitting: false,
            newTrackMetaFields: {
                key: '',
                value: '',
                newTrackMetaFields: undefined
            },
            newTrackValidation: {}
        };
        
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDetailsView = this.handleDetailsView.bind(this);
        this.toggleTrackDelete = this.toggleTrackDelete.bind(this);
        this.handleTrackDelete = this.handleTrackDelete.bind(this);
        this.handleTrackDeleteConfirm = this.handleTrackDeleteConfirm.bind(this);
        this.handleTrackFilePathChange = this.handleTrackFilePathChange.bind(this);
        this.handleTrackMediaUrlChange = this.handleTrackMediaUrlChange.bind(this);
        this.handleMetaKeyChange = this.handleMetaKeyChange.bind(this);
        this.handleMetaValueChange = this.handleMetaValueChange.bind(this);
        this.handleNewMetaFieldAdd = this.handleNewMetaFieldAdd.bind(this);
        this.handleNewMetaFieldsDelete = this.handleNewMetaFieldsDelete.bind(this);
        this.handleMinLengthChange = this.handleMinLengthChange.bind(this);
        this.handleMaxLengthChange = this.handleMaxLengthChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleNextPageClick = this.handleNextPageClick.bind(this);
        this.handlePrevPageClick = this.handlePrevPageClick.bind(this);
    }

    componentDidMount() {
        this.search();
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    handleSearchTermChange = (event: any) => {
        let term = event.target.value;
        this.setState({
            searchTerm: term ?? '',
            offset: 0
        }, () => this.search());
    }

    handlePageClick = (page: number) => {
        this.setState(() => {
            return {offset: (page - 1) * Tracks.PageSize}
        }, () => this.search()); 
    }
    
    handleNextPageClick = () => {
        this.setState(currentState => {
            return {offset: currentState.offset + Tracks.PageSize}
        }, () => this.search());
    }

    handlePrevPageClick = () => {
        this.setState(currentState => {
            return {offset: currentState.offset - Tracks.PageSize}
        }, () => this.search());
    }

    search() {
        let {searchTerm, offset, minLength, maxLength} = this.state;
        let {tenantToken} = this.props;
        
        this.setState({searching: true}, () => {

            fetch(`/api/${ApiVersion}/tracks?q=${searchTerm}&offset=${offset}&limit=${Tracks.PageSize}&minLength=${minLength}&maxLength=${maxLength}`, {
                method: 'GET',
                headers: {'Authorization': `Basic ${tenantToken}`},
            })
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Could not retrieve tracks.")
                    }
                    
                    return response;
                })
                .then(response => response.json() as Promise<Paths.ApiV1Tracks.Get.Responses.$200>)
                .then(tracks => {
                    this.setState({searchResults: tracks, searched: true, searching: false});
                })
                .catch(error =>{
                    this.setState({
                        errorToDisplay: error.message,
                        searched: true,
                        searching: false
                    });
                });
        });
    }
    
    handleDetailsView(track: Components.Schemas.TrackInfo) {
        this.setState({
            modalTrack: track
        });
    }

    toggle() {
        const {modalTrack} = this.state;
        if(modalTrack) {
            this.setState({
                modalTrack: undefined
            });
        }
    }
    
    toggleTrackDelete() {
        const {modalTrackDelete} = this.state;
        if(modalTrackDelete) {
            this.setState({
                modalTrackDelete: undefined
            });
        } 
    }
    
    handleTrackDelete(track: Components.Schemas.TrackInfo) {
        this.setState({
            modalTrackDelete: track
        });
    }

    handleTrackDeleteConfirm(track: Components.Schemas.TrackInfo) {
        this.setState({
            deleteTrackError: '',
            deletingTrack: true
        });

        const {tenantToken} = this.props;
        fetch(`/api/v1/tracks/${track.id}`, {
            method: 'DELETE',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async response => {
           if (!response.ok) {
               throw await getError(response);
           }
            
           return await response.json() as Promise<Paths.ApiV1Streams.Delete.Responses.$200>;
        })
        .then(() => {
            this.setState({
                deletingTrack: false
            }, () => {
                this.toggleTrackDelete();
                this.search();
            })
        })
        .catch(error => {
            this.setState({
                deleteTrackError: error.message
            });
        });
    }

    handleNewTrackIdChanged(newTrackId: string) {
        const {newTrackValidation} = this.state;
        newTrackValidation.trackIdValid = newTrackId !== "" && newTrackId !== null;
        this.setState({
            newTrackId: newTrackId,
            newTrackValidation: newTrackValidation
        });
    }

    handleTrackTitleChange(newTrackTitle: string) {
        const {newTrackValidation} = this.state;
        newTrackValidation.titleValid = true;
        this.setState({
            newTrackTitle: newTrackTitle,
            newTrackValidation: newTrackValidation
        });
    }

    handleTrackArtistChange(newTrackArtist: string){
        const {newTrackValidation} = this.state;
        newTrackValidation.artistValid = true;
        this.setState({
            newTrackArtist: newTrackArtist,
            newTrackValidation: newTrackValidation
        });
    }

    handleTrackFilePathChange = (event: any) => {
        const {newTrackValidation} = this.state;
        newTrackValidation.mediaSourceFileValid = true;
        if(newTrackValidation.mediaUrlValid === false) {
            newTrackValidation.mediaUrlValid = undefined;
        }
        
        this.setState({
            newTrackFile: event.target.files[0],
            newTrackValidation: newTrackValidation
        });
    }
    
    handleTrackMediaUrlChange = (event: any) => {
        const {newTrackValidation} = this.state;
        if(newTrackValidation.mediaSourceFileValid === false) {
            newTrackValidation.mediaSourceFileValid = undefined;
        }
        
        try {
            new URL(event.target.value);
            newTrackValidation.mediaUrlValid = true;
        }
        catch(_) {
            newTrackValidation.mediaUrlValid = false;
        }
        
        this.setState({
            newTrackMediaUrl: event.target.value,
            newTrackValidation:  newTrackValidation
        })
    }
    
    handleMetaKeyChange(event: any){
        const {newTrackMetaFields} = this.state;
        newTrackMetaFields.key = event.target.value;
        newTrackMetaFields.keyValid = newTrackMetaFields.key !== '' && newTrackMetaFields.key !== null;
        this.setState({
            newTrackMetaFields: newTrackMetaFields
        });
    }
    
    handleMetaValueChange(event: any) {
        const {newTrackMetaFields} = this.state;
        newTrackMetaFields.value = event.target.value;
        newTrackMetaFields.valueValid = newTrackMetaFields.value !== '' && newTrackMetaFields.value !== null;
        this.setState({
            newTrackMetaFields: newTrackMetaFields
        }); 
    }

    handleNewMetaFieldAdd(event: any) {
        const {newTrackMetaFields} = this.state;
        if(newTrackMetaFields.key === '' || newTrackMetaFields.value === '') {
            newTrackMetaFields.keyValid = newTrackMetaFields.key !== '';
            newTrackMetaFields.valueValid = newTrackMetaFields.value !== '';
            this.setState({
                newTrackMetaFields: newTrackMetaFields
            });
            return;
        }
        
        let metaFields = newTrackMetaFields.newTrackMetaFields ?? {};
        metaFields[newTrackMetaFields.key] = newTrackMetaFields.value;
        newTrackMetaFields.newTrackMetaFields = metaFields;
        newTrackMetaFields.key = '';
        newTrackMetaFields.value = '';
        newTrackMetaFields.keyValid = newTrackMetaFields.valueValid = undefined;
        this.setState({
            newTrackMetaFields: newTrackMetaFields
        });
    }
    
    handleNewMetaFieldsDelete(key: string) {
        const {newTrackMetaFields} = this.state;
        delete newTrackMetaFields.newTrackMetaFields![key];
        this.setState({
            newTrackMetaFields: newTrackMetaFields
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const {newTrackId, newTrackFile, newTrackMediaUrl, newTrackValidation} = this.state;
        if(!newTrackFile && newTrackMediaUrl === '') {
            newTrackValidation.mediaSourceFileValid = false;
            this.setState({
                newTrackValidation: newTrackValidation
            });
            return;
        }
        
        this.setState({
            errorToDisplay: '',
            successToDisplay: '', 
            newTrackSubmitting: true
        });
        
        const createTrackPromise = newTrackFile ? this.postTrack() : this.putTrack();
        createTrackPromise.then(async result => {
            if (!result.ok) {
                let error = await getError(result);
                this.setState({
                    errorToDisplay: error.message,
                    newTrackSubmitting: false
                });
                return;
            }
            
            this.setState({
                newTrackId: this.uuidv4(),
                newTrackTitle: '',
                newTrackArtist: '',
                newTrackFile: null,
                newTrackMediaUrl: '',
                errorToDisplay: '',
                successToDisplay: `Track ${newTrackId} successfully inserted`,
                newTrackSubmitting: false,
                newTrackValidation: {},
                newTrackMetaFields: {
                    newTrackMetaFields: {},
                    key: '',
                    value: '',
                    keyValid: undefined,
                    valueValid: undefined
                }
            },() => {
                this.search();
            });
        });
    }
    
    handleMinLengthChange(value: string) {
        this.setState({
            minLength: +value
        }, () => this.search());
    }
    
    handleMaxLengthChange(value: string) {
        this.setState({
            maxLength: +value
        }, () => this.search());
    }
    
    private async putTrack() {
        const {newTrackId, newTrackTitle, newTrackArtist, newTrackMediaUrl, newTrackMetaFields} = this.state;
        let {tenantToken} = this.props;
        
        return await fetch(`/api/${ApiVersion}/tracks?encodedMediaUrl=${encodeURIComponent(newTrackMediaUrl)}`, {
            method: 'PUT',
            headers: {'Authorization': `Basic ${tenantToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: newTrackId,
                title: newTrackTitle,
                artist: newTrackArtist,
                mediaType: 'Audio',
                metaFields: newTrackMetaFields.newTrackMetaFields
            }),
        });
    }

    private async postTrack() {
        const {newTrackId, newTrackTitle, newTrackArtist, newTrackFile, newTrackMetaFields} = this.state;
        const form = new FormData();
        form.append('Id', newTrackId);
        form.append('Title', newTrackTitle);
        form.append('Artist', newTrackArtist);
        form.append('MediaType', "Audio");
        form.append("file", newTrackFile!, newTrackFile!.name);
        Object.keys(newTrackMetaFields.newTrackMetaFields ?? {}).forEach((key) => {
            form.append(`MetaFields[${key}]`, newTrackMetaFields.newTrackMetaFields![key]); 
        });

        let {tenantToken} = this.props;
        return await fetch(`/api/${ApiVersion}/tracks/`, {
            method: 'POST',
            headers: {'Authorization': `Basic ${tenantToken}`},
            body: form,
        });
    }
    
    public render() {
        const {offset, 
            searchResults, 
            searched,
            searching,
            modalTrack,
            modalTrackDelete, 
            deletingTrack, 
            deleteTrackError, 
            errorToDisplay, 
            successToDisplay, 
            newTrackSubmitting, 
            newTrackValidation, 
            newTrackMetaFields,
            minLength, 
            maxLength} = this.state;
        const totalPages = Math.ceil(searchResults.totalCount / Tracks.PageSize);
        const currentPage = totalPages > 0 ? (offset / Tracks.PageSize + 1) : 0;
        const {newTrackId, newTrackTitle, newTrackArtist, newTrackMediaUrl} = this.state;
        
        return <>
        <Row className="stacked-card">
            <Col md={12}>
                <h1>Tracks</h1>
                <hr/>
                <Card>
                    <CardBody>
                        <h3 className="card-title">Insert new track</h3>
                        <ErrorMessage errorToDisplay={errorToDisplay} />
                        <SuccessMessage messageToDisplay={successToDisplay} />
                        <Form className="form row" onSubmit={this.handleSubmit}>
                            <Col className="col-md-6">
                                <FormGroup>
                                    <Label htmlFor="trackId">Track Id</Label>
                                    <Input id="trackId" 
                                           type="text" 
                                           placeholder="Id" 
                                           aria-describedby="trackIdHelp" 
                                           value={newTrackId} 
                                           valid={newTrackValidation.trackIdValid}
                                           invalid={newTrackValidation.trackIdValid === false}
                                           onChange={value => this.handleNewTrackIdChanged(value.target.value)} />
                                    <small id="trackIdHelp" className="form-text text-muted">Autogenerated ID, enter custom ID if required.</small>
                                    <FormFeedback>
                                        Please provide a non-empty track identifier
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="text" 
                                           placeholder="Title" 
                                           value={newTrackTitle} 
                                           valid={newTrackValidation.titleValid}
                                           invalid={newTrackValidation.titleValid === false}
                                           onChange={value => this.handleTrackTitleChange(value.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="text" 
                                           placeholder="Artist" 
                                           value={newTrackArtist}
                                           valid={newTrackValidation.artistValid}
                                           invalid={newTrackValidation.artistValid === false}
                                           onChange={value => this.handleTrackArtistChange(value.target.value)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="metaFieldsId">Track meta fields</Label>
                                    {
                                        newTrackMetaFields.newTrackMetaFields !== undefined ? 
                                        Object.keys(newTrackMetaFields.newTrackMetaFields).map((key) => {
                                            return <Row className="mb-3">
                                                       <Col className="col-md-4">
                                                           <Input type="text" value={key} disabled />
                                                       </Col>
                                                       <Col className="col-md-6">
                                                           <Input type="text" value={newTrackMetaFields.newTrackMetaFields![key]} disabled /> 
                                                       </Col>
                                                       <Col className="col-md-2">
                                                            <Button type="button"
                                                                onClick={() => this.handleNewMetaFieldsDelete(key)}
                                                                color="transparent"
                                                                size="small">
                                                                <span><FontAwesomeIcon icon={faTrash} /></span>
                                                            </Button>
                                                        </Col>
                                                   </Row>;
                                        }) : null
                                    }
                                    <Row>
                                        <Col className="col-md-4">
                                            <Input type="text" 
                                                   placeholder="Key" 
                                                   value={newTrackMetaFields.key}
                                                   valid={newTrackMetaFields.keyValid}
                                                   invalid={newTrackMetaFields.keyValid === false}
                                                   onChange={this.handleMetaKeyChange}/>
                                            <FormFeedback>
                                                Key should not be empty
                                            </FormFeedback>
                                        </Col>
                                        <Col className="col-md-6">
                                            <Input type="text" 
                                                   placeholder="Value"
                                                   value={newTrackMetaFields.value}
                                                   valid={newTrackMetaFields.valueValid}
                                                   invalid={newTrackMetaFields.valueValid === false}
                                                   onChange={this.handleMetaValueChange}/>
                                            <FormFeedback>
                                                Value should not be empty
                                            </FormFeedback>
                                        </Col>
                                        <Col className="col-md-2">
                                            <Button type="button" 
                                                    onClick={this.handleNewMetaFieldAdd}
                                                    color="transparent"
                                                    size="small">
                                                <span><FontAwesomeIcon icon={faPlus} /></span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col className="col-md-6">
                                <FormGroup>
                                    <Label htmlFor="fileId">Choose media source from file or URL</Label>
                                    <Input id="fileId" 
                                           type="file" 
                                           valid={newTrackValidation.mediaSourceFileValid}
                                           invalid={newTrackValidation.mediaSourceFileValid === false}
                                           onChange={this.handleTrackFilePathChange}/>
                                    <FormFeedback>
                                        Please provide either path or media URL as the source of the track media
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="m-0" htmlFor="fileId">YouTube URL</Label>
                                    <Input type="text" 
                                           placeholder="URL" 
                                           value={newTrackMediaUrl} 
                                           valid={newTrackValidation.mediaUrlValid}
                                           invalid={newTrackValidation.mediaUrlValid === false}
                                           onChange={this.handleTrackMediaUrlChange} />
                                    <FormFeedback>
                                        Please provide a valid URL
                                    </FormFeedback>
                                </FormGroup>
                                <Button type="submit" 
                                        disabled={newTrackSubmitting} 
                                        className="float-end"
                                        color="primary" onClick={this.handleSubmit}>
                                        {
                                            !newTrackSubmitting ? <span>Submit</span> :
                                                <span>
                                                    <FontAwesomeIcon icon={faSpinner} spin/> Submiting...
                                                </span>
                                        }
                                </Button>
                            </Col>
                        </Form>
                    </CardBody>
                </Card>
                <Card className="mt-3">
                    <CardBody>
                        <h3>Search</h3>
                        <Row className="mb-5">
                            <Col md={6}>
                                <Label for="searchBox">&nbsp;</Label>
                                <Input id="searchBox" className="form-control" type="search" placeholder="Enter track ID, title or artist"
                                       name="searchTerm" value={this.state.searchTerm}
                                       onChange={this.handleSearchTermChange}/>
                            </Col>
                            <Col md={2}>
                                <Label for="minLength">Min Length (sec)</Label>
                                <Input id="minLength" type="number" value={minLength} onChange={(e) => this.handleMinLengthChange(e.target.value)} />
                            </Col>
                            <Col md={2}>
                                <Label for="minLength">Max Length (sec)</Label>
                                <Input id="minLength" type="number" value={maxLength} onChange={(e) => this.handleMaxLengthChange(e.target.value)} />
                            </Col>
                        </Row>
                        {
                            this.renderSearchComponent(searchResults, searching, searched)
                        }
                        </CardBody>
                    </Card>
                    <EmyPagination className="mt-2" 
                                   currentPage={currentPage} 
                                   totalPages={totalPages} 
                                   handlePageClick={this.handlePageClick} 
                                   handleNextPageClick={this.handleNextPageClick} 
                                   handlePrevPageClick={this.handlePrevPageClick}
                    />
                </Col>
                           
        </Row> 
        <ModalTrack track={modalTrack} toggleModal={this.toggle} />    
        <Modal isOpen={modalTrackDelete !== undefined} toggle={this.toggleTrackDelete} size="lg">
            <ModalHeader toggle={this.toggleTrackDelete}>Deleting Track</ModalHeader>
            <ModalBody className="match-modal">
                <ErrorMessage errorToDisplay={deleteTrackError} />
                Remove track <b>{modalTrackDelete?.id}</b> from EmySound storage.
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.toggleTrackDelete}>Close</Button>{' '}
                <Button color="danger" onClick={() => this.handleTrackDeleteConfirm(modalTrackDelete!)} disabled={deletingTrack}>
                    {
                        deletingTrack ?
                            <React.Fragment>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                &nbsp;Deleting...
                            </React.Fragment>
                            : <span>Delete</span>
                    }
                </Button>
            </ModalFooter>
        </Modal>
        </>;
    }

    private renderSearchComponent(searchResults: Components.Schemas.TrackInfos, searching: boolean, searched: boolean) {
        if(searching) {
            return <Loader />;
        }
        
        if(searchResults.results.length > 0) {
            return this.renderSearchResults(searchResults);
        }
        
        if(searched) {
            return  <p className="text-center"><b>No results</b></p>;
        }
        
        return <></>
    }

    private renderSearchResults(searchResults: Components.Schemas.TrackInfos) {
        return <Row>
            <Col md={12}>
                <Table className="table-hover caption-top">
                    <caption>Total {searchResults.totalCount}</caption>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Artist</th>
                        <th>Insert Date</th>
                        <th>Length (mm:ss)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        searchResults.results.map((track, index) => {
                            return <tr key={index}>
                                <td><span className="text-monospace small">{track.id}</span></td>
                                <td>{track.title}</td>
                                <td>{track.artist}</td>
                                <td>{moment(track.insertDate).format()}</td>
                                <td>{moment.duration(Number(track?.audioTrackLength ?? 0), "seconds").format("mm:ss.SS", {trim: false})}</td>
                                <td>
                                    <div className="btn-group btn-group-sm float-end"
                                         role="group" aria-label="Basic example">
                                        <button type="button"
                                                className="btn btn-sm btn-success"
                                                onClick={() => this.handleDetailsView(track)}>Details
                                        </button>
                                        <button type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.handleTrackDelete(track)}>Remove
                                        </button>
                                    </div>
                                </td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>;
    }
}

export default withRouter(Tracks);
