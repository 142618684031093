/// <reference path="../common/generated-types.d.ts" />
import React from 'react';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {formatNumber, getEmptyAVQueryMatch, getMatchedAt} from "./common";
import QueryMatch from "./QueryMatch";
import {withRouter} from "react-router";
import IRouteProps from "./IRouteProps";

interface MatchesBlockProps extends IRouteProps {
    tenantToken: string,
    appConfig: Components.Schemas.EmyConfiguration,
    matches: Components.Schemas.AVQueryMatches
}

interface MatchesBlockState {
    modalMatch: Components.Schemas.AVQueryMatch
    isOpen: boolean,
    copied: boolean
}

class QueryMatches extends React.Component<MatchesBlockProps, MatchesBlockState> {

    constructor(props: any) {
        super(props);
        this.state = {
            modalMatch: getEmptyAVQueryMatch(),
            isOpen: false,
            copied: false
        };

        this.toggle = this.toggle.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }
    
    handleQueryMatchClick(match: Components.Schemas.AVQueryMatch): void {
        this.setState({
            isOpen: true,
            modalMatch: match
        });
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
            copied: false
        });
    }

    copyToClipboard() {
        const {modalMatch} = this.state;
        const url = `${window.location.origin}/matches/${modalMatch?.id}`;
        let textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.setState({
            copied: true
        });
    }

    render() {
        const {isOpen, modalMatch, copied } = this.state;
        const {matches, tenantToken} = this.props;
        return <Card>
            <CardBody className="card-body kaiser-list-group">
                {matches.results.length === 0 ? <h5 className="text-center m-0">No matches found.</h5> :
                    <Table className="table-hover caption-top">
                        <caption>Total {matches.totalCount}</caption>
                        <thead>
                        <tr>
                            <th>Stream ID</th>
                            <th>Title</th>
                            <th>Artist</th>
                            <th>Matched At</th>
                            <th>Coverage</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            matches.results.map((match: Components.Schemas.AVQueryMatch, index: number) => {
                                const coverage = match.audio?.coverage.trackCoverage ?? match.video?.coverage.trackCoverage;
                                const track = match.track;
                                return <tr key={index}>
                                    <td>{match.streamId}</td>
                                    <td>
                                        <button onClick={() => this.handleQueryMatchClick(match)} type="button"
                                                className="btn btn-link query-match-link text-left">{track?.title}</button>
                                    </td>
                                    <td>{track?.artist}</td>
                                    <td>{getMatchedAt(match.matchedAt)}</td>
                                    <td>{formatNumber(coverage)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                }
            </CardBody>
            {
                <Modal isOpen={isOpen} toggle={this.toggle} size="lg">
                    <ModalHeader
                        toggle={this.toggle}>{modalMatch?.track.title ?? ""} {(modalMatch?.track.artist ?? "")}</ModalHeader>
                    <ModalBody className="match-modal">
                        <QueryMatch appConfig={this.props.appConfig} avQueryMatch={modalMatch} tenantToken={tenantToken} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.copyToClipboard}>{copied ? 'Copied!' : 'Share'}</Button>
                        <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
                    </ModalFooter>
                </Modal>
            }
        </Card>;
    }
}

export default withRouter(QueryMatches);
