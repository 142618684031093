import React from 'react';
import 'isomorphic-fetch';
import moment from 'moment';
import {Card, ILoadingState} from '../common/common';
import IRouteProps from "../common/IRouteProps";
import {withRouter} from "react-router";

export interface ILicenseDetailsState extends ILoadingState<ILicenseDetails> {
}

interface ILicenseDetailsProps extends IRouteProps {
    tenantToken: string
}

interface ILicenseDetails {
    assignedTo: string;
    to: Date;
    from: Date;
}

class LicenseDetails extends React.Component<ILicenseDetailsProps, ILicenseDetailsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            state: {
                assignedTo: "",
                to: new Date(),
                from: new Date()
            }
        };
    }
    
    componentDidMount() {
        let {tenantToken} = this.props;
        fetch(`/spa/license`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<ILicenseDetails>)
        .then(data => {
            this.setState({ loading: false, state: data });
        });
    }

    public render() {
        return <Card title='License'
                     loadingState={this.state}
                     onLoaded={LicenseDetails.renderLicenseDetails}/>;
    }

    private static renderLicenseDetails(licenseDetails: ILicenseDetails) {
        return (
            <ul className="list-group list-group-flush">
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Licensed To</span></div>
                        <div className="col-6">{licenseDetails.assignedTo}</div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Period</span></div>
                        <div className="col-6">{moment(licenseDetails.to).format('L')}</div>
                    </div>
                </li>
            </ul>
        );
    }
}

export default withRouter(LicenseDetails);
