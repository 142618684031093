/*eslint no-eval: "off"*/

import moment from "moment";
import ReactTooltip from "react-tooltip";
import * as React from "react";

export function getStreamStatus(stream: Components.Schemas.Stream, maxDelayMinutes: number) {
    if (stream.status === "Running") {
        return stream.lastQueryTime === "0001-01-01T00:00:00Z" ?
            <span className="badge bg-warning">Loading...</span> :
            moment.utc().diff(moment(stream.lastQueryTime), "minutes") > maxDelayMinutes ?
                <span className="badge bg-warning">Catching Up</span> :
                <span className="badge bg-success">Healthy</span>
    } else if (stream.status === "Stopped") {
        return <>
            <p data-tip='' data-for={stream.streamId!} className="m-0 badge bg-warning">Stopped</p>
            <ReactTooltip id={stream.streamId!}>
                <p className="m-0">{stream.error?.errorMessage}</p>
            </ReactTooltip>
        </>
    }

    return <>
        <p data-tip='' data-for={stream.streamId!} className="badge bg-danger">Errored</p>
        <ReactTooltip id={stream.streamId!}>
            <p className="m-0">Message: {stream.error?.errorMessage}</p>
            <p className="m-0">Checked: {moment(stream.error?.checkedAt).format()}</p>
            <p className="m-0">Retries: {stream.error?.retryCount}</p>
        </ReactTooltip>
    </>
}

export function getLastQueryTime(stream: Components.Schemas.Stream) {
    return stream.lastQueryTime === "0001-01-01T00:00:00Z" ?
        <p className="m-0">Not yet registered</p> :
        <>
            <p className="m-0">{moment(stream.lastQueryTime).format()}</p>
            <span className="text-muted"><small>{moment(stream.lastQueryTime).fromNow()}</small></span>
        </>
}