import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader,
    Row, 
    Table
} from "reactstrap";
import ErrorMessage from "../ErrorMessage";
import * as React from "react";
import {FormEvent} from "react";
import {ApiVersion, getError} from "../../common/common";
import {getLastQueryTime, getStreamStatus} from "./streamUtils";
import moment from "moment";
import Loader from "../../common/Loader";

interface IExternalStreamsProps {
    tenantToken: string
}

interface IStreamInsertValidation {
    streamIdValid?: boolean,
    streamUrlValid?: boolean
}

interface IExternalStreamsState {
    externalStreams: Components.Schemas.Streams,        //  registered external streams
    realtimeMatches: {
        [name: string]: string;
    },
    errorToDisplay: string,
    submitting: boolean,
    modalStreamDelete: string,
    streamId: string,
    streamUrl: string,
    savePlaybackTrack: boolean,
    deleteStreamError: string,
    deletingStream: boolean,
    loading: boolean,
    streamInsertValidation: IStreamInsertValidation
}


export default class ExternalStreams extends React.Component<IExternalStreamsProps, IExternalStreamsState> {

    private maxDelayMinutes = 15;

    constructor(props: IExternalStreamsProps) {
        super(props);

        this.handleSubmitStream = this.handleSubmitStream.bind(this);
        this.handleStreamIdChange = this.handleStreamIdChange.bind(this);
        this.handStreamUrlChange = this.handStreamUrlChange.bind(this);
        this.handlePlaybackForTrack = this.handlePlaybackForTrack.bind(this);
        this.handleStreamRemove = this.handleStreamRemove.bind(this);
        this.handleError = this.handleError.bind(this);
        this.toggleStreamDelete = this.toggleStreamDelete.bind(this);
        this.handleStreamDeleteConfirm = this.handleStreamDeleteConfirm.bind(this);
        this.updateStreams = this.updateStreams.bind(this);

        this.state = {
            externalStreams: {
                results: [],
                totalCount: 0,
            },
            realtimeMatches: {},
            streamId: '',
            streamUrl: '',
            savePlaybackTrack: false,
            errorToDisplay: '',
            submitting: false,
            modalStreamDelete: '',
            deleteStreamError: '',
            deletingStream: false,
            loading: false,
            streamInsertValidation: {}
        }
    }

    async componentDidMount() {
        await this.updateStreams();
    }

    handleStreamIdChange(value: string) {
        const {streamInsertValidation} = this.state;
        streamInsertValidation.streamIdValid = value !== '' && value !== null;
        this.setState({
            streamId: value,
            streamInsertValidation: streamInsertValidation
        });
    }

    handStreamUrlChange(value: string) {
        const {streamInsertValidation} = this.state;
        try {
            new URL(value);
            streamInsertValidation.streamUrlValid = true;
        } catch (_) {
            streamInsertValidation.streamUrlValid = false;
        }

        this.setState({
            streamUrl: value,
            streamInsertValidation: streamInsertValidation
        });
    }

    handlePlaybackForTrack() {
        const {savePlaybackTrack} = this.state;
        this.setState({
            savePlaybackTrack: !savePlaybackTrack
        });
    }

    handleSubmitStream(event: FormEvent) {
        event.preventDefault();
        const {streamId, streamUrl, savePlaybackTrack, streamInsertValidation} = this.state;
        if (streamInsertValidation.streamIdValid === false || streamInsertValidation.streamUrlValid === false) {
            return;
        }

        this.setState({
            submitting: true
        });

        const {tenantToken} = this.props;

        const stream = {
            streamId: streamId,
            streamUrl: streamUrl,
            savePlaybackTrack: savePlaybackTrack,
            type: "External"
        };

        fetch(`/api/${ApiVersion}/streams`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${tenantToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stream)
        })
        .then(async response => {
            if (!response.ok) {
                throw await getError(response);
            }
            return response.json() as Promise<Paths.ApiV1Streams.Post.Responses.$201>;
        })
        .then(async () => {
            await this.updateStreams();
            this.setState({
                streamId: '',
                streamUrl: '',
                savePlaybackTrack: false,
                errorToDisplay: '',
                submitting: false,
                streamInsertValidation: {}
            });
        })
        .catch(this.handleError)
    }

    handleError(error: Error) {
        this.setState({
            errorToDisplay: error.message,
            submitting: false
        })
    }

    handleStreamRemove(streamId: string) {
        this.setState({
            modalStreamDelete: streamId
        });
    }

    handleStreamDeleteConfirm(streamId: string) {
        this.setState({
            deletingStream: true,
            deleteStreamError: ''
        });

        const {tenantToken} = this.props;
        fetch(`/api/${ApiVersion}/streams/${streamId}`, {
            method: 'DELETE',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async response => {
            if (!response.ok) {
                throw await getError(response);
            }
            return await response.json() as Promise<Paths.ApiV1Streams.Delete.Responses.$200>;
        })
        .then(() => {
            this.setState({
                deletingStream: false
            }, () => {
                this.toggleStreamDelete();
                this.updateStreams();
            });
        })
        .catch(error => {
            this.setState({
                deleteStreamError: error.message,
                deletingStream: false
            });
        })
    }

    async updateStreams() : Promise<void> {
        const {tenantToken} = this.props;
        this.setState({loading: true});
        
        await fetch(`/api/${ApiVersion}/streams?streamType=External`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiV1Streams.Get.Responses.$200>)
        .then((streams: Components.Schemas.Streams) => {
            this.setState({
                externalStreams: streams,
                loading: false
            }, async () => {
                await this.updateRealtimeMatches(streams.results);
            });
        })
        .catch(error => {
            this.setState({loading: false});
            throw error;
        });
    }

    async updateRealtimeMatches(streams: Components.Schemas.Stream[]): Promise<any> {
        const {tenantToken} = this.props;
        const params: string[] = streams.map((stream: Components.Schemas.Stream) => {
            return `streamId=${stream.streamId}`;
        });
        const joined = params.join("&");
        return await fetch(`/api/${ApiVersion}/matches/realtime?${joined}`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiV1Matches.Get.Responses.$200>)
        .then(avQueryMatches => {
            const matches: {
                [name: string]: string;
            } = {};
            avQueryMatches.results.forEach(current => matches[current.streamId!] = `${current.track.artist} - ${current.track.title}`)
            this.setState({
                realtimeMatches: matches
            });
        });
    }

    toggleStreamDelete() {
        this.setState({
            modalStreamDelete: '',
            deleteStreamError: '',
            deletingStream: false
        });
    }

    render() {
        const {
            externalStreams,
            streamId,
            streamUrl,
            savePlaybackTrack,
            errorToDisplay,
            submitting,
            modalStreamDelete,
            deleteStreamError,
            deletingStream,
            streamInsertValidation,
            realtimeMatches,
            loading
        } = this.state;

        return <Row className="stacked-card">
            <Col md={12}>
                <h1>External streams</h1>
                <hr/>
                <Card>
                    <CardBody>
                        <CardTitle><h5>Add stream</h5></CardTitle>
                        <ErrorMessage errorToDisplay={errorToDisplay}/>
                        <Row>
                            <Col md={6}>
                                <Form>
                                    <FormGroup>
                                        <Label htmlFor="streamId">Stream ID</Label>
                                        <Input type="text"
                                               id="streamId"
                                               disabled={submitting}
                                               placeholder="Stream ID"
                                               value={streamId}
                                               valid={streamInsertValidation.streamIdValid}
                                               invalid={streamInsertValidation.streamIdValid === false}
                                               onChange={(value) => this.handleStreamIdChange(value.target.value)}/>
                                        <FormFeedback>
                                            Please provide a non-empty stream ID
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="streamUrl">Stream URL</Label>
                                        <Input type="text"
                                               id="Stream URL"
                                               disabled={submitting}
                                               placeholder="URL"
                                               aria-describedby="streamUrlHelp"
                                               value={streamUrl}
                                               valid={streamInsertValidation.streamUrlValid}
                                               invalid={streamInsertValidation.streamUrlValid === false}
                                               onChange={(value) => this.handStreamUrlChange(value.target.value)}/>
                                        <small id="streamUrlHelp" className="form-text text-muted">URL of
                                            the monitored stream (i.e.,
                                            https://listen.openstream.co/2658/audio).</small>
                                        <FormFeedback>
                                            Please provide a valid stream URL
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label htmlFor="saveAudioDataCheckbox">Save audio for matches
                                            playback.</Label>
                                        <Input type="checkbox"
                                               checked={savePlaybackTrack}
                                               id="saveAudioDataCheckbox"
                                               onChange={this.handlePlaybackForTrack}/>
                                        <p>
                                            <small id="saveAudioDataHelp" className="form-text text-muted">Saving
                                                audio will allow you to playback matched tracks from
                                                monitored stream.</small>
                                        </p>
                                    </FormGroup>
                                    <Button type="submit"
                                            color="primary"
                                            className="float-end"
                                            disabled={submitting}
                                            onClick={this.handleSubmitStream}>
                                        Start Monitoring
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {
                    loading ? <Loader/> :
                        externalStreams.totalCount > 0 ?
                            <Row className="stacked-card">
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><h3>Monitored streams</h3></CardTitle>
                                            <Table className="caption-top table-hover">
                                                <caption>Total {externalStreams.totalCount}</caption>
                                                <thead>
                                                <tr>
                                                    <th>Stream ID</th>
                                                    <th>URL</th>
                                                    <th>Last Query Time</th>
                                                    <th>Matches playback</th>
                                                    <th>Status</th>
                                                    <th>Created</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    externalStreams.results.map(stream => {
                                                        return <tr key={stream.streamId!}>
                                                            <td>
                                                                <Row>
                                                                    <Col>
                                                                        <a href={`/matches?streamId=${stream.streamId}`}>{stream.streamId}</a>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <small
                                                                            className="text-muted">{realtimeMatches[stream.streamId!] ?? ''}</small>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                            <td>{stream.streamUrl}</td>
                                                            <td>{getLastQueryTime(stream)}</td>
                                                            <td>{this.getMatchesPlayback(stream)}</td>
                                                            <td>{getStreamStatus(stream, this.maxDelayMinutes)}</td>
                                                            <td>{moment(stream.createdAt).format()}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary float-end"
                                                                        onClick={() => this.handleStreamRemove(stream.streamId!)}>Remove
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> : null
                }
                <Modal isOpen={modalStreamDelete !== ''} toggle={this.toggleStreamDelete} size="lg">
                    <ModalHeader toggle={this.toggleStreamDelete}>Deleting Stream</ModalHeader>
                    <ModalBody className="match-modal">
                        <ErrorMessage errorToDisplay={deleteStreamError}/>
                        Remove stream <b>{modalStreamDelete}</b> from broadcast monitoring.
                        It takes up to a minute to remove the stream from monitoring.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleStreamDelete}>Close</Button>{' '}
                        <Button color="danger" onClick={() => this.handleStreamDeleteConfirm(modalStreamDelete!)}
                                disabled={deletingStream}>
                            {
                                deletingStream ?
                                    <React.Fragment>
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"/>&nbsp;Deleting...
                                    </React.Fragment>
                                    : <span>Delete</span>
                            }
                        </Button>
                    </ModalFooter>
                </Modal>
            </Col>
        </Row>
    }

    getMatchesPlayback(stream: Components.Schemas.Stream) {
        if (stream.savePlaybackTrack) {
            return <span className="badge bg-success">Enabled</span>;
        }

        return <span className="badge bg-secondary">None</span>;
    }
}