/*eslint no-eval: "off"*/

import React from 'react';
import moment from "moment";

export interface ILoadingState<T> {
    loading: boolean;
    state: T;
}

export interface IMatchDetail {
    streamId: string;
    start: Date;
    end: Date;
    length: string;
}

export interface IMatch {
    queryMatch: IMatchDetail;
    resultMatch: IMatchDetail;
}

export interface IDuplicateMetadata {
    clientId: string;
    mediaType: Components.Schemas.MediaType;
    uid: string;
    title: string;
    regionStart: Date;
    regionEnd: Date;
    matches: IMatch[];
    uniqueTitles: number;
    meta: {
        [key: string]: string
    };
}

export const ApiVersion : string = "v1.1";

export async function getError(response: Response): Promise<Error> {
    try {
        let error = await response.json();
        return new Error(error.message);
    } catch (_) {
        return new Error("Undefined error. Please contact support to handle it.")
    }
}

export function getMatchedAt(matchedAt: string | null | undefined) {
    if(matchedAt) {
        return moment(matchedAt).format('MM/DD/YY HH:mm:ss Z');
    }

    return "n/a";
}

export function formatNumber(value: number | undefined) {
    if(value) {
        return Number(value ?? 0).toFixed(2);
    }

    return "n/a";
}

export function buildPreviewWindowForDuplicatesWithExternalPlayer(previewLink: string) {
    if (previewLink === null || previewLink === "") {
        return <span className="previewIframe">No Preview Available</span>;
    }
    return <iframe title="Preview" className="previewIframe" id="embeddedVideo" src={previewLink} width="500" height="380"/>;
}

export function buildPreviewLinkForDuplicatesForExternalPlayer(previewLinkPattern: string,
                                                               datePattern: string,
                                                               streamId: string,
                                                               startsAt: Date,
                                                               endsAt: Date): string {

    if(previewLinkPattern == null || previewLinkPattern === "") {
        return "";
    }
    
    let playStartsAt = moment(startsAt).subtract("10", "seconds").toDate()
    let playEndsAt = moment(endsAt).add("10", "seconds").toDate();
    let link = previewLinkPattern.replace("{startsAt}",  encodeURIComponent(startsAt.toLocaleString("en", {timeZone: "UTC"})))
        .replace("{playStartsAt}", encodeURIComponent(playStartsAt.toLocaleString("en", {timeZone: "UTC"})))
        .replace("{endsAt}", encodeURIComponent(endsAt.toLocaleString("en", {timeZone: "UTC"})))
        .replace("{playEndsAt}", encodeURIComponent(playEndsAt.toLocaleString("en", {timeZone: "UTC"})));
    
    return link.replace("{streamId}", streamId);
}

export function buildPreviewLinkWithExternalPlayer(appConfig: Components.Schemas.EmyConfiguration, match: Components.Schemas.AVQueryMatch) {

    let substitute: any = {};
    for(const entry in appConfig.substitute) {
        if(appConfig.substitute.hasOwnProperty(entry)) {
            let startsAt = moment(match.matchedAt);
            let enhancedMatch = {
                streamId: match.streamId,
                startsAt: startsAt.toDate(),
                endsAt: moment(startsAt).add(match.audio?.coverage.trackDiscreteCoverageLength ?? match.video?.coverage.trackDiscreteCoverageLength, "seconds")
            };
            let code = `let match = ` + JSON.stringify(enhancedMatch) + ';\n';
            let x = code + appConfig.substitute[entry];
            try {
                substitute[entry] = eval(x);
            }
            catch(error) {
                return '';
            }
        }
    }

    let url = appConfig.previewLink;
    for(const entry in substitute) {
        if(substitute.hasOwnProperty(entry)) {
            url = url?.replace("{" + entry + "}", encodeURIComponent(substitute[entry]));
        }
    }

    return url;
}

export function getEmptyAVQueryMatch(): Components.Schemas.AVQueryMatch {
    return {
        id: '',
        audio: {
            queryMatchId: '',
            coverage: {
                queryMatchStartsAt: 0,
                trackMatchStartsAt: 0,
                queryCoverage: 0,
                trackCoverage: 0,
                queryCoverageLength: 0,
                trackCoverageLength: 0,
                queryDiscreteCoverageLength: 0,
                trackDiscreteCoverageLength: 0,
                queryGaps: [],
                trackGaps: [],
                queryLength: 0,
                trackLength: 0
            }
        },
        video: {
            queryMatchId: '',
            coverage: {
                queryMatchStartsAt: 0,
                trackMatchStartsAt: 0,
                queryCoverage: 0,
                trackCoverage: 0,
                queryCoverageLength: 0,
                trackCoverageLength: 0,
                queryDiscreteCoverageLength: 0,
                trackDiscreteCoverageLength: 0,
                queryGaps: [],
                trackGaps: [],
                queryLength: 0,
                trackLength: 0
            }
        },
        streamId: '',
        track: {id: "", title: "", artist: "", mediaType: "Any", metaFields: {}}
    }
}

export class Card extends React.Component<any, any> {

    public render() {
        let title = this.props.title;
        let onLoaded = this.props.onLoaded;
        let loadingState: ILoadingState<any> = this.props.loadingState;

        let contents = loadingState.loading
            ? <p><em>Loading...</em></p>
            : onLoaded(loadingState.state);

        return (<div className="card">
                    <div className="card-body">
                        {title ? <h5 className="card-title">{title}</h5> : null }
                        {contents}
                    </div>
                </div>);
    }
}
