import * as React from 'react';
import PaymentForm from "./PaymentForm";
import {Product} from "./billing/billing-types";
import ErrorMessage from "./ErrorMessage";
import SubscriptionStore from "./stores/SubscriptionStore";
import {BillingCalculator} from "./BillingCalculator";
import {CheckIcon} from "@primer/octicons-react";

interface IPricePlansState {
    prices: Product[],
    selectedProduct: Product | undefined,
    subscribing: boolean,
    errorToDisplay: string,
    firstLastName: string
}

interface IPricePlansProps {
    stripeConfig: Components.Schemas.StripeConfiguration,
    tenantToken: string,
    subscriptionStore: SubscriptionStore
}

export default class PricePlans extends React.Component<IPricePlansProps, IPricePlansState> {
    constructor(props: any) {
        super(props);

        this.state = {
            prices: [],
            selectedProduct: undefined,
            subscribing: false,
            errorToDisplay: '',
            firstLastName: ''
        };

        this.handlePriceSelected = this.handlePriceSelected.bind(this);
    }

    componentDidMount() {
        const {tenantToken} = this.props;
        fetch(`spa/v1/products`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Could not retrieve current price plans. Please contact support.")
            })
            .then(prices => {
                prices.sort(this.sortByOrder);
                this.setState({
                    prices: prices
                });
            })
            .catch(error => {
                this.setState({
                    errorToDisplay: error.message
                });
            })
    }
    
    sortByOrder(a: Product, b: Product) {
        return a.order - b.order;
    }

    handlePriceSelected(product: Product) {
        const{selectedProduct} = this.state;
        if (selectedProduct?.id === product.id) {
            this.setState({
                selectedProduct: undefined
            });
        } else {
            this.setState({
                selectedProduct: product
            });
        }
    }

    render() {
        const {prices, selectedProduct, errorToDisplay} = this.state;
        return <React.Fragment>
            <ErrorMessage errorToDisplay={errorToDisplay} />
            <div className="row">
                {
                    prices.map(price => {
                        return <div key={price.id} className="col-4">
                            <div id={price.id}>
                                <div>
                                    <div className="text-black-50 text-xl-center">
                                        <h1>{price.name}</h1>
                                    </div>
                                    {
                                        "tier1Price" in price ?
                                            <h1 className="display-3">
                                                {new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: price.currency
                                                }).format(price?.tier1Price ?? 0)}
                                            </h1> : <h1 className="display-3">Custom</h1>
                                    }
                                    <div>
                                        {
                                            price.tier1Description ?
                                                <h5 className="text-muted">
                                                    {price.tier1Description}
                                                </h5> : <p>&nbsp;</p>
                                        }
                                        {
                                            price.tier2Price ?
                                                <div>
                                                    <h2 className="display-4">
                                                        +{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: price.currency
                                                    }).format(price.tier2Price)}
                                                    </h2>
                                                </div> : <h2 className="display-4">&nbsp;</h2>
                                        }
                                        {
                                            price.tier2Description ?
                                                <h5 className="text-muted">
                                                    {price.tier2Description}
                                                </h5> : <h5>&nbsp;</h5>
                                        }
                                        {
                                            price.tier1Price ?
                                                <p className="blockquote-footer">
                                                    Billed monthly
                                                </p> : <p className="blockquote-footer">&nbsp;</p>
                                        }
                                    </div>
                                    <div>
                                        <hr/>
                                        {
                                            price.features.map((feature: any) => {
                                                return <div key={feature.id} className="mb-2">
                                                    <CheckIcon />&nbsp;<span
                                                    className="h6">{feature.title}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="mt-2 text-center">
                                        {
                                            price.stripePriceId ?
                                            <button onClick={() => this.handlePriceSelected(price)}
                                                    className="btn btn-primary"
                                                    type="button">
                                                {selectedProduct?.id === price.id ? 'Selected' : 'Select'}
                                            </button> :
                                                <a href="mailto:sergiu@emysound.com" target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="btn btn-primary">Email Us</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            {
                !selectedProduct ?
                <div className="row mt-5">
                    <div className="col-4">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">What is a unit?</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1,000 API requests</td>
                                <td>=</td>
                                <td>1 unit</td>
                            </tr>
                            <tr>
                                <td>1 hour of stored content</td>
                                <td>=</td>
                                <td>1 unit</td>
                            </tr>
                            <tr>
                                <td>1 broadcast stream</td>
                                <td>=</td>
                                <td>10 units</td>
                            </tr>
                            <tr>
                                <td>1 broadcast stream with matches playback</td>
                                <td>=</td>
                                <td>15 units</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-4">
                        <BillingCalculator pricePlan="Premium" tier1Price={99} tier1Limit={100} tier2Price={0.9}/>
                    </div>
                </div> : null
            }
            {
                selectedProduct ?
                    <div className="row mt-4">
                        <div className="col-4"/>
                        <div className="col-4">
                            <PaymentForm product={selectedProduct} 
                                         tenantToken={this.props.tenantToken}
                                         stripeConfig={this.props.stripeConfig}
                                         subscriptionStore={this.props.subscriptionStore} />
                        </div>
                        <div className="col-4"/>
                    </div> : null
            }
        </React.Fragment>
    }
}
