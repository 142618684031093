import React, { Component } from 'react';
import { Route, Switch} from 'react-router-dom';
import Client from './components/Client';
import {ApplicationPaths} from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import configurationService from "./common/services";

interface IAppState {
    ready: boolean,
    communityEdition: boolean
}

export default class App extends Component<any, IAppState> {
    static displayName = App.name;
    
    constructor(props: any) {
        super(props);
        
        this.state = {
            ready: false,
            communityEdition: false
        };
    }
    
    componentDidMount() {
        configurationService
            .getAppConfig()
            .then(config => {
                this.setState({
                    ready: true,
                    communityEdition: config.emy?.communityEdition ?? true
                });
            }).catch(e => this.setState({
            ready: true,
        }));
    }

    render() {
        const {ready, communityEdition} = this.state;
        if (!ready) {
            return <div>Checking server edition...</div>
        }
        
        if (communityEdition)
        {
            return (
                <Switch>
                    <Route path='/' component={Client}/>
                </Switch>
            );
        }
        return (
            <Switch>
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <AuthorizeRoute path='/' component={Client}/>
            </Switch>
        );
    }
}
