
export class SubscriptionError implements Error {
    constructor(message: string, subscriptionId: string | undefined) {
        this.message = message;
        this.name = '';
        this.subscriptionId = subscriptionId;
    }

    message: string;
    name: string;
    subscriptionId: string | undefined
}

export class SubscriptionCreated {
    
    constructor(subscription: any, stripePaymentMethodId: string, stripePriceId: string, subscriptionUpdated: boolean) {
        this.subscription = subscription;
        this.stripePaymentMethodId = stripePaymentMethodId;
        this.stripePriceId = stripePriceId;
        this.subscriptionUpdated = subscriptionUpdated;
    }
    
    subscription: any;
    stripePaymentMethodId: string;
    stripePriceId: string;
    subscriptionUpdated: boolean;
}

export interface Product {
    id: string;
    name: string;
    currency: string;
    tier1Price: number | undefined;
    tier1Description: number | undefined;
    tier2Price: number | undefined;
    tier2Description: string | undefined;
    stripePriceId: string | undefined;
    features: Feature[];
    order: number;
}

export interface Feature {
    id: string;
    title: string;
}

