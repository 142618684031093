import * as React from 'react';
import 'isomorphic-fetch';
import moment from 'moment';
import ErrorMessage from "../ErrorMessage";
import {ApiVersion, getError} from "../../common/common";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink, 
    Progress,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {getLastQueryTime, getStreamStatus} from "./streamUtils";
import Select, {MultiValue} from "react-select";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import us from '../../data/counties-albers-10m.json';
import states from '../../data/us-states.json';
import {computed} from "mobx";
import classnames from "classnames";
import {PlusCircleIcon} from "@primer/octicons-react";

interface ITVStreamsProps {
    tenantToken: string
}

interface ITVStreamsState {
    activeCard: string,
    errorToDisplay: string,
    stations: Components.Schemas.TVStream[],            // available TV streams
    tvStreams: Components.Schemas.Streams,              // registered TV streams
    totalAvailableStreams: number,
    selectAllStreams: boolean,                          // select all streams
    enabledStreams: Set<string>,                        // enabled streams
    enablingStreams: boolean,                           // enabling streams,
    enablingProgressValue: number,                      // enabling progress value

    // By Market ID
    marketIdToMarketNameOptions: Array<{ value: string, label: string }>,
    selectedMarkets: MultiValue<{ value: string; label: string; }>,

    // By State
    stateToStateNameOptions: Array<{ value: string, label: string }>,
    selectedStates: MultiValue<{ value: string; label: string; }>,

    // By Stream ID
    streamIdToNameOptions: Array<{ value: string, label: string }>,
    selectedStreams: MultiValue<{ value: string; label: string; }>,
}

export default class TVStreams extends React.Component<ITVStreamsProps, ITVStreamsState> {

    private maxDelayMinutes = 15;

    private stateCodeToName: Map<string, string>;
    private stateNameToCode: Map<string, string>;

    constructor(props: ITVStreamsProps) {
        super(props);

        this.state = {
            activeCard: '1',
            errorToDisplay: '',
            stations: [],
            tvStreams: {
                results: [],
                totalCount: 0
            },
            totalAvailableStreams: 0,
            selectAllStreams: false,
            enabledStreams: new Set<string>(),
            enablingStreams: false,
            enablingProgressValue: 0,

            // By Market ID
            marketIdToMarketNameOptions: [],
            selectedMarkets: [],
            // By State
            stateToStateNameOptions: [],
            selectedStates: [],
            // By Stream ID
            streamIdToNameOptions: [],
            selectedStreams: [],
        }

        this.handleError = this.handleError.bind(this);
        this.getStreamsForDrawing = this.getStreamsForDrawing.bind(this);
        this.toggleSelectAllStreams = this.toggleSelectAllStreams.bind(this);
        this.handleSaveStreams = this.handleSaveStreams.bind(this);

        this.stateCodeToName = new Map<string, string>(states.map(state => [state.Code, state.State]));
        this.stateNameToCode = new Map<string, string>(states.map(state => [state.State, state.Code]));
    }

    async componentDidMount() {
        await this.getStations();
        await this.updateRegisteredStreams();
        this.drawUsMap();
    }

    handleError = (error: Error) => {
        this.setState({
            errorToDisplay: error.message,
        })
    }

    getStations(): Promise<any> {
        const {tenantToken} = this.props;
        return fetch(`/spa/${ApiVersion}/tvStream`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`},
        })
        .then(response => response.json() as Promise<Components.Schemas.TVStreams>)
        .then(response => {
            const marketGroupBy = this.groupBy(response.results, 'marketId');
            const marketIdToMarketNameOptions = Object.keys(marketGroupBy)
                .map(key => {
                    return {value: key, label: marketGroupBy[key][0].marketName as string};
                })
                .sort((a, b) => a.label.localeCompare(b.label));

            const stateGroupBy = this.groupBy(response.results, 'state');
            const stateToStateNameOptions = Object.keys(stateGroupBy)
                .filter(value => value !== "")
                .map(key => {
                    return {value: key, label: `${this.stateCodeToName.get(key)} (${key})`};
                })
                .sort((a, b) => a.label.localeCompare(b.label));

            const streamIdToNameOptions = response.results
                .map(key => {
                    return {value: key.stationId, label: key.stationName};
                })
                .sort((a, b) => a.label.localeCompare(b.label));

            const totalAvailableStreams = response.results.length;
            const stations = response.results;
            this.setState({
                marketIdToMarketNameOptions,
                stateToStateNameOptions,
                streamIdToNameOptions,
                totalAvailableStreams,
                stations
            });
        }).catch(this.handleError);
    }

    groupBy(array: Array<any>, key: string) {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    updateRegisteredStreams(): Promise<void> {
        const {tenantToken} = this.props;
        return fetch(`/api/${ApiVersion}/streams?streamType=TV`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiV1Streams.Get.Responses.$200>)
        .then((tvStreams: Components.Schemas.Streams) => {
            this.setState({ tvStreams });
        });
    }

    async toggleStream(event: any, streamId: string) {
        const {enabledStreams} = this.state;
        if(event.target.checked) {
            enabledStreams.add(streamId);
        }
        else {
            enabledStreams.delete(streamId);
        }

        this.setState({enabledStreams});
    }
    
    async handleSaveStreams() {
        const {enabledStreams} = this.state;
        this.setState({enablingStreams: true, errorToDisplay: ''});
        const streams = Array.from(enabledStreams.values());
        for (let index = 0; index < streams.length; ++index) {
            let saved = await this.saveStream(streams[index]);
            if(!saved) {
                break;
            }
            
            let enablingProgressValue = Math.round((index + 1) / streams.length * 100);
            this.setState({enablingProgressValue});
            enabledStreams.delete(streams[index]);
        }
        
        await this.updateRegisteredStreams();
        this.setState({
            enablingStreams: false,
            enabledStreams,
            enablingProgressValue: 0,
            selectedMarkets: [],
            selectedStates: [],
            selectedStreams: [],
            selectAllStreams: false
        }, () => this.drawUsMap());
    }

    async deleteStream(streamId: string): Promise<Components.Schemas.Stream | void> {
        const {tenantToken} = this.props;
        await fetch(`/api/${ApiVersion}/streams/${streamId}`, {
            method: 'DELETE',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async response => {
            if (!response.ok) {
                throw await getError(response);
            }
            return await response.json() as Promise<Paths.ApiV1Streams.Delete.Responses.$200>;
        })
        .catch(this.handleError)

        await this.updateRegisteredStreams();
    }

    saveStream(streamId: string): Promise<boolean> {
        const {tenantToken} = this.props;
        const stream = {streamId: streamId, type: "TV"};
        return fetch(`/api/${ApiVersion}/streams`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${tenantToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stream)
        })
        .then(async response => {
            if (!response.ok) {
                let error = await getError(response);
                this.handleError(error);
                return false;
            }
            return true;
        });
    }

    toggle(tab: string) {
        this.setState({
            activeCard: tab,
            errorToDisplay: ''
        }, () => this.drawUsMap());
    }

    selectedStreamsChanged(newValue: MultiValue<{ value: string; label: string; }>) {
        this.setState({
            selectedStreams: newValue,
            errorToDisplay: ''
        }, () => {
            this.drawUsMap();
        })
    }

    selectedMarketsChanged(newValue: MultiValue<{ value: string; label: string; }>) {
        this.setState({
            selectedMarkets: newValue,
            errorToDisplay: ''
        }, () => {
            this.drawUsMap();
        })
    }

    selectedStatesChanged(newValue: MultiValue<{ value: string; label: string; }>) {
        this.setState({
            selectedStates: newValue,
            errorToDisplay: ''
        }, () => {
            this.drawUsMap();
        })
    }

    drawUsMap() {
        const stations: Components.Schemas.TVStream[] = this.getStreamsForDrawing();
        const marketGroupBy = this.groupBy(stations, 'marketId');
        const path = d3.geoPath()
        const width = 975;
        const height = 610;
        const projection = d3.geoAlbersUsa()
            .translate([width / 2, height / 2])
            .scale(1200 / 960 * width); // scale the scale factor, otherwise map will overflow SVG bounds.

        const data = Object.keys(marketGroupBy).map(key => {
            return {
                id: key,
                title: marketGroupBy[key][0].marketName,
                value: marketGroupBy[key].length as number,
                position: projection([+marketGroupBy[key][0].longitude, +marketGroupBy[key][0].latitude])
            }
        });

        // @ts-ignore
        const radius = d3.scaleSqrt([0, d3.max(data, d => d.value)], [0, 30])

        const divElement = d3.select("#dataviz");
        divElement.selectAll("*").remove();
        const svg = divElement.append("svg")
            // .attr("width", width)
            // .attr("height", height)
            .attr("viewBox", [0, 0, width, height]);

        svg.append("path")
            // @ts-ignore
            .datum(topojson.feature(us, us.objects.nation))
            .attr("fill", "#ddd")
            // @ts-ignore
            .attr("d", path);

        svg.append("path")
            // @ts-ignore
            .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-linejoin", "round")
            // @ts-ignore
            .attr("d", path);

        const legend = svg.append("g")
            .attr("fill", "#777")
            .attr("transform", "translate(915,608)")
            .attr("text-anchor", "middle")
            .style("font", "10px sans-serif")
            .selectAll("g")
            .data(radius.ticks(4).slice(1))
            .join("g");

        legend.append("circle")
            .attr("fill", "none")
            .attr("stroke", "#ccc")
            .attr("cy", d => -radius(d))
            .attr("r", radius);

        legend.append("text")
            .attr("y", d => -2 * radius(d))
            .attr("dy", "1.3em")
            .text(radius.tickFormat(4, "s"));

        svg.append("g")
            .attr("fill", "brown")
            .attr("fill-opacity", 0.5)
            .attr("stroke", "#fff")
            .attr("stroke-width", 0.5)
            .selectAll("circle")
            .data(data
                .filter(d => d.position)
                .sort((a, b) => d3.descending(a.value, b.value)))
            .join("circle")
            .attr("transform", d => `translate(${d.position})`)
            .attr("r", d => radius(d.value))
            .append("title")
            .text(d => `${d.title} - ${d3.format(",.0f")(d.value)}`);

        svg.append("g")
            .attr("class", "states-names")
            .selectAll("text")
            // @ts-ignore
            .data(topojson.feature(us, us.objects.states).features)
            .enter()
            .append("svg:text")
            // @ts-ignore
            .text((d) => this.stateNameToCode.get(d.properties.name) ?? '')
            .style("font", "10px sans-serif")
            // @ts-ignore
            .attr("x", d => path.centroid(d)[0])
            // @ts-ignore
            .attr("y", d => path.centroid(d)[1])
            .attr("text-anchor", "middle")
            .attr('fill', 'grey');

        svg.append("text")
            .attr("x", (width / 2))
            .attr("y", 20)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("text-decoration", "bold")
            .text(`Stations grouped by markets`);
    }

    getStreamsForDrawing(): Components.Schemas.TVStream[] {
        return this.selectedStreams;
    }

    toggleSelectAllStreams(){
        const {selectAllStreams, enabledStreams, tvStreams} = this.state;
        const toggled = !selectAllStreams;
        this.setState({ selectAllStreams: toggled });

        if(toggled) {
            this.selectedStreams
                .filter(stream => !tvStreams.results.some(s => s.streamId === stream.stationId))
                .forEach(stream => {
                    enabledStreams.add(stream.stationId);
                });
        }
        else {
            enabledStreams.clear();
        }

        this.setState({enabledStreams})
    }

    @computed get selectedStreams(): Components.Schemas.TVStream[] {
        const {selectedMarkets, selectedStates, selectedStreams, stations} = this.state;
        const marketIds = new Set<number>(selectedMarkets.map(_ => +_.value));
        const stateIds = new Set(selectedStates.map(_ => _.value));
        const streamIds = new Set<string>(selectedStreams.map(stream => stream.value)
            .concat(stations.filter(s => marketIds.has(s.marketId) || stateIds.has(s.state)).map(s => s.stationId)))
        return stations.filter(station => streamIds.has(station.stationId));
    }

    render() {
        const {
            errorToDisplay,
            totalAvailableStreams,
            activeCard,
            tvStreams,
            streamIdToNameOptions,
            marketIdToMarketNameOptions,
            stateToStateNameOptions,
            selectedStreams,
            selectedMarkets,
            selectedStates,
            selectAllStreams,
            enabledStreams,
            enablingStreams,
            enablingProgressValue
        } = this.state;
        
        return <Row className="stacked-card">
            <Col md={12}>
                <h1>US streams provided by Emysound</h1>
                <hr/>
                <ErrorMessage errorToDisplay={errorToDisplay}/>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames("nav-link", {active: activeCard === '1'})} onClick={() => this.toggle('1')}>
                            Available for monitoring ({totalAvailableStreams})
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames("nav-link", {active: activeCard === '2'})} onClick={() => this.toggle('2')}>
                            Monitored ({tvStreams.totalCount})
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeCard}>
                    <TabPane tabId="1">
                        <Card>
                            <CardBody>
                                <CardTitle><h4>Select streams for monitoring</h4></CardTitle>
                                <Row>
                                    <Col md={12}>
                                        <Row className="pb-4">
                                            <Col md={4}>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <Label for="selectedStreams">Select individual station</Label>
                                                        <Select
                                                            id="selectedStreams"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            options={streamIdToNameOptions}
                                                            value={selectedStreams}
                                                            onChange={(newValue) => this.selectedStreamsChanged(newValue)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <Label for="selectedMarkets">Select stations by market</Label>
                                                        <Select
                                                            id="selectedMarkets"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            options={marketIdToMarketNameOptions}
                                                            value={selectedMarkets}
                                                            onChange={(newValue) => this.selectedMarketsChanged(newValue)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <Label for="selectedStates">Select stations by state</Label>
                                                        <Select
                                                            id="selectedStates"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            options={stateToStateNameOptions}
                                                            value={selectedStates}
                                                            onChange={(newValue) => this.selectedStatesChanged(newValue)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={8}>
                                                <div id="dataviz"/>
                                            </Col>
                                        </Row>
                                        {
                                            this.selectedStreams.length > 0 ?
                                                <Row className="mt-3">
                                                    <Col md={12}>
                                                        {
                                                            enablingStreams ? <Progress value={enablingProgressValue}/> : <></>
                                                        }
                                                        <Table className="caption-top table-hover">
                                                            <caption>
                                                                <Button color="link" className="ps-2">
                                                                    <input className="form-check-input"
                                                                           type="checkbox"
                                                                           checked={selectAllStreams}
                                                                           onChange={this.toggleSelectAllStreams}/>
                                                                </Button>
                                                                {
                                                                    enabledStreams.size > 0 ?
                                                                        <Button
                                                                            onClick={this.handleSaveStreams}
                                                                            disabled={enablingStreams}
                                                                            className="ms-3" color="primary"
                                                                            size="sm"><PlusCircleIcon/> Monitor</Button> : <></>
                                                                }
                                                                <span className="float-end">Selected {enabledStreams.size}</span>
                                                            </caption>
                                                            <thead>
                                                            <tr>
                                                                <th>Enabled</th>
                                                                <th>Stream ID</th>
                                                                <th>Market</th>
                                                                <th>State</th>
                                                                <th>Network</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.selectedStreams.map(tvStream => {
                                                                    return <tr key={tvStream.stationId}>
                                                                        <th scope="row">
                                                                            <div className="form-check form-switch">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox"
                                                                                       role="switch"
                                                                                       disabled={tvStreams.results.some(s => s.streamId === tvStream.stationId)}
                                                                                       checked={enabledStreams.has(tvStream.stationId) || tvStreams.results.some(s => s.streamId === tvStream.stationId)}
                                                                                       onChange={async e => await this.toggleStream(e, tvStream.stationId)}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                        <td><b>{tvStream.stationId}</b></td>
                                                                        <td>{tvStream.marketName}</td>
                                                                        <td>{tvStream.state}</td>
                                                                        <td>{tvStream.network}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row> : <></>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId="2">
                        <Card>
                            <CardBody>
                                <CardTitle><h4>Monitored streams</h4></CardTitle>
                                <Row>
                                    <Col md={12}>
                                    {
                                        tvStreams.totalCount > 0 ?
                                            <Table className="caption-top table-hover">
                                                <caption>Total {tvStreams.totalCount}</caption>
                                                <thead>
                                                <tr>
                                                    <th>Enabled</th>
                                                    <th>Stream ID</th>
                                                    <th>Last Query Time</th>
                                                    <th>Status</th>
                                                    <th>Created</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    tvStreams.results.map(stream => {
                                                        return <tr key={stream.streamId}>
                                                            <th scope="row">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                           type="checkbox"
                                                                           role="switch"
                                                                           checked
                                                                           onChange={() => this.deleteStream(stream.streamId!)}
                                                                    />
                                                                </div>
                                                            </th>
                                                            <td><a
                                                                href={`/matches?streamId=${stream.streamId}`}>{stream.streamId}</a>
                                                            </td>
                                                            <td>{getLastQueryTime(stream)}</td>
                                                            <td>{getStreamStatus(stream, this.maxDelayMinutes)}</td>
                                                            <td>{moment(stream.createdAt).format()}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </Table>
                                            : <p>No streams are monitored</p>
                                    }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>;
    }
}