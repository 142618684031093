import * as React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {Navbar, Collapse, Nav, NavItem, NavbarBrand} from 'reactstrap';
import IRouteProps from '../common/IRouteProps';
import configurationService from "../common/services";
import ReactTooltip from "react-tooltip";
import {ApplicationPaths} from "./api-authorization/ApiAuthorizationConstants";
import {
    BroadcastIcon, CheckIcon, CreditCardIcon,
    FileBinaryIcon,
    HomeIcon, LinkExternalIcon,
    ListOrderedIcon, OrganizationIcon, PersonIcon,
    PulseIcon, SearchIcon, ServerIcon,
    TerminalIcon
} from "@primer/octicons-react";

interface INavMenuProps extends IRouteProps {
    enableTvStreams: boolean,
    collapsed: boolean
}

interface INavMenuState {
    appConfig: Components.Schemas.EmyConfiguration,
    role: string
}

class NavMenu extends React.Component<INavMenuProps, INavMenuState> {
    
    constructor (props: INavMenuProps) {
        super(props);
        this.state = {
            appConfig: {
                communityEdition: true,
                serverMode: "Standard",
                previewLink: '',
                substitute: {},
                datePattern: '',
                documentationUrl: '',
                apiHostname: '',
                jsonApiPort: 3340,
                dotNetApiPort: 3399,
                enableTvStreams: false
            },
            role: ''
        };
    }
    
    componentDidMount() {
        configurationService.getAppConfig()
            .then(config => this.setState({
                appConfig: config.emy!
            }));
        
        configurationService.getRole()
            .then(role => {
                this.setState({
                    role: role
                })
            });
    }

    public render() {
        const {collapsed, enableTvStreams} = this.props;
        const {appConfig, role} = this.state;
        
        return <Navbar color="dark" dark>
            <Collapse isOpen={!collapsed}>
                <NavbarBrand className="me-auto" href="/">
                    <img className="logo-image mb-4 mt-2" src='/white-logo-only.png' alt="Emy Sound logo"/>
                    <span className="ms-4 fw-light">Emysound</span>
                </NavbarBrand>
            </Collapse> 
            {
                collapsed ?
                    <Nav navbar className="mt-4">
                        <NavItem className="p-2">
                            <NavLink data-tip='' data-for="home" exact to={`/`}>
                                <HomeIcon className="nav-icon-collapsed"/>
                                <ReactTooltip id="home">
                                    <p className="m-0">Home</p>
                                </ReactTooltip>
                            </NavLink>
                        </NavItem>
                        <NavItem className="p-2">
                            <NavLink data-tip='' data-for="tracks" to={`/tracks`}>
                                <FileBinaryIcon className="nav-icon-collapsed" />
                            </NavLink>
                            <ReactTooltip id="tracks">
                                <p className="m-0">Tracks</p>
                            </ReactTooltip>
                        </NavItem>
                        {
                            enableTvStreams ?
                                <NavItem className="p-2">
                                    <NavLink data-tip='' data-for="us-streams" to={`/us-streams`}>
                                        <PulseIcon className="nav-icon-collapsed"/>
                                    </NavLink>
                                    <ReactTooltip id="us-streams">
                                        <p className="m-0">US streams</p>
                                    </ReactTooltip>
                                </NavItem> : <></>
                        }
                        <NavItem className="p-2">
                            <NavLink data-tip='' data-for="streams" to={`/external-streams`}>
                                <BroadcastIcon className="nav-icon-collapsed" />
                            </NavLink>
                            <ReactTooltip id="streams">
                                <p className="m-0">External streams</p>
                            </ReactTooltip>
                        </NavItem>
                        <NavItem className="p-2">
                            <NavLink data-tip='' data-for="matches"  to={`/matches`}>
                                <ListOrderedIcon className="nav-icon-collapsed" />
                            </NavLink>
                            <ReactTooltip id="matches">
                                <p className="m-0">Matches</p>
                            </ReactTooltip>
                        </NavItem>
                        <NavItem className="p-2">
                            <NavLink data-tip='' data-for="api" to={`/api-keys`}>
                                <TerminalIcon className="nav-icon-collapsed" />
                            </NavLink>
                            <ReactTooltip id="api">
                                <p className="m-0">API Keys</p>
                            </ReactTooltip>
                        </NavItem>
                        {appConfig.serverMode !== "DuplicatesDetection" ? null :
                            <NavItem className="p-2">
                                <NavLink data-tip='' data-for="duplicates" to={`/adsSearch`}>
                                    <SearchIcon className="nav-icon-collapsed" />
                                </NavLink>
                                <ReactTooltip id="duplicates">
                                    <p className="m-0">Duplicates</p>
                                </ReactTooltip>
                            </NavItem>}
                        {appConfig.serverMode !== "DuplicatesCrossValidation" ? null :
                            <NavItem className="p-2">
                                <NavLink data-tip='' data-for="tagging" to={`/tagging`}>
                                    <CheckIcon className="nav-icon-collapsed" />
                                </NavLink>
                                <ReactTooltip id="tagging">
                                    <p className="m-0">Tagging</p>
                                </ReactTooltip>
                            </NavItem>}
                        {!appConfig.communityEdition ? <>
                            <NavItem className="p-2">
                                <NavLink data-tip='' data-for="billing" to={`/billing`}>
                                    <CreditCardIcon className="nav-icon-collapsed" />
                                </NavLink>
                                <ReactTooltip id="billing">
                                    <p className="m-0">Billing</p>
                                </ReactTooltip>
                            </NavItem>
                            <NavItem className="p-2">
                                <a data-tip='' data-for="account" href={`${ApplicationPaths.IdentityManagePath}`} className="menu-link">
                                    <PersonIcon className="nav-icon-collapsed" />
                                </a>
                                <ReactTooltip id="account">
                                    <p className="m-0">Account</p>
                                </ReactTooltip>
                            </NavItem>
                        </> : null}
                        <NavItem className="p-2">
                            <a data-tip='' data-for="documentation" href={appConfig.documentationUrl ?? "https:///emysound.readme.io"} className="menu-link"
                               target="_blank" rel="noopener noreferrer">
                                <LinkExternalIcon className="nav-icon-collapsed" />
                            </a>
                            <ReactTooltip id="documentation">
                                <p className="m-0">Documentation</p>
                            </ReactTooltip>
                        </NavItem>
                        {!appConfig.communityEdition && role === 'Admin' ? <div className="mt-2">
                            <p className="text-white">Admin</p>
                            <NavItem data-tip='' data-for="overview" className="p-2">
                                <NavLink to={`/cluster`}>
                                    <ServerIcon className="nav-icon-collapsed" />
                                </NavLink>
                                <ReactTooltip id="overview">
                                    <p className="m-0">Cluster Overview</p>
                                </ReactTooltip>
                            </NavItem>
                            <NavItem className="p-2">
                                <NavLink data-tip='' data-for="clients" to={`/clients`}>
                                    <OrganizationIcon className="nav-icon-collapsed" />
                                </NavLink>
                                <ReactTooltip id="clients">
                                    <p className="m-0">Clients</p>
                                </ReactTooltip>
                            </NavItem>
                        </div> : null}
                    </Nav>
                    :
                    <Nav navbar pills>
                        <NavItem>
                            <NavLink exact to={`/`}>
                                <HomeIcon  className="nav-icon"/><span> Home</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={`/tracks`}>
                                <FileBinaryIcon className="nav-icon" /><span> Tracks</span>
                            </NavLink>
                        </NavItem>
                        {
                            enableTvStreams ? 
                            <NavItem>
                                <NavLink to={`/us-streams`}>
                                    <PulseIcon className="nav-icon" /><span> US streams</span>
                                </NavLink>
                            </NavItem> : <></>
                        }
                        <NavItem>
                            <NavLink to={`/external-streams`}>
                                <BroadcastIcon className="nav-icon" /><span> External streams</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={`/matches`}>
                                <ListOrderedIcon className="nav-icon" /><span> Matches</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={`/api-keys`}>
                                <TerminalIcon className="nav-icon" /><span> API Keys</span>
                            </NavLink>
                        </NavItem>
                        {appConfig.serverMode !== "DuplicatesDetection" ? null :
                            <NavItem>
                                <NavLink to={`/adsSearch`}>
                                    <SearchIcon className="nav-icon" /><span> Duplicates</span>
                                </NavLink>
                            </NavItem>}
                        {appConfig.serverMode !== "DuplicatesCrossValidation" ? null :
                            <NavItem>
                                <NavLink to={`/tagging`}>
                                    <CheckIcon className="nav-icon" /><span> Tagging</span>
                                </NavLink>
                            </NavItem>}
                        {!appConfig.communityEdition ? <>
                            <NavItem>
                                <NavLink to={`/billing`}>
                                    <CreditCardIcon className="nav-icon" /><span> Billing</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="menu-item">
                                <a href={`${ApplicationPaths.IdentityManagePath}`} className="menu-link">
                                    <PersonIcon className="nav-icon" /> Account
                                </a>
                            </NavItem>
                        </> : null}
                        <NavItem>
                            <a href={appConfig.documentationUrl ?? "https:///emysound.readme.io"} className="menu-link"
                               target="_blank" rel="noopener noreferrer">
                                <LinkExternalIcon className="nav-icon" /><span> API Docs</span>
                            </a>
                        </NavItem>
                        {!appConfig.communityEdition && role === 'Admin' ? <div className="mt-2">
                            <p className="text-white">Admin Section</p>
                            <NavItem>
                                <NavLink to={`/cluster`}>
                                    <ServerIcon className="nav-icon" /><span> Overview</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to={`/clients`}>
                                    <OrganizationIcon className="nav-icon" /><span> Clients</span>
                                </NavLink>
                            </NavItem>
                        </div> : null}
                    </Nav>
            }
        </Navbar>;
    }
}

export default withRouter(NavMenu);
