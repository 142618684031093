import React from 'react';
import {Card, CardBody, CardTitle, Col, Row, Table, Button, Progress} from "reactstrap";
import ErrorMessage from "../ErrorMessage";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";
import {ApiVersion, getError} from "../../common/common";
import Loader from "../../common/Loader";
import ModalTrack from "./ModalTrack";
import classnames from "classnames";
import EmyPagination from "../../common/EmyPagination";
import {SortAscIcon, SortDescIcon, TrashIcon} from "@primer/octicons-react";

interface TracksWithCount {
    tracks: TrackWithCount[],
    totalCount: number
}

interface TrackWithCount {
    track: Components.Schemas.TrackInfo,
    count: number,
    deleted: boolean;
}

interface ITopTracksProps {
    tenantToken: string
}

interface ITopTracksState {
    from: Date,
    to: Date
    errorToDisplay: string;
    loading: boolean,
    tracksWithCounts: TracksWithCount,
    modalTrack: Components.Schemas.TrackInfo| undefined,
    pageSize: number,
    currentPage: number,
    sortOrder: "Ascending" | "Descending",
    facetValuesMissing: boolean,
    selectedTracks: Set<string>,
    selectedPages: Set<number>,
    deleting: boolean,
    deleteProgressValue: number
}

export default class TrackMatches extends React.Component<ITopTracksProps, ITopTracksState> {
    
    constructor(props: ITopTracksProps) {
        super(props);
        
        this.state = {
            errorToDisplay: '',
            from: moment().startOf('day').utc().toDate(),
            to: moment().endOf('day').utc().toDate(), 
            loading: false,
            tracksWithCounts: {
                tracks: [],
                totalCount: 0
            },
            modalTrack: undefined,
            currentPage: 1,
            pageSize: 50,
            sortOrder: "Descending",
            facetValuesMissing: false,
            selectedTracks: new Set<string>(),
            selectedPages: new Set<number>(),
            deleting: false,
            deleteProgressValue: 0
        }
        
        this.toggle = this.toggle.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.toggleFacetValuesMissing = this.toggleFacetValuesMissing.bind(this);
        this.toggleTrackSelection = this.toggleTrackSelection.bind(this);
        this.togglePageSelection = this.togglePageSelection.bind(this);
        this.handleDeleteSelectedTracks = this.handleDeleteSelectedTracks.bind(this);
    }
    
    async componentDidMount() {
        await this.getTopTracks();
    }
    
    async getTopTracks(): Promise<any> {
        const {from, to, currentPage, pageSize, sortOrder, facetValuesMissing} = this.state;
        const {tenantToken} = this.props;
        const fromUnix = moment(from).utc().format();
        const toUnix = moment(to).utc().format();
        const facetValueOffset = (currentPage - 1) * pageSize;
        let empty: TracksWithCount = {tracks: [], totalCount: 0};
        
        this.setState({errorToDisplay: '', loading: true});
        
        const url = new URL(`/api/${ApiVersion}/matches`, window.location.origin);
        url.searchParams.append("limit", "1");
        url.searchParams.append("facetValuesMissing", `${facetValuesMissing}`);
        url.searchParams.append("facetValuesOrder", `${sortOrder}`);
        url.searchParams.append("facets", "trackId");
        url.searchParams.append("facetValuesOffset", `${facetValueOffset}`);
        url.searchParams.append("facetValuesLimit", `${pageSize}`);
        url.searchParams.append("from", fromUnix);
        url.searchParams.append("to", toUnix);
        
        
        let tracksWithCounts = await fetch(url.toString(), {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Could not retrieve matches")
            }
            
            return response
        })
        .then(response => response.json() as Promise<Paths.ApiV1Matches.Get.Responses.$200>)
        .then(async avMatches => {
            let trackIdFacet : Components.Schemas.Facet | undefined = avMatches.facets.find(_ => _.name === "trackId");
            if(!trackIdFacet || trackIdFacet.values.length === 0) {
                return empty;
            }
            
            let trackIds = trackIdFacet.values.map(fv => `trackIds=${fv.name}`).join("&");
            let tracks = await this.getTracks(trackIds, tenantToken);
            return this.groupTracksWithCounts(trackIdFacet, tracks);
        })    
        .catch((error: Error) => {
            this.setState({errorToDisplay: error.message});
            return empty;
        });
        
        this.setState({loading: false, tracksWithCounts })
    }

    getTracks(trackIds: string, tenantToken: string) : Promise<Components.Schemas.TrackInfo[]> {
        return fetch(`/api/${ApiVersion}/tracks?${trackIds}`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async response => {
            if (!response.ok) {
                this.setState({errorToDisplay: "Could not retrieve tracks top matches"});
                return [];
            }

            let trackInfos = await (response.json() as Promise<Paths.ApiV1Tracks.Get.Responses.$200>);
            return trackInfos.results;
        });
    }

    groupTracksWithCounts(trackIdFacet: Components.Schemas.Facet, tracks: Components.Schemas.TrackInfo[]) :TracksWithCount {
        let tracksWithCount = trackIdFacet.values.map(fv => {
            let track = tracks.find(t => t.id === fv.name);
            if (!track) {
                let missing: TrackWithCount = {
                    count: fv.count,
                    deleted: true,
                    track: {
                        id: fv.name,
                        mediaType: "Any",
                    }
                };

                return missing;
            }

            return {
                count: fv.count,
                deleted: false,
                track: track
            };
        });
        
        return {
            tracks: tracksWithCount,
            totalCount: trackIdFacet.totalCount
        }
    }

    handleFromChange(from: Date | null | undefined) {
        if(from) {
            this.setState({from: from}, async () => {
                await this.getTopTracks();
            });
        }
    }

    handleToChange(to: Date | null | undefined) {
        if(to) {
            this.setState({to: to}, async () => {
                await this.getTopTracks();
            });
        }
    }

    handleDetailsView(track: Components.Schemas.TrackInfo) {
        this.setState({
            modalTrack: track
        });
    }

    toggle() {
        const {modalTrack} = this.state;
        if(modalTrack) {
            this.setState({
                modalTrack: undefined
            });
        }
    }
    
    toggleSort() {
        const {sortOrder} = this.state;
        let sort: "Ascending" | "Descending" = sortOrder === "Ascending" ? "Descending" : "Ascending";
        this.setState({
            sortOrder: sort
        }, async () => await this.getTopTracks());
    }

    toggleFacetValuesMissing() {
        const {facetValuesMissing} = this.state;
        this.setState({
            facetValuesMissing: !facetValuesMissing
        }, async () => {
            await this.getTopTracks();
        })
    }
    
    handlePageClick(currentPage: number) {
       this.setState({
           currentPage
       }, async () => {
           await this.getTopTracks();
       });
    }
    
    toggleTrackSelection(id: string) {
        const {selectedTracks} = this.state;
        if(selectedTracks.has(id)) {
            selectedTracks.delete(id);
        }
        else {
            selectedTracks.add(id);
        }
        
        this.setState({selectedTracks});
    }
    
    togglePageSelection(page: number){
        const {selectedPages, tracksWithCounts, selectedTracks} = this.state;
        if(selectedPages.has(page)) {
            tracksWithCounts.tracks.forEach(track => {
                selectedTracks.delete(track.track.id);
            });
            selectedPages.delete(page);
        }
        else {
            tracksWithCounts.tracks.forEach(track => {
                selectedTracks.add(track.track.id);
            });
            selectedPages.add(page);
        }
        
        this.setState({selectedTracks, selectedPages});
    }
    
    async handleDeleteSelectedTracks() {
        const {selectedTracks, selectedPages} = this.state;
        const {tenantToken} = this.props;
        
        this.setState({deleting: true, errorToDisplay: ''});
        const tracksToDelete = Array.from(selectedTracks.values());
        for(let index = 0; index < tracksToDelete.length; ++index)
        {
            let track = tracksToDelete[index];
            let deleted = await fetch(`/api/${ApiVersion}/tracks/${track}`, {
                method: 'DELETE',
                headers: {'Authorization': `Basic ${tenantToken}`}
            })
            .then(async response => {
                if (!response.ok) {
                    let error = await getError(response);
                    this.setState({errorToDisplay: error.message});
                    return false;
                }

                return true;
            });
            
            if(!deleted) {
                break;
            }
            
            let deleteProgressValue = Math.round((index + 1) / tracksToDelete.length * 100);
            this.setState({deleteProgressValue});
            selectedTracks.delete(track);
        }
        
        if(selectedTracks.size === 0) {
            selectedPages.clear();
        }
        
        this.setState({deleting: false, selectedTracks, selectedPages, deleteProgressValue: 0}, async () => await this.getTopTracks());
    }

    render() {
        const {errorToDisplay, from, to, loading, tracksWithCounts, modalTrack, currentPage, pageSize, sortOrder, facetValuesMissing, selectedTracks, selectedPages, deleteProgressValue, deleting} = this.state;
        const totalPages = Math.ceil( tracksWithCounts.totalCount / pageSize);
        const caption = `${(currentPage - 1) * pageSize + 1}-${currentPage * pageSize} of ${tracksWithCounts.totalCount}`;
        return <>
            <Card className="mb-3">
                <CardBody>
                    <CardTitle><h4>Tracks</h4></CardTitle>
                    <ErrorMessage errorToDisplay={errorToDisplay}/>
                    <Row className="mb-5">
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="from" className="text-muted small mb-0">From</label>
                                    <DatePicker id="from" value={from} includeTime
                                                timeInputProps={{"use12HourClock": false}}
                                                onChange={this.handleFromChange} max={to}/>
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="to" className="text-muted small mb-0">To</label>
                                    <DatePicker id="to" value={to} includeTime
                                                timeInputProps={{"use12HourClock": false}}
                                                onChange={this.handleToChange}
                                                min={from}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <div className="form-check form-switch mt-4">
                                <label htmlFor="facet-missing" className="text-muted small mb-0">Show tracks with no matches</label>
                                <input id="facet-missing" className="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   checked={facetValuesMissing}
                                   onChange={this.toggleFacetValuesMissing} />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    { loading ? <Loader/> :
                        tracksWithCounts.tracks.length === 0 ? <h5 className="text-center m-0">No matches found.</h5> :
                            <>
                                {
                                    deleting ? <Progress value={deleteProgressValue}/> : <></>
                                }
                                <Table className="table-hover caption-top">
                                    <caption>
                                        <Button color="link" className="ps-2">
                                            <input className="form-check-input"
                                                   type="checkbox"
                                                   checked={selectedPages.has(currentPage)}
                                                   onChange={() => this.togglePageSelection(currentPage)}/>
                                        </Button>
                                        {
                                            selectedTracks.size > 0 ?
                                                <Button onClick={this.handleDeleteSelectedTracks} disabled={deleting}
                                                        className="ms-3" color="danger" outline
                                                        size="sm"><TrashIcon/></Button> : <></>
                                        }
                                        <span className="float-end">{caption}</span>
                                    </caption>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th>Insert Date</th>
                                        <th>Length (mm:ss)</th>
                                        <th style={{paddingBottom: '0.15rem'}}>
                                            Matches
                                            <Button onClick={this.toggleSort} className="p-1" color="link" size="sm">
                                                {
                                                    sortOrder === "Descending" ? <SortDescIcon className="ms-1"/> : <SortAscIcon className="ms-1"/>
                                                }
                                            </Button>
                                        </th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        tracksWithCounts.tracks.map((trackWithCount, index) => {
                                            let track = trackWithCount.track;
                                            return <tr className={classnames({'bg-light': trackWithCount.deleted})}
                                                       key={index}>
                                                <td>
                                                    <input className="form-check-input"
                                                           type="checkbox"
                                                           checked={selectedTracks.has(track.id)}
                                                           onChange={() => this.toggleTrackSelection(track.id)}/>
                                                </td>
                                                <td><span className="text-monospace small">{track.id}</span></td>
                                                <td>{track.title}</td>
                                                <td>{track.artist}</td>
                                                <td>{moment(track.insertDate).format()}</td>
                                                <td>{moment.duration(Number(track?.audioTrackLength ?? 0), "seconds").format("mm:ss.SS", {trim: false})}</td>
                                                <td>{trackWithCount.count}</td>
                                                <td>
                                                    <div className="btn-group btn-group-sm float-end"
                                                         role="group" aria-label="Basic example">
                                                        <button type="button"
                                                                className="btn btn-sm btn-success"
                                                                onClick={() => this.handleDetailsView(track)}>Details
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>;
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </>
                    }
                </CardBody>
            </Card>
            {
                tracksWithCounts.tracks.length > 0 && !loading ?
                    <EmyPagination className="mt-2"
                                   currentPage={currentPage}
                                   totalPages={totalPages}
                                   handleNextPageClick={() => this.handlePageClick(currentPage + 1)}
                                   handlePrevPageClick={() => this.handlePageClick(currentPage - 1)}
                                   handlePageClick={this.handlePageClick}
                    /> : null
            }
            <ModalTrack track={modalTrack} toggleModal={this.toggle} />
            </>;
    }
}