import React from 'react';
import 'isomorphic-fetch';
import {withRouter} from "react-router";
import IRouteProps from "../common/IRouteProps";
import StorageDetails from "./StorageDetails";
import Info from "./Info";
import LicenseDetails from "./LicenseDetails";

interface IClusterProps extends IRouteProps {
    appConfig: Components.Schemas.EmyConfiguration,
    tenantToken: string
}

interface IClusterPage {
    loading: boolean,
    serverMetrics: Components.Schemas.ServerMetrics[],
    servers: Components.Schemas.AVServerInfo[]
}

class Cluster extends React.Component<IClusterProps, IClusterPage> {

    constructor(props: IClusterProps) {
        super(props);

        this.state = {
            loading: true,
            servers: [],
            serverMetrics: []
        };
    }

    async componentDidMount(): Promise<void> {
        
        let {tenantToken} = this.props;
        fetch(`/api/v1/info/metrics`,{
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiV1InfoMetrics.Get.Responses.$200>)
        .then(data => {
            this.setState({
                loading: false,
                serverMetrics: data
            });
        });
        
        fetch(`/api/v1/info`,{
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiV1Info.Get.Responses.$200>)
        .then(data => {
            this.setState({
                servers: data
            });
        });
    }

    public render() {
        const {servers, serverMetrics} = this.state;
        const {tenantToken, appConfig} = this.props;

        if (serverMetrics == null || serverMetrics.length === 0) {
            return <div className="row stacked-card">
                <div className="col-12">
                    <h1>Cluster Information</h1>
                    <hr/>
                    <div>
                        <div><em>Running in standalone mode</em></div>
                    </div>
                </div>
            </div>
        }

        return (
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>Overview</h1>
                    <hr/>
                    <div className="row stacked-card">
                        <div className="col-12">
                            <div className="card-group">
                                <StorageDetails tenantToken={tenantToken} appConfig={appConfig} />
                                <Info tenantToken={tenantToken} />
                                <LicenseDetails tenantToken={tenantToken} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Emy servers in rotation</h5>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr className="emy-list-header">
                                        <th/>
                                        <th>Running at</th>
                                        <th>Active connections</th>
                                        <th>reqs/sec</th>
                                        <th>Avg. response time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        serverMetrics.map((server: Components.Schemas.ServerMetrics, index: number) => {
                                            const serverDetails = server;
                                            return <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td><a href={`//${server.hostname}/`}>
                                                    {serverDetails.isLeader ? <b>{server.hostname}</b> : server.hostname}</a>
                                                </td>
                                                <td className="fw-light">{serverDetails.activeConnections}</td>
                                                <td className="fw-light">{(serverDetails.requestsPerSecond || 0).toFixed(3)}</td>
                                                <td className="fw-light">{(serverDetails.avgResponseTime || 0).toFixed(3)}</td>
                                            </tr>;
                                        })
                                    }
                                    <tr>
                                        <th scope="row"/>
                                        <td>Totals</td>
                                        <td>
                                            {
                                                serverMetrics
                                                    .map((server: Components.Schemas.ServerMetrics) => (server.activeConnections ?? 0))
                                                    .reduce((p?: number, c?: number) => (p || 0) + (c || 0), 0)
                                            }
                                        </td>
                                        <td className="fw-light">
                                            {
                                                serverMetrics
                                                    .map((server: Components.Schemas.ServerMetrics) => (server.requestsPerSecond ?? 0))
                                                    .reduce((p?: number, c?: number) => (p || 0) + (c || 0), 0)
                                                    .toFixed(3)
                                            }
                                        </td>
                                        <td className="fw-light">
                                            {
                                                (serverMetrics
                                                    .map((server: Components.Schemas.ServerMetrics) => (server.avgResponseTime ?? 0))
                                                    .reduce((p?: number, c?: number) => (p || 0) + (c || 0), 0) / serverMetrics.length)
                                                    .toFixed(3)
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <h5 className="card-title">Client breakdown</h5> 
                                <div className="row fw-bold emy-list-header">
                                    <div className="col-2">Client</div>
                                    <div className="col-4">Hostname</div>
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-6">
                                                Audio Tracks
                                            </div>
                                            <div className="col-6">
                                                Video Tracks
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-6">
                                                Audio Fingerprints
                                            </div>
                                            <div className="col-6">
                                                Video Fingerprints
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    servers
                                        .map(server => {
                                            const audioInfo = server.audio;
                                            const videoInfo = server.video;
                                            const name = audioInfo?.hostname ?? videoInfo?.hostname;

                                            return <div key={`${server.clientId}-${server.audio?.hostname}`} className="row">
                                                <div className="col-2">{server.clientId}</div>
                                                <div className="col-4">{name}</div>

                                                <div className="col-3">
                                                    <div className="row">
                                                        <div className="col-6 fw-light">
                                                            {audioInfo?.tracks}
                                                        </div>
                                                        <div className="col-6 fw-light">
                                                            {videoInfo?.tracks}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="row">
                                                        <div className="col-6 fw-light">
                                                            {audioInfo?.fingerprints}
                                                        </div>
                                                        <div className="col-6 fw-light">
                                                            {videoInfo?.fingerprints}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Cluster);
