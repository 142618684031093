/// <reference path="../common/generated-types.d.ts" />
import React from 'react';
import 'isomorphic-fetch';
import QueryMatches from "../common/QueryMatches";

import IRouteProps from '../common/IRouteProps';
import {withRouter} from 'react-router';
import {debounce} from 'throttle-debounce';
import DatePicker from "react-widgets/DatePicker";
import moment from 'moment';
import ErrorMessage from "./ErrorMessage";
import {Button, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {ApiVersion} from "../common/common";
import classnames from "classnames";
import Select, {MultiValue} from 'react-select';
import {drawBarPlot, getIntervalFacets} from "../common/search";
import EmyPagination from "../common/EmyPagination";
import Loader from 'src/common/Loader';
import TrackMatches from "./tracks/TrackMatches";
import {DownloadIcon, SearchIcon, XIcon} from "@primer/octicons-react";

interface IMatchesProps extends IRouteProps {
    tenantToken: string
    appConfig: Components.Schemas.EmyConfiguration,
}

interface MatchesSearchState {
    searchTerm: string,
    errorToDisplay: string,
    from: Date,
    to: Date
    loading: boolean,
    matches: Components.Schemas.AVQueryMatches,
    totalMatches: number,                                          // total number of matches without cursors applied
    nextCursor: string,                                            // next page cursor 
    currentCursor: string,                                         // current cursor 
    previousCursors: string []                                     // previous page cursors
    mediaTypeFilters: string[],
    
    // By Stream ID
    streamIdToNameOptions:  Array<{value: string, label: string}>,
    selectedStreams: MultiValue<{ value: string; label: string; }>
    
    // By Track ID
    trackIdToNameOptions:  Array<{value: string, label: string}>,
    selectedTracks: MultiValue<{ value: string; label: string; }>,
    
    activeTab: string
}

class Matches extends React.Component<IMatchesProps, MatchesSearchState> {

    private pageSize = 50;
    private readonly searchDebounced: debounce<(forward: boolean) => void>; 
    
    constructor(props: IMatchesProps) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const selectedStreams = new Array<{ value: string, label: string }>();
        if (query.get('streamId')) {
            selectedStreams.push({value: query.get('streamId')!, label: query.get('streamId')!});
        }
        
        this.state = {
            searchTerm: '',
            errorToDisplay: '',
            nextCursor: '',
            currentCursor: '',
            previousCursors: [],
            totalMatches: 0,
            loading: false,
            matches: {
                results: [],
                facets: [],
                totalCount: 0,
                count: 0,
                cursor: {
                    next: ""
                }
            },
            from: moment().startOf('day').utc().toDate(),
            to: moment().endOf('day').utc().toDate(),
            mediaTypeFilters: [],
            streamIdToNameOptions: [],
            selectedStreams,
            trackIdToNameOptions: [],
            selectedTracks: [],
            activeTab: "1"
        };
        
        this.searchDebounced = debounce(500, this.searchWithLoadingIndicator);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.handlePrevPageClick = this.handlePrevPageClick.bind(this);
        this.handleNextPageClick = this.handleNextPageClick.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(): void {
        this.searchWithLoadingIndicator(true);
        
    }

    searchWithLoadingIndicator(forward: boolean) {
        this.setState({loading: true, errorToDisplay: ''}, () => {
            this.search(forward, () => {
                this.setState({loading: false})
            });
        })
    }

    search(forward: boolean, callback = () => {}, drawAirings: boolean = true) {
        const {searchTerm, previousCursors, currentCursor, nextCursor} = this.state;
        const localSearchTerm = searchTerm;
        const cursor = forward ? nextCursor : previousCursors.shift()!;
        if(forward && cursor !== '' /*not first page*/) {
            // save current page cursor in the list of previous cursors
            previousCursors.unshift(currentCursor)
        }
        
        this.searchForQueryMatches(localSearchTerm, cursor)
            .then(results => {
                if (this.expecting(localSearchTerm)) {
                    const totalMatches = cursor === '' ? results.totalCount : this.state.totalMatches;
                    const streams = results.facets.filter(f => f.name === "streamId").flatMap(f => f.values);
                    const streamIdToNameOptions = streams.map(key => {
                        return {value: key.name, label: `${key.name} (${key.count})`};
                    });
                    const selectedStreams = this.state.selectedStreams
                        .map(entry => { return streams.findIndex(stream => entry.value === stream.name) })
                        .filter(index => index !== -1)
                        .map(index => {
                            const stream = streams[index];
                            return {value: stream.name, label: `${stream.name} (${stream.count})`};
                        });
                    
                    const tracks =  results.facets.filter(f => f.name === "trackId").flatMap(f => f.values);
                    const trackIdToNameOptions = tracks.map(key => {
                        return {value: key.name, label: `${key.name} (${key.count})`}
                    });
                    const selectedTracks = this.state.selectedTracks
                        .map(entry => { return tracks.findIndex(track => entry.value === track.name) })
                        .filter(index => index !== -1)
                        .map(index => {
                            const track = tracks[index];
                            return {value: track.name, label: `${track.name} (${track.count})`};
                        });
                    
                    if(drawAirings) {
                        const facetValues = results.facets.filter(fv => fv.name === "matchedAt").flatMap(fv => fv.values)
                        drawBarPlot(facetValues, "#dataviz", results.totalCount);
                    }

                    this.setState({
                        matches: results,
                        nextCursor: results.cursor.next,
                        currentCursor: cursor,
                        previousCursors,
                        totalMatches,
                        streamIdToNameOptions,
                        selectedStreams,
                        trackIdToNameOptions,
                        selectedTracks
                    }, callback);
                }
            })
            .catch(error => {
                this.setState({
                    errorToDisplay: error.message
                }, callback)
            });
    }

    private expecting(term: string) {
        return term === this.state.searchTerm;
    }

    searchForQueryMatches(searchTerm: string, cursor: string): Promise<Paths.ApiV1Matches.Get.Responses.$200> {
        const queryUrl = this.getQueryUrl(searchTerm, cursor, this.pageSize);
        const {tenantToken} = this.props;
        return fetch(queryUrl.toString(), {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => {
            if(!response.ok) {
                throw new Error("Could not retrieve matches.")
            }
            return response;
        })    
        .then(response => response.json() as Promise<Paths.ApiV1Matches.Get.Responses.$200>);
    }

    private getQueryUrl(searchTerm: string, cursor: string, pageSize: number): URL {
        const {from, to, selectedStreams, selectedTracks} = this.state;

        const url = new URL(`/api/${ApiVersion}/matches`, window.location.origin);
        url.searchParams.append("q", searchTerm);
        url.searchParams.append("cursor", cursor);
        url.searchParams.append("facets", "trackId");
        url.searchParams.append("facets", "streamId");
        url.searchParams.append("facets", "mediaType");
        url.searchParams.append("limit", `${pageSize}`);
        
        selectedTracks.forEach(track => {
            url.searchParams.append("filters", `trackId:${track.value}`);
        });
        
        selectedStreams.forEach(stream => {
            url.searchParams.append("filters",`streamId:${stream.value}`)
        });

        const fromUnix = moment(from).utc().format();
        const toUnix = moment(to).utc().format();

        url.searchParams.append("from", fromUnix);
        url.searchParams.append("to", toUnix);
        
        const resolution = 36;
        getIntervalFacets("matchedAt", resolution, from, to).forEach(value => {
            url.searchParams.append("intervalFacets", value);
        });
        
        return url;
    }

    handleTermChange(value: string): void {
        this.setState({
            searchTerm: value,
            currentCursor: "",
            nextCursor: "",
            previousCursors: []
        }, () => {
            this.searchDebounced(true);
        });
    }
    
    handlePageClick(page: number) {
        const currentPage = this.state.previousCursors.length + 1;
        if(currentPage === page){
            return;
        }
        
        const forward = currentPage < page;
        let invocations = Math.abs(page - currentPage);
        let callback = () => {
            invocations = invocations - 1;
            if (invocations > 0) {
                this.search(forward, callback, false);
            }
        };
        
        this.search(forward, callback, false);
    } 
    
    handleNextPageClick() {
        this.search(true, () => {}, false);
    }

    handlePrevPageClick() {
        this.search(false, () => {}, false);
    }
    
    handleFromChange(from: Date | null | undefined) {
        if(from) {
            this.setState({
                from: from,
                currentCursor: "",
                nextCursor: "",
                previousCursors: []
            }, () => {
                this.searchWithLoadingIndicator(true);
            });
        }
    }
    
    handleToChange(to: Date | null | undefined) {
        if(to) {
            this.setState({
                to: to,
                currentCursor: "",
                nextCursor: "",
                previousCursors: []
            }, () =>{
                this.searchWithLoadingIndicator(true);
            });
        }
    }

    selectedStreamsChanged(selectedStreams: MultiValue<{ value: string; label: string; }>) {
        this.setState({
            selectedStreams,
            currentCursor: "",
            nextCursor: "",
            previousCursors: []
        }, () => {
            this.searchWithLoadingIndicator(true);
        });
    }

    selectedTracksChanged(selectedTracks: MultiValue<{ value: string; label: string; }>) {
        this.setState({
            selectedTracks,
            currentCursor: "",
            nextCursor: "",
            previousCursors: []
        }, () => {
            this.searchWithLoadingIndicator(true);
        });
    }

    exportCsv() {
        const {matches, currentCursor, searchTerm} = this.state;
        if(matches.totalCount === 0) {
            return;
        }
        
        const queryUrl = this.getQueryUrl(searchTerm, currentCursor, matches.totalCount + 1);
        const {tenantToken} = this.props;
        return fetch(queryUrl.toString(), {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`, 'Accept': 'text/csv'}
        })
        .then(response => {
            if(!response.ok) {
                throw new Error("Could not retrieve matches.")
            }
            return response;
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `export_${moment.now()}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove(); 
        });
    }

    toggle(tab: string) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const {appConfig, tenantToken} = this.props;
        const {
            searchTerm,
            loading,
            matches,
            previousCursors,
            totalMatches,
            from,
            to,
            errorToDisplay,
            streamIdToNameOptions,
            selectedStreams,
            trackIdToNameOptions,
            selectedTracks
        } = this.state;
        const totalPages = Math.ceil(totalMatches / this.pageSize);

        return <Row className="stacked-card">
            <Col className="col-md-12">
                <h1>Matches</h1>
                <hr/>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            Query Matches
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Track Matches
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Card className="mb-3">
                            <CardBody>
                                <CardTitle><h4>Search</h4></CardTitle>
                                <ErrorMessage errorToDisplay={errorToDisplay}/>
                                <Row>
                                    <Col md={4}>
                                        <label htmlFor="searchTerm" className="text-muted small mb-0">Track
                                            query</label>
                                        <div className="form-group has-search">
                                            <SearchIcon className="form-control-feedback" />
                                            <input id="searchTerm"
                                                   onChange={(value) => this.handleTermChange(value.target.value)}
                                                   type="text"
                                                   className="form-control form-control-sm" placeholder="Search"
                                                   value={searchTerm}/>
                                            <button className="clear-search form-control-sm"
                                                    onClick={() => this.handleTermChange('')} title="Clear">
                                                <XIcon />
                                            </button>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <Row>
                                            <Col md={6}>
                                                <label htmlFor="from" className="text-muted small mb-0">From</label>
                                                <DatePicker id="from" value={from} includeTime
                                                            timeInputProps={{"use12HourClock": false}}
                                                            onChange={this.handleFromChange} max={to}/>
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="to" className="text-muted small mb-0">To</label>
                                                <DatePicker id="to" value={to} includeTime
                                                            timeInputProps={{"use12HourClock": false}}
                                                            onChange={this.handleToChange}
                                                            min={from}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={4}>
                                        <label htmlFor="selectedTracks" className="text-muted small mb-0">Tracks</label>
                                        <Select
                                            id="selectedTracks"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={trackIdToNameOptions}
                                            value={selectedTracks}
                                            onChange={(newValue) => this.selectedTracksChanged(newValue)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label htmlFor="selectedStreams"
                                               className="text-muted small mb-0">Streams</label>
                                        <Select
                                            id="selectedStreams"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={streamIdToNameOptions}
                                            value={selectedStreams}
                                            onChange={(newValue) => this.selectedStreamsChanged(newValue)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Button color="primary"
                                                className={classnames("mt-4", {disabled: matches.totalCount === 0})}
                                                onClick={this.exportCsv}><DownloadIcon /> Export to CSV</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-4 border-top">
                                    <Col md={12}>
                                        <div id="dataviz"/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {
                            loading ? <Loader/> :
                                <QueryMatches tenantToken={tenantToken} matches={matches} appConfig={appConfig}/>
                        }
                        {
                            matches.results.length > 0 ?
                                <EmyPagination className="mt-2"
                                               currentPage={previousCursors.length + 1}
                                               totalPages={totalPages}
                                               handleNextPageClick={this.handleNextPageClick}
                                               handlePrevPageClick={this.handlePrevPageClick}
                                               handlePageClick={this.handlePageClick}
                                /> : null
                        }
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <TrackMatches tenantToken={tenantToken} />
                        </Row>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>;
    }
}

export default withRouter(Matches);
