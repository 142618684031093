import React, {Fragment} from 'react';
import {withRouter} from "react-router";
import IRouteProps from "../common/IRouteProps";
import {ApiVersion} from "../common/common";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import moment from "moment";
import {TaggingMatch} from "./TaggingMatch";
import {debounce} from "throttle-debounce";
import classnames from "classnames";
import DatePicker from "react-widgets/DatePicker";
import {drawBarPlot, getIntervalFacets} from "../common/search";
import ReactTooltip from "react-tooltip";

interface ITaggingProps extends IRouteProps {
    taggingConfiguration: Components.Schemas.TaggingConfiguration,
    tenantToken: string
}

interface ITaggingState {
    entries: Components.Schemas.MatchGroup[],
    totalCount: number,
    offset: number,
    streamId: string,
    discreteLength: boolean | null,
    reviewing: Set<string>,
    from: Date,
    to: Date
}

class Tagging extends React.Component<ITaggingProps, ITaggingState> {

    static readonly PageSize = 30;
    private readonly fetchDebounced: debounce<() => void>;
    
    constructor(props: ITaggingProps) {
        super(props);

        this.state = {
            entries: [],
            totalCount: 0,
            offset: 0,
            streamId: '',
            discreteLength: null,
            reviewing: new Set<string>(),
            from: moment().startOf('day').utc().toDate(),
            to: moment().utc().toDate(),
        };

        this.fetch = this.fetch.bind(this);
        this.review = this.review.bind(this);
        this.tag = this.tag.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.alignGraph = this.alignGraph.bind(this);
        this.fetchDebounced = debounce(500, this.fetch);
    }

    async componentDidMount(): Promise<void> {
        await this.fetch();
    }

    handleNextPageClick = () => {
        this.setState(currentState => {
            return {offset: currentState.offset + Tagging.PageSize}
        }, () => this.fetch());
    }

    handlePrevPageClick = () => {
        this.setState(currentState => {
            return {offset: currentState.offset - Tagging.PageSize}
        }, () => this.fetch());
    }

    async fetch(): Promise<void> {
        const {offset, streamId, from, to} = this.state;
        const resolution = 36;
        
        const url = new URL(`/api/${ApiVersion}/tagging`, window.location.origin);
        url.searchParams.append("streamId", streamId);
        url.searchParams.append("offset", `${offset}`);
        url.searchParams.append("limit", `${Tagging.PageSize}`);
        getIntervalFacets("startsAt", resolution, from, to).forEach(value => {
            url.searchParams.append("intervalFacets", value);
        });
        
        const fromUnix = moment(from).utc().format();
        const toUnix = moment(to).utc().format();
        
        url.searchParams.append("from", fromUnix);
        url.searchParams.append("to", toUnix);
        
       
        if (this.state.discreteLength != null)
        {
            url.searchParams.append("withDiscreteLength", `${this.state.discreteLength}`);
        }
        
        let {tenantToken} = this.props;
        fetch(url.toString(), {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiTagging.Get.Responses.$200>)
        .then(taggingData => {
            this.setState({
                entries: taggingData.groups ?? [],
                totalCount: taggingData?.totalCount ?? 0
            });

            const facetValues = taggingData.facets.filter(fv => fv.name === "startsAt").flatMap(fv => fv.values)
            drawBarPlot(facetValues, "#dataviz", taggingData.totalCount ?? 0);
        });
    }

    tag = async (matchGroupId: string, isAd: boolean) =>
    {
        let {tenantToken} = this.props;
        fetch(`/api/${ApiVersion}/tagging/${matchGroupId}?isAd=${isAd}`, {
            method: 'PUT',
            headers: {'Authorization': `Basic ${tenantToken}`}
        }).then(() => {
            this.fetch();
        });
    }

    handleDiscreteLengthChange = (value: string) => {
        let discrete = value === "all" ? null : JSON.parse(value);
        this.setState({
            discreteLength: discrete,
            offset: 0
        },async () => {
            await this.fetchDebounced();
        });
    }

    handleStreamIdChanged = (streamId: string) => {
        this.setState({streamId}, async () => {
            await this.fetchDebounced();
        });
    }

    handleFromChange(from: Date | null | undefined) {
        if (from) {
            this.setState({from}, async () => {
                await this.fetchDebounced();
            });
        }
    }

    handleToChange(to: Date | null | undefined) {
        if (to) {
            this.setState({to}, async () => {
                await this.fetchDebounced();
            });
        }
    }

    alignGraph() {
        const {offset, streamId} = this.state;
        const {tenantToken} = this.props;
        let url = `/api/${ApiVersion}/tagging?streamId=${streamId}&offset=${offset}&limit=${Tagging.PageSize}`;
        fetch(url, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Paths.ApiTagging.Get.Responses.$200>)
        .then(matchGroups => {
            if(matchGroups === null || matchGroups.totalCount === 0) {
                return;
            }
            
            const sorted = matchGroups.groups?.flatMap(group => group.matches)
                .sort((a, b) => {
                return moment.utc(a.startsAt).unix() - moment.utc(b.startsAt).unix();
            })!
            
            const from = moment(sorted[0].startsAt).utc().toDate();
            const to = moment(sorted[sorted.length - 1].startsAt).utc().toDate();
            
            this.setState({from, to}, async () => {
                await this.fetchDebounced();
            })
        });
    }

    review(matchGroupId: string) {
        const{reviewing} = this.state;
        if(reviewing.has(matchGroupId)) {
            reviewing.delete(matchGroupId)
        }
        else {
            reviewing.add(matchGroupId);
        }
        
        this.setState({reviewing});
    }

    save = (matchGroupId: string) => {
        this.tag(matchGroupId, true);
    }

    dismiss = (matchGroupId: string) => {
        this.tag(matchGroupId, false);
    }

    render() {
        const {entries,offset, totalCount, reviewing, streamId, to, from} = this.state;
        const {taggingConfiguration} = this.props;
        const totalPages = Math.ceil(totalCount / Tagging.PageSize);
        const currentPage = totalPages > 0 ? (offset / Tagging.PageSize + 1) : 0;

        return <div className="row stacked-card">
            <div className="col-12">
                <h1>Tagging</h1>
                <hr/>
                <div className="card">
                    <div className="card-body">
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label htmlFor="streamId">Stream Id</Label>
                                    <Input id="streamId"
                                           type="text"
                                           placeholder="Stream Id"
                                           value={streamId}
                                           onChange={value => this.handleStreamIdChanged(value.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label htmlFor="discreteLengthFilter">Discrete length</Label>
                                    <select id="discreteLengthFilter" defaultValue="all" onChange={(value) => this.handleDiscreteLengthChange(value.target.value)} className="form-control">
                                        <option value="all">All</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <Label htmlFor="from">From</Label>
                                <DatePicker id="from" value={from} includeTime
                                            timeInputProps={{"use12HourClock": false}}
                                            onChange={this.handleFromChange} max={to}
                                /> 
                            </Col>
                            <Col md={2}>
                                <Label htmlFor="to">To</Label>
                                <DatePicker id="to" value={to} includeTime
                                            timeInputProps={{"use12HourClock": false}}
                                            onChange={this.handleToChange}
                                            min={from}
                                />
                            </Col>
                            <Col md={2} className="mt-4">
                                <Button className="float-end" onClick={this.alignGraph}>Align Graph</Button>
                            </Col>
                        </Row>
                        <Row className="mt-4 border-top">
                            <Col md={12}>
                                <div id="dataviz"/>
                            </Col>
                        </Row>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h4 className="card-title">Top entries ({totalCount})</h4>
                                <Row className="fw-bold text-uppercase mt-3 border-bottom border-top p-2">
                                    <Col md={1}>
                                        StreamId
                                    </Col>
                                    <Col md={3}>
                                        Matched At
                                    </Col>
                                    <Col md={1}>
                                        Length
                                    </Col>
                                    <Col md={1}>
                                        Group Count 
                                    </Col>
                                    <Col md={1}>
                                        Discrete Count
                                    </Col>
                                    <Col md={1}>
                                        Time Period (dd:hh:mm)
                                    </Col>
                                    <Col md={1}>
                                        Across Streams
                                    </Col>
                                    <Col md={3}>
                                        Review
                                    </Col>
                                </Row>
                                {
                                    entries.map((matchGroup) => {
                                        return <Fragment key={matchGroup.matchGroupId}>
                                            <div className={classnames("row", "pt-2", "px-2", {'bg-light' : matchGroup.matches[0].matchStatus === 'Tagged'}, {'bg-opacity-25' : matchGroup.matches[0].matchStatus === 'Dismissed'})}>
                                                <div className="col-1">
                                                    <p data-tip='' data-for={matchGroup.matchGroupId}>{matchGroup.matches[0].streamId}</p>
                                                    <ReactTooltip id={matchGroup.matchGroupId}>
                                                        <p className="m-0">{matchGroup.matchGroupId}</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className="col-3">
                                                    <p data-tip='' data-for={`${matchGroup.matchGroupId}-date`}>{moment(matchGroup.matches[0].startsAt).toISOString(true)}</p>
                                                    <ReactTooltip id={`${matchGroup.matchGroupId}-date`}>
                                                        <p className="m-0">{moment.utc(matchGroup.matches[0].startsAt).toISOString()}</p>
                                                    </ReactTooltip>
                                                </div>
                                                <div className="col-1">
                                                    {moment.duration(Number(matchGroup.matches[0].length), "seconds").format("mm:ss.SS", {trim: false})}
                                                </div>
                                                <div className="col-1">
                                                    {matchGroup.matches.length}
                                                </div>
                                                <div className="col-1">
                                                    {Tagging.getDiscreteLengthStats(matchGroup)}
                                                </div>
                                                <div className="col-1">
                                                    {Tagging.getTimeSpan(matchGroup)} 
                                                </div>
                                                <div className="col-1">
                                                    {Tagging.acrossStreams(matchGroup)}
                                                </div>
                                                <div className="col-3">
                                                    <button type="button" className="btn btn-primary btn-sm mx-1"
                                                            onClick={() => this.review(matchGroup.matchGroupId)}>
                                                        {
                                                            !reviewing.has(matchGroup.matchGroupId) ?
                                                                <span
                                                                    className="glyphicon glyphicon-chevron-down">&nbsp;Drill Down</span> :
                                                                <span
                                                                    className="glyphicon glyphicon-chevron-up">&nbsp;Collapse</span>
                                                        }
                                                    </button>
                                                    <button className="btn btn-sm btn-success mx-1"
                                                            onClick={() => this.save(matchGroup.matchGroupId)}>Save
                                                    </button>
                                                    <button className="btn btn-sm btn-secondary mx-1"
                                                            onClick={() => this.dismiss(matchGroup.matchGroupId)}>Dismiss
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                reviewing.has(matchGroup.matchGroupId) ? <div style={{backgroundColor: '#eee'}}>
                                                    {
                                                        matchGroup.matches.map((match, index) => {
                                                            return <TaggingMatch key={index}
                                                                                 match={match}
                                                                                 previewLinkPattern={taggingConfiguration.previewLink!}
                                                                                 datePattern={taggingConfiguration.datePattern!}/>
                                                        })
                                                    }
                                                </div> : null
                                            }
                                        </Fragment>
                                    })
                                }
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-10">
                                <nav aria-label="Tagging navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className={offset > 0 ? "page-item" : "page-item disabled"}>
                                            <button className="page-link" onClick={this.handlePrevPageClick}
                                                    aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </button>
                                        </li>
                                        <li className={offset + Tagging.PageSize < totalCount ? "page-item" : "page-item disabled"}>
                                            <button className="page-link" onClick={this.handleNextPageClick}
                                                    aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span className="sr-only">Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-2">
                                <span className="mt-1 float-end"> Page {currentPage} / {totalPages}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private static getDiscreteLengthStats(matchGroup: Components.Schemas.MatchGroup) {
        const discreteCount = matchGroup.matches.filter(m => m.hasDiscreteLength)?.length ?? 0;
        return `${discreteCount} (${Number(discreteCount / matchGroup.matches.length).toFixed(2)})`
    }

    private static acrossStreams(matchGroup: Components.Schemas.MatchGroup) {
        const matches = matchGroup.matches.map(m => m.streamId);
        return new Set<string>(matches).size;
    }

    private static getTimeSpan(matchGroup: Components.Schemas.MatchGroup) {
        const sorted = matchGroup.matches.sort((a, b) => {
            return moment(a.startsAt).unix() - moment(b.startsAt).unix()
        });

        const last = sorted[sorted.length - 1];
        const difference = moment(last.startsAt).add(last.length, "seconds").diff(moment(sorted[0].startsAt));
        return moment.duration(difference).format("dd:hh:mm", {trim: false});
    }
}

export default withRouter(Tagging);
