import {Col, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";
import React from "react";

interface IEmyPaginationProps {
    className: string | undefined;
    currentPage: number;
    totalPages: number;
    handlePageClick?: (page: number) => void;
    handleNextPageClick: () => void;
    handlePrevPageClick: () => void;
}

export default class EmyPagination extends React.Component<IEmyPaginationProps, any> {
    
    private MaxPages = 9;
    
    render() {
        const {currentPage, totalPages, handleNextPageClick, handlePrevPageClick, handlePageClick, className} = this.props;
        
        if(totalPages <= 1) {
            return <></>;
        }
        
        return <Row className={className}>
            <Col md={12}>
                <Pagination className="d-flex justify-content-center" aria-label="Page navigation example">

                    <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink onClick={() => handlePrevPageClick()} previous />
                    </PaginationItem>
                    {
                        handlePageClick ? this.getPages(currentPage, totalPages, handlePageClick) : null
                    }
                    <PaginationItem disabled={currentPage >= totalPages}>
                        <PaginationLink onClick={() => handleNextPageClick()} next />
                    </PaginationItem>
                </Pagination>
            </Col>
        </Row>
    }

    private getPages(currentPage: number, totalPages: number, handlePageClick: ((page: number) => void)) {
        const maxPages = Math.min(totalPages, this.MaxPages);
        return [...Array(maxPages)].map((x, n) => {
            let middle = Math.ceil(maxPages / 2);
            let firstPages = currentPage < middle;
            let lastPages = totalPages - currentPage < middle;
            const page = firstPages ? n + 1 : lastPages ? totalPages + n + 1 - maxPages : currentPage + n + 1 - middle;
            return <PaginationItem active={page === currentPage} key={page}>
                <PaginationLink onClick={() => handlePageClick(page)}>
                    {page}
                </PaginationLink>
            </PaginationItem>
        });
    }
}