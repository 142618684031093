import * as React from 'react';

interface ISuccessProps {
    messageToDisplay: string
}

export default class SuccessMessage extends React.Component<ISuccessProps, any> {

    render() {
        const {messageToDisplay} = this.props;
        return  messageToDisplay !== '' ?
            <div className="row mt-1">
                <div className="col-12">
                    <div className="alert alert-success" role="alert">
                        <div className="row">
                            <div className="col-12">
                                {messageToDisplay}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    }
}