import React, {Fragment} from 'react';
import 'isomorphic-fetch';
import moment from 'moment';
import {buildPreviewLinkForDuplicatesForExternalPlayer, buildPreviewWindowForDuplicatesWithExternalPlayer, IDuplicateMetadata} from '../common/common';
import {DuplicateMatch} from "./DuplicateMatch";
import {MediaType} from '../common/MediaType';
import PlayerControlsStore from "./stores/PlayerControlsStore";
import {computed} from "mobx";
import {observer} from "mobx-react";
import {PlayerControls} from "./PlayerControls";

interface IDuplicateState {
    duplicateMetadata: IDuplicateMetadata;
    reviewing: boolean;
    savingOrValidatingTrack: boolean;
    trackSaved: boolean;
    trackSaveFailed: boolean;
    trackTitle: string;
}

@observer
export class Duplicate extends React.Component<any, IDuplicateState> {

    previewLinkPattern: string;
    datePattern: string;
    playerControlsStore: PlayerControlsStore = new PlayerControlsStore();

    constructor(props: any) {
        super(props);
        this.previewLinkPattern = this.props.previewLinkPattern;
        this.datePattern = this.props.datePattern;

        this.playerControlsStore.controls.startsAt = moment.utc(props.duplicate.regionStart).toDate();
        this.playerControlsStore.controls.endsAt = moment.utc(props.duplicate.regionEnd).toDate();

        this.state = {
            duplicateMetadata: props.duplicate,
            reviewing: false,
            savingOrValidatingTrack: false,
            trackSaved: false,
            trackSaveFailed: false,
            trackTitle: '',
        };

        this.review = this.review.bind(this);
    }

    review() {
        this.setState({reviewing: !this.state.reviewing});
    }

    @computed get previewLink(): string {
        let startsAt = this.playerControlsStore.controls.startsAt;
        let endsAt = this.playerControlsStore.controls.endsAt;
        let streamId = this.state.duplicateMetadata.title;
        return buildPreviewLinkForDuplicatesForExternalPlayer(this.previewLinkPattern, this.datePattern, streamId, startsAt, endsAt);
    }

    public render() {
        const {startsAt, endsAt} = this.playerControlsStore.controls;
        return <div>
            <div className="row show-grid">
                <div className="col-md-2 mt-2">
                    {this.state.duplicateMetadata.clientId}
                </div>
                <div className="col-md-2 mt-2">
                    <MediaType value={this.state.duplicateMetadata.mediaType}/>
                </div>
                <div className="col-md-3 mt-2">
                    {Duplicate.renderRegionLocation(this.previewLink, startsAt)}
                </div>
                <div className="col-md-1">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.review}>
                        {
                            !this.state.reviewing ?
                                <span className="glyphicon glyphicon-chevron-down">&nbsp;Review</span> :
                                <span className="glyphicon glyphicon-chevron-up">&nbsp;Review</span>
                        }
                    </button>
                </div>
                <div className="col-md-1 mt-2 text-right">
                    {moment.duration(moment(endsAt).diff(moment(startsAt))).asSeconds()}
                </div>
                <div className="col-md-1 mt-2 text-right">
                    {this.state.duplicateMetadata.matches.length}
                </div>
                <div className="col-md-2 mt-2 text-right">
                    {this.state.duplicateMetadata.uniqueTitles}
                </div>
            </div>
            {
                this.state.reviewing
                    ?
                    <Fragment>
                        <div className="row">
                            <div className="col-6">
                                {buildPreviewWindowForDuplicatesWithExternalPlayer(this.previewLink)}
                            </div>
                            <div className="col-6">
                                <PlayerControls store = {this.playerControlsStore} title={this.state.duplicateMetadata.title} />
                            </div>
                        </div>
                        <div className="row">
                            {
                                this.state.duplicateMetadata.matches.map((match, index) => {
                                    return <DuplicateMatch key={index} match={match} previewLinkPattern={this.previewLinkPattern} datePattern={this.datePattern}/>
                                })
                            }
                        </div>
                    </Fragment> : null
            }
        </div>;
    }

    public static renderRegionLocation(previewLink: string, startsAt: Date) {
        let text = moment.utc(startsAt).toISOString();
        return (previewLink !== null && previewLink !== "") ? <a href={previewLink} target="_blank" rel="noopener noreferrer">{text}</a> : <span>{text}</span>;
    }
}