import * as React from 'react';

interface IErrorProps {
    errorToDisplay: string
}

export default class ErrorMessage extends React.Component<IErrorProps, any> {
    
    render() {
        const {errorToDisplay} = this.props;
        return  errorToDisplay !== '' ?
            <div className="row mt-1">
                <div className="col-12">
                    <div className="alert alert-danger" role="alert">
                        <div className="row">
                            <div className="col-12">
                                {errorToDisplay}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    }
}