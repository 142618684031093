import {MediaType} from "../../common/MediaType";
import React from "react";
import moment from 'moment';

interface ITrackDetailsProps {
    track: Components.Schemas.TrackInfo | undefined
}

export default class TrackDetails extends React.Component<ITrackDetailsProps, any> {

    private static getAudioLength(trackInfo?: Components.Schemas.TrackInfo): string {
        return Number(trackInfo?.audioTrackLength).toFixed(2);
    }

    private static getVideoLength(trackInfo?: Components.Schemas.TrackInfo): string {
        return Number(trackInfo?.videoTrackLength).toFixed(2);
    }

    getPlaybackComponent() {
        const{track} = this.props;
        if(track?.originalPlaybackUrl) {
            return <iframe title="Original Track" className="previewIframe" id="embeddedVideo" src={decodeURI(track.originalPlaybackUrl)}  width="500" height="420"/>
        }
        return <></>;
    }
    
    render() {
        const {track} = this.props;
        if(!track) {
            return <></>;
        }
        
        return <>
            <div className="row mb-2">
                <div className="col-12 text-larger">Track Details</div>
            </div>
            <div className="row">
                <div className="col-12">
                    <table className="table table-bordered table-sm table-hover">
                        <tbody>
                        <tr>
                            <th scope="row">ID</th>
                            <td className="text-monospace">{track.id}</td>
                        </tr>
                        <tr>
                            <th scope="row">Title</th>
                            <td>{track.title}</td>
                        </tr>
                        <tr>
                            <th scope="row">Artist</th>
                            <td>{track.artist}</td>
                        </tr>
                        <tr>
                            <th scope="row">Media Type</th>
                            <td>
                                <MediaType value={track.mediaType}/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Insert Date</th>
                            <td>
                                {moment(track?.insertDate).format()}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Last Modified Time</th>
                            <td>
                                {moment(track?.lastModifiedTime).format()}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Audio Length</th>
                            <td>
                                {TrackDetails.getAudioLength(track)}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Video Length</th>
                            <td>
                                {TrackDetails.getVideoLength(track)}
                            </td> 
                        </tr>
                        <tr>
                            <th scope="row">Original Playback URL</th>
                            <td>
                                {track.originalPlaybackUrl ?? "N/A"}
                            </td>
                        </tr>
                        {
                            Object
                                .keys(track.metaFields ?? {})
                                .map(key => {
                                    return <tr key={key}>
                                        <th scope="row">{key}</th>
                                        <td>{track.metaFields![key]}</td>
                                    </tr>
                                })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    { this.getPlaybackComponent() }
                </div>
            </div>
        </>;
    }
}