import * as React from 'react';
import {withRouter} from 'react-router';
import IRouteProps from "../common/IRouteProps";
import configurationService from "../common/services";

interface IApiKeysProps extends IRouteProps {
    tenantToken: string
}

interface IApiKeysState {
    clientId: string,
    httpKey: string,
    dotNetKey: string,
    communityEdition: boolean,
    apiHostname: string,
    jsonApiPort: number,
    dotNetApiPort: number
}

class ApiKeys extends React.Component<IApiKeysProps, IApiKeysState> {

    constructor(props: any) {
        super(props);
        this.state = {
            clientId: '',
            httpKey: '',
            dotNetKey: '',
            communityEdition: true,
            apiHostname: '',
            jsonApiPort: 3340,
            dotNetApiPort: 3399
        }
    }

    async componentDidMount() {
        const clientId = await configurationService.getClientId();
        const dotNetKey = await configurationService.getDotNetKey();
        const httpKey = await configurationService.getHttpKey();
        const appConfig = await configurationService.getAppConfig();
        const communityEdition = appConfig.emy?.communityEdition ?? true;
        this.setState({
            clientId: clientId,
            httpKey: httpKey,
            dotNetKey: dotNetKey,
            communityEdition: communityEdition,
            apiHostname: appConfig.emy?.apiHostname ?? '',
            jsonApiPort: appConfig.emy?.jsonApiPort ?? 3340,
            dotNetApiPort: appConfig.emy?.dotNetApiPort ?? 3399
        });
    }

    render() {
        const {clientId, httpKey, dotNetKey, communityEdition, apiHostname, jsonApiPort, dotNetApiPort} = this.state;

        return <>
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>API Keys</h1>
                    <hr/>
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Your API keys</h3>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <p className="mb-0">Application ID</p>
                                    <span className="text-muted"><small>Unique identifier for you application</small></span>
                                </div>
                                <div className="col-9">
                                    <p>{clientId}</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <p className="mb-0">JSON API Key</p>
                                    <span className="text-muted"><small>JSON API key used to connect to Cloud API</small></span>
                                </div>
                                <div className="col-9">
                                    <p>{httpKey}</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <p className="mb-0">.NET API Key</p>
                                    <span className="text-muted"><small>.NET API key used to connect to Cloud API via native C# interface.</small></span>
                                </div>
                                <div className="col-9">
                                    <p className="code-font">{dotNetKey}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">How to authenticate</h4>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <p>Example for JSON API</p>
                                </div>
                                {communityEdition ?
                                    <div className="col-9">
                                        <p className="mb-0 code-font">curl http://{apiHostname}:{jsonApiPort}/api/v1/tracks -u "{clientId}:{httpKey}"</p>
                                        <span className="text-muted"><small>Every HTTP call has to provide Basic authentication credentials in form <b>ApplicationId:JsonApiKey</b></small></span>
                                    </div> :
                                    <div className="col-9">
                                        <p className="mb-0 code-font">curl https://{apiHostname}{jsonApiPort === 80 ? '' : `:${jsonApiPort}`}/api/v1/tracks -u "{clientId}:{httpKey}"</p>
                                        <span className="text-muted"><small>Every HTTP call has to provide Basic authentication credentials in form <b>ApplicationId:JsonApiKey</b></small></span>
                                    </div>
                                }
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <p>Example for .NET API</p>
                                </div>
                                {communityEdition ?
                                    <div className="col-9">
                                        <p className="mb-0 code-font">var modelService = EmyModelService.NewInstance("{apiHostname}", {dotNetApiPort});</p>
                                        <span className="text-muted"><small>ModelService interface used to access Emy.</small></span>
                                    </div> :
                                    <div className="col-9">
                                        <p className="mb-0 code-font">var credentials = new Credentials("{clientId}","{dotNetKey}");</p>
                                        <p className="mb-0 code-font">var modelService = EmyModelService.NewInstance("{apiHostname}", {dotNetApiPort}, credentials);</p>
                                        <span className="text-muted"><small>ModelService interface used to access Emy.</small></span>
                                    </div>
                                }
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    Full API documentation can be found on our <a href="http://emysound.readme.io" target="_blank" rel="noopener noreferrer">documentation portal</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default withRouter(ApiKeys);