import * as React from 'react';
import { ILoadingState, Card } from "../common/common";

interface IClientProps {
    tenantToken: string
}

interface IClientsState extends ILoadingState<Components.Schemas.Client[]> {}

export default class Clients extends React.Component<IClientProps, IClientsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            state: []
        };
        
    }
    
    componentDidMount() {
        const {tenantToken} = this.props;
        fetch('/api/client', {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Components.Schemas.Client[]>)
        .then(clients => this.setState({
             loading: false,
             state: clients
        })); 
    }

    render() {
        return (
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>Clients</h1>
                    <hr />
                    <Card loadingState={this.state} onLoaded={Clients.renderClients} />
                </div>
            </div>);
    }

    private static renderClients(clients: Components.Schemas.Client[]): any {
        if ((clients ?? []).length === 0) {
            return "No clients yet.";
        }
        return <ul className="list-group list-group-flush">
            {
                <li className="list-group-item fw-bold emy-list-header">
                    <div className="row">
                        <div className="col-2">Client ID</div>
                        <div className="col-4">HTTP Key</div>
                        <div className="col-6">API Key</div>
                    </div>
                </li>
            }
            {
                clients.map((client, index) => {
                    return <li key={index} className="list-group-item">
                        <div className="row">
                            <div className="col-md-2">
                                <b>{client.clientId}</b>
                            </div>
                            <div className="col-md-4 text-monospace">
                                {client.httpAuthKey}
                            </div>
                            <div className="col-md-6 text-monospace">
                                {client.apiKey}
                            </div>
                        </div>
                    </li>;
                })
            }
        </ul>
    }
}
