import * as React from 'react';

interface ICalculatorState {
    apiRequests: string;
    hoursOfContent: string;
    streams: string;
    withMatchesPlayback: boolean;
    units: number,
    price: number
}

interface ICalculatorProps {
    pricePlan: string
    tier1Price: number;
    tier1Limit: number;
    tier2Price: number;
}

export class BillingCalculator extends React.Component<ICalculatorProps, ICalculatorState> {

    constructor(props: any) {
        super(props);

        this.state = {
            apiRequests: '',
            hoursOfContent: '',
            streams: '',
            units: 0,
            price: 0,
            withMatchesPlayback: false
        }
        
        this.handleMatchesPlaybackChange = this.handleMatchesPlaybackChange.bind(this);
    }

    handleApiRequestsChange(value: string) {
        this.setState({
            apiRequests: value
        }, () => {
            this.calculate();
        });
    }

    handleHoursOfContentChange(value: string) {
        this.setState({
            hoursOfContent: value
        }, () => {
            this.calculate();
        });
    }

    handleBroadcastStreamsChange(value: string) {
        this.setState({
            streams: value
        }, () => {
            this.calculate();
        })
    }

    handleMatchesPlaybackChange() {
        const {withMatchesPlayback} = this.state;
        this.setState({
            withMatchesPlayback: !withMatchesPlayback
        }, () => {
            this.calculate();
        });
    }

    calculate() {
        const {apiRequests, hoursOfContent, streams, withMatchesPlayback} = this.state;

        console.log(withMatchesPlayback);
        const streamsMultiplier = withMatchesPlayback ? 15 : 10;
        let units = Number(apiRequests) / 1000 + Number(hoursOfContent) + Number(streams) * streamsMultiplier;
        this.setState({
            units: units,
            price: units < this.props.tier1Limit ? this.props.tier1Price : this.props.tier1Price + (units - this.props.tier1Limit) * this.props.tier2Price
        });
    }

    render() {
        const {apiRequests, hoursOfContent, streams, units, price, withMatchesPlayback} = this.state;
        return <form>
            <table className="table">
                <thead>
                <tr>
                    <th>
                        Estimate usage for {this.props.pricePlan} price plan
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="p-2">
                        <input id="api" aria-describedby="apiHelp" className="form-control form-control-sm"
                               type="number"
                               min="0" step="1000" placeholder="API requests"
                               onChange={value => this.handleApiRequestsChange(value.target.value)}
                               value={apiRequests}/>
                    </td>
                </tr>
                <tr>
                    <td className="p-2">
                        <input id="hours" aria-describedby="hoursHelp" className="form-control form-control-sm"
                               type="number"
                               min="0" step="1" placeholder="Hours of content"
                               onChange={value => this.handleHoursOfContentChange(value.target.value)}
                               value={hoursOfContent}/>
                    </td>
                </tr>
                <tr>
                    <td className="p-2">
                        <div className="input-group">
                            <input id="streams" aria-describedby="streamsHelp" className="form-control form-control-sm"
                               type="number" min="0" step="1" placeholder="Broadcast streams"
                               onChange={value => this.handleBroadcastStreamsChange(value.target.value)}
                               value={streams}/>
                            <div className="input-group-append">
                                <div className="input-group-text p-0">
                                    <input id="withMediaPlaybackId" onChange={this.handleMatchesPlaybackChange} checked={withMatchesPlayback} type="checkbox" aria-label="Checkbox for streams with playback" />
                                    <label className="form-check-label pl-2 pr-2" htmlFor="withMediaPlaybackId">
                                        With matches playback
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <p><b>Total</b> {units} units = {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: "USD"
                            }).format(price)}/month</p>
                        </div>
                        <div>
                            <p className="text-muted">
                                Pay as you go. Billed at the end of the month for daily usage.
                            </p>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    }
}