import React, {Fragment} from 'react';
import moment from "moment";
import * as PropTypes from 'prop-types';

interface IPlayerControlsState {
    title: string
}

export class PlayerControls extends React.Component<any, IPlayerControlsState> {

    static get propTypes() {
        return {
            store: PropTypes.object,
            title: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            store: null,
            title: ''
        };
    }

    secondsDelta: number = 1;

    constructor(props: any) {
        super(props);

        this.state = {
            title: props.title
        };

        this.addSecondsOnStartAt = this.addSecondsOnStartAt.bind(this);
        this.addSecondsToEndAt = this.addSecondsToEndAt.bind(this);
        this.removeSecondsFromStartAt = this.removeSecondsFromStartAt.bind(this);
        this.removeSecondsFromEndAt = this.removeSecondsFromEndAt.bind(this);
        this.changeStartAt = this.changeStartAt.bind(this);
        this.changeEndsAt = this.changeEndsAt.bind(this);

    }

    changeStartAt(title: string, delta: number) {
        const {startsAt} = this.props.store.controls;
        this.props.store.controls.startsAt = moment(startsAt).add("seconds", delta).toDate();
    }

    changeEndsAt(title: string, delta: number) {
        const {endsAt} = this.props.store.controls;
        this.props.store.controls.endsAt = moment(endsAt).add("seconds", delta).toDate();
    }

    addSecondsOnStartAt() {
        this.changeStartAt(this.state.title, this.secondsDelta);
    }

    removeSecondsFromStartAt() {
        this.changeStartAt(this.state.title, -this.secondsDelta);
    }

    addSecondsToEndAt() {
        this.changeEndsAt(this.state.title, this.secondsDelta);
    }

    removeSecondsFromEndAt() {
        this.changeEndsAt(this.state.title, -this.secondsDelta);
    }

    render() {
        const {startsAt, endsAt} = this.props.store.controls;
        return <Fragment>
            <div className="row show-grid-no-line">
                <div className="col-md-6">
                    <b>{this.state.title}</b>
                </div>
            </div>
            <div className="row show-grid-no-line">
                <div className="col-md-6">
                    <div className="btn-group btn-group-sm" role="group" aria-label="...">
                        <button type="button" className="btn btn-secondary disabled">Starts At</button>
                        <button type="button" className="btn btn-primary" onClick={this.removeSecondsFromStartAt}>
                            -{this.secondsDelta} sec
                        </button>
                        <button type="button" className="btn btn-primary" onClick={this.addSecondsOnStartAt}>
                            +{this.secondsDelta} sec
                        </button>
                    </div>
                </div>
                <div className="col-md-6">
                    <span className="fw-light">{moment(startsAt).toISOString()}</span>
                </div>
            </div>
            <div className="row show-grid-no-line">
                <div className="col-md-6">
                    <div className="btn-group btn-group-sm" role="group" aria-label="...">
                        <button type="button" className="btn btn-secondary disabled">Ends At&nbsp;&nbsp;</button>
                        <button type="button" className="btn btn-primary" onClick={this.removeSecondsFromEndAt}>
                            -{this.secondsDelta} sec
                        </button>
                        <button type="button" className="btn btn-primary" onClick={this.addSecondsToEndAt}>
                            +{this.secondsDelta} sec
                        </button>
                    </div>
                </div>
                <div className="col-md-6">
                    <span className="fw-light">{moment(endsAt).toISOString()}</span>
                </div>
            </div>
            <div className="row show-grid-no-line">
                <div className="col-md-6">
                    <span
                        className="fw-light">Length {moment.duration(moment(endsAt).diff(startsAt)).asSeconds().toFixed(2)} seconds</span>
                </div>
            </div>
        </Fragment>;
    }
}