import * as React from 'react';
import 'isomorphic-fetch';
import {Card, ILoadingState} from '../common/common';
import IRouteProps from '../common/IRouteProps';
import { withRouter } from 'react-router';

interface IStorageDetailsProps extends IRouteProps {
    appConfig: Components.Schemas.EmyConfiguration,
    tenantToken: string
}

interface IEmyServerInfoState extends ILoadingState<IStorageDetails> { }

interface ICounts {
    tracks: number;
    subFingerprints: number;
}

interface IStorageDetails {
    clients: number,
    audio: ICounts;
    video: ICounts;
    memoryUsedMb: number;
    totalMemoryMb: number;
}

class StorageDetails extends React.Component<IStorageDetailsProps, IEmyServerInfoState> {

    constructor(props: IStorageDetailsProps) {
        super(props);
        this.state = {
            state: {
                clients: 0,
                audio: {
                    tracks: 0,
                    subFingerprints: 0
                },
                video: {
                    tracks: 0,
                    subFingerprints: 0
                },
                memoryUsedMb: 0,
                totalMemoryMb: 0,
            },
            loading: true
        };
    }
    
    async componentDidMount(): Promise<void> {
        let {tenantToken} = this.props;
        let storage = await fetch(`/spa/storage`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<IStorageDetails>);
        
        this.setState({state: storage, loading: false});
    }

    public render() {
        const {appConfig} = this.props;
        return <Card title='Storage Details' loadingState={this.state} onLoaded={(serverInfo: IStorageDetails) => StorageDetails.renderServerInfo(serverInfo, appConfig)}/>;
    }

    private static renderServerInfo(serverInfo: IStorageDetails, appConfig: Components.Schemas.EmyConfiguration) {
        return (
            <ul className="list-group list-group-flush">
                {appConfig.communityEdition ? null :
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Clients</span></div>
                        <div className="col-6"><strong>{serverInfo.clients}</strong></div>
                    </div>
                </li>}
                <li className="list-group-item fw-light">
                    <div className="row">
                       <div className="col-6"><span>Audio Tracks</span></div>
                        <div className="col-6"><strong>{serverInfo.audio.tracks}</strong></div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Audio Fingerprints</span></div>
                        <div className="col-6"><strong>{serverInfo.audio.subFingerprints}</strong></div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Video Tracks</span></div>
                        <div className="col-6"><strong>{serverInfo.video.tracks}</strong></div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Video Fingerprints</span></div>
                        <div className="col-6"><strong>{serverInfo.video.subFingerprints}</strong></div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>RAM used</span></div>
                        <div className="col-6"><strong>{serverInfo.memoryUsedMb} MB</strong></div>
                    </div>
                </li>
            </ul>
        );
    }
}

export default withRouter(StorageDetails);
