import * as React from 'react';
import 'isomorphic-fetch';
import {Duplicate} from './Duplicate';
import {IDuplicateMetadata} from '../common/common';
import WebHooks from "./WebHooks";
import IRouteProps from '../common/IRouteProps';
import {withRouter} from 'react-router';

interface IDuplicatesSearchProps extends IRouteProps {
    tenantToken: string
}

interface IDuplicatesSearchState {
    communityEdition: boolean;
    duplicates: IDuplicateMetadata[];
    previewLinkPattern: string;
    datePattern: string;
    duplicateSecondsPerTitle: ISecondsPerTitle[];
    webHooks: Components.Schemas.StoredWebHook[];
}

interface ISecondsPerTitle {
    title: string;
    seconds: number;
    count: number;
}

class DuplicatesSearch extends React.Component<IDuplicatesSearchProps, IDuplicatesSearchState> {

    constructor(props: IDuplicatesSearchProps) {
        super(props);

        this.state = {
            communityEdition: false,
            duplicates: [],
            previewLinkPattern: '',
            datePattern: '',
            duplicateSecondsPerTitle: [],
            webHooks: []
        };
    }
    
    async componentDidMount(): Promise<void> {
        let {tenantToken} = this.props;
        fetch(`/spa/duplicates`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<IDuplicatesSearchState>)
        .then(search => {
            this.setState(search);
        });
    }

    public render() {

        const {duplicateSecondsPerTitle, duplicates, datePattern, previewLinkPattern, communityEdition} = this.state;

        if (communityEdition) {
            return <React.Fragment>
                <div className="row stacked-card">
                    <div className="col-12">
                        <h5>Duplicates Search not available in Community Edition.</h5>
                    </div>
                </div>
                <div className="row stacked-card">
                    <div className="col-12">
                        Duplicates search is used to detect repeating regions across a continuous stream.
                        Identifying these regions helps with tagging ads and other content which repeats during a
                        predefined period of time.
                    </div>
                </div>
            </React.Fragment>

        }

        return <div className="row stacked-card">
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <h1>Ads Search</h1>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-body">
                        <h4 className="card-title">Summary</h4>
                        <div className="row show-grid">
                            <div className="col-12">
                                {
                                    duplicateSecondsPerTitle.map(secondsPerTitle => {
                                        return <div key={secondsPerTitle.title} className="row">
                                            <div className="col-md-2">
                                                {secondsPerTitle.title}
                                            </div>
                                            <div className="col-md-3">
                                                Length: {secondsPerTitle.seconds.toFixed(1)} seconds
                                            </div>
                                            <div className="col-md-3">
                                                Count: {secondsPerTitle.count}
                                            </div>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <WebHooks />
                <div className="card mt-2">
                    <div className="card-body">
                        <h4 className="card-title">Regions</h4>
                        <div className="row show-grid">
                            <div className="col-12">
                                <div className="row fw-bold">
                                    <div className="col-md-2">Client</div>
                                    <div className="col-md-2">Media</div>
                                    <div className="col-md-3">Time</div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-1 text-right">Duration</div>
                                    <div className="col-md-1 text-right">Count</div>
                                    <div className="col-md-2 text-right">Unique Titles</div>
                                </div>
                                {
                                    duplicates.map(duplicate =>
                                        <Duplicate key={duplicate.uid} datePattern={datePattern}
                                                   previewLinkPattern={previewLinkPattern}
                                                   duplicate={duplicate}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(DuplicatesSearch);
