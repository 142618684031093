import * as React from 'react';

interface MediaTypeProps {
    value?: Components.Schemas.MediaType
}

export class MediaType extends React.Component<MediaTypeProps> {
    render() {
        switch (this.props.value) {
            case "Audio": return 'Audio';
            case "Video": return 'Video';
            case "AudioAndVideo": return 'Audio/Video';
            default: return '';
        }
    }
}
