import moment from "moment";
import * as d3 from "d3";


export function getIntervalFacets(facetName: string, resolution: number, from: Date, to: Date) : string[] {
    
    const seconds = moment(to).diff(moment(from), "seconds");
    const bucketSize = Math.round(seconds / resolution);

    return new Array(resolution).fill(0).map((_, index)=> {
        let fromUnix = moment(from).add(Math.round(bucketSize * index), "seconds").unix();
        let toUnix = moment(from).add(Math.round(bucketSize * (index + 1)), "seconds").unix();
        return `${facetName}:[${fromUnix},${toUnix}]`;
    });
}

export function drawBarPlot(facetValues: Components.Schemas.FacetValue[], dataviz: string, totalCount: number) {
    const facets = facetValues
        .sort((a, b) => {
            return moment.utc(a.name).unix() - moment.utc(b.name).unix();
        })
        .map(fv => {
            return {name: moment(fv.name).format("MMM DD HH:mm"), count: fv.count}
        });

    const max = Math.max.apply(Math, facets.map(function (o) {
        return o.count;
    }))

    // set the dimensions and margins of the graph
    var margin = {top: 30, right: 150, bottom: 75, left: 40},
        width = 1150 - margin.left - margin.right,
        height = 300 - margin.top - margin.bottom;
    const divElement = d3.select(dataviz);
    divElement.selectAll("*").remove();
    const svgElement = divElement
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 1000 300`)
        .classed("svg-content-responsive", true)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scaleBand()
        .range([0, width])
        .domain(facets.map(function (d) {
            return d.name;
        }))
        .padding(0.1);

    svgElement.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr("transform", "translate(-10,0)rotate(-45)")
        .style("font-size", "9px")
        .style("text-anchor", "end");

    // Add Y axis
    var y = d3.scaleLinear()
        .domain([0, max + 10])
        .range([height, 0]);
    svgElement.append("g")
        .call(d3.axisLeft(y));

    svgElement.selectAll("mybar")
        .data(facets)
        .join("rect")
        // @ts-ignore
        .attr("x", d => x(d.name))
        .attr("y", d => y(d.count))
        .attr("width", x.bandwidth())
        .attr("height", d => height - y(d.count))
        .attr("fill", "#69b3a2")

    svgElement.append("text")
        .attr("x", (width / 2))
        .attr("y", 0)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("text-decoration", "bold")
        .text(`Airings (${totalCount})`);
}