import * as React from 'react';
import NavMenu from './NavMenu';
import {Col, Container, NavbarToggler, Row} from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import classnames from "classnames";
import {withRouter} from "react-router";
import IRouteProps from "../common/IRouteProps";

export interface LayoutProps extends IRouteProps {
    children?: React.ReactNode;
    communityEdition: boolean;
    enableTvStreams: boolean;
}

interface LayoutState {
    collapsed: boolean
}

interface MenuRoute {
    id: string;
    label: string;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
    
    private menuItems :MenuRoute[] = [
        {
            id: 'tracks',
            label: "Tracks"
        }, 
        {
            id: "external-streams",
            label: "Streams"
        },
        {
            id: "matches",
            label: "Matches"
        },
        {
            id: "api-keys",
            label: "API"
        },
        {
            id: "billing",
            label: "Billing"
        }
    ];
    
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            collapsed: false
        }

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    
    navigate(route: MenuRoute[]) {
        if(route.length > 0) {
            this.props.history.push(`/${route[0].id}`)
        }
    }
    
    public render() {
        const {children, communityEdition, enableTvStreams} = this.props;
        const {collapsed} = this.state;
        return <>
            <div className="d-flex">
                <div className={classnames("p-2 min-vh-100", {'nav-root': !collapsed }, {'nav-root-collapsed': collapsed})}>
                    <NavMenu collapsed={collapsed} enableTvStreams={enableTvStreams}/>
                </div>
                <Container fluid>
                    <header className="d-flex py-2 mb-4 border-bottom navbar-dark bg-dark" style={{marginLeft: "-0.75rem"}}>
                        <NavbarToggler className="float-start" onClick={() => this.toggleNavbar()}/>
                        <span style={{marginLeft: "auto", paddingRight: "10px"}}>
                            <Typeahead placeholder="Search" id="menu-typeahead"
                                       multiple={false}
                                       onChange={this.navigate}
                                       options={this.menuItems}/>
                        </span>
                    </header>
                    {children}
                </Container>
            </div>
            <footer className="footer mt-auto py-3 bg-dark">
                <Row className="mx-0">
                    <Col md={2}>
                        {
                            communityEdition ? <>
                                <a className="p-3 fw-light link-secondary" href="https://emysound.com/eula-community" target="_blank" rel="noopener noreferrer">EULA Community Edition</a>
                            </> : <>
                                <a className="p-3 fw-light link-secondary" href="https://emysound.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a>
                                <a className="p-3 fw-light link-secondary" href="https://emysound.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms</a>
                            </>
                        }
                    </Col>
                    <Col md={10}>
                        <span className="float-end text-secondary">2022 Copyright EmySound LLC</span>
                    </Col>
                </Row>
            </footer>
        </>;
    }
}

export default withRouter(Layout);
