import * as React from 'react';
import ErrorMessage from "./ErrorMessage";
import moment from "moment";

interface IInvoicesProps {
    stripeConfig: Components.Schemas.StripeConfiguration,
    tenantToken: string
}

interface IInvoicesState {
    invoices: Array<any>,
    errorToDisplay: string
}

export default class Invoices extends React.Component<IInvoicesProps, IInvoicesState> {
    constructor(props: IInvoicesProps) {
        super(props);

        this.state = {
            invoices: [],
            errorToDisplay: ''
        }
    }

    componentDidMount() {
        const {tenantToken} = this.props;
        fetch(`/spa/v1/invoices`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
            .then(async (result) => {
                if (!result.ok) {
                    let error = await result.json();
                    this.setState({
                        errorToDisplay: error.message
                    });
                    return;
                }

                let invoices = await result.json();
                this.setState({
                    invoices: invoices.data
                });
            })
    }

    render() {
        const {invoices, errorToDisplay} = this.state;
        if(invoices.length === 0 && errorToDisplay === '') {
            return null;
        }
        
        return <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Paid invoices</h4>
                    {
                        invoices.map(invoice => {
                            return <div key={invoice.id} className="row border-bottom mt-2">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <h6>Period {moment.unix(invoice.period_start).format('LL')} - {moment.unix(invoice.period_end).format('LL')}</h6> 
                                        </div>
                                        <div className="col-6">
                                            <a className="float-end" href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">View and download</a> 
                                        </div>
                                    </div>
                                   <div className="row">
                                       <div className="col-12">
                                           {
                                               invoice.lines.data.map((line: any) => {
                                                   return <p key={line.id}><b>{line.description}</b></p>
                                               })
                                           } 
                                       </div>
                                   </div>
                                </div>
                            </div>
                        })
                    }
                    <ErrorMessage errorToDisplay={errorToDisplay} />
                </div>
            </div>
        </React.Fragment>
    }
}