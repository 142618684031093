import * as React from 'react';
import 'isomorphic-fetch';
import {Card, ILoadingState} from '../common/common';
import IRouteProps from "../common/IRouteProps";
import {withRouter} from "react-router";

export interface IInfoState extends ILoadingState<IInfo> {
}

interface IInfoProps extends IRouteProps{
    tenantToken: string
}

interface IInfo {
    hostname: string;
    port: number;
    clusterId: string;
    multicastAddress: string;
    leader: boolean;
    version: string;
}

class Info extends React.Component<IInfoProps, IInfoState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            state: {
                hostname: '',
                port: 0,
                clusterId: '',
                multicastAddress: '',
                leader: false,
                version: ''
            }
        };
    }
    
    async componentDidMount(): Promise<void> {
        let {tenantToken} = this.props; 
        fetch(`/spa/info`,{
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<IInfo>)
        .then(data => {
            this.setState({ loading: false, state: data });
        });
    }

    public render() {
        return <Card title='Info'
                     loadingState={this.state}
                     onLoaded={Info.renderInfo}/>;
    }

    public static renderInfo(info: IInfo) {
        return (
            <ul className="list-group list-group-flush">
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Mode</span></div>
                        <div className="col-6"><strong>{info.leader ? 'leader' : 'follower'}</strong></div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Port</span></div>
                        <div className="col-6">:{info.port}</div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Multicast address</span></div>
                        <div className="col-6">{info.multicastAddress}</div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Cluster ID</span></div>
                        <div className="col-6">{info.clusterId}</div>
                    </div>
                </li>
                <li className="list-group-item fw-light">
                    <div className="row">
                        <div className="col-6"><span>Version</span></div>
                        <div className="col-6">{info.version}</div>
                    </div>
                </li>
            </ul>
        );
    }
}

export default withRouter(Info);