/// <reference path="../common/generated-types.d.ts" />
import React from 'react';
import {
    buildPreviewLinkWithExternalPlayer,
    formatNumber,
    getEmptyAVQueryMatch,
    getMatchedAt
} from "./common";

import TrackDetails from "../components/tracks/TrackDetails";

interface IQueryMatchProps {
    tenantToken: string,
    appConfig: Components.Schemas.EmyConfiguration,
    avQueryMatch: Components.Schemas.AVQueryMatch
}

interface IQueryMatchState {
    mediaUrl: string
}

export default class QueryMatch extends React.Component<IQueryMatchProps, IQueryMatchState> {

    constructor(props: IQueryMatchProps) {
        super(props);
        this.state = {
            mediaUrl: ""
        };
    }

    componentDidMount() {
        const {avQueryMatch, tenantToken} = this.props;
        fetch(`/spa/mediaUrl?avQueryMatchId=${avQueryMatch.id}`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async (result) => {
            if (result.ok) {
                const mediaUrl = await result.text();
                this.setState({
                    mediaUrl: mediaUrl
                });
            }
        });
    }

    buildPreview() {
        const queryMatch = this.props.avQueryMatch || getEmptyAVQueryMatch();
        const {appConfig} = this.props;
        const {mediaUrl} = this.state;
        let preview = buildPreviewLinkWithExternalPlayer(appConfig, queryMatch);
        if(preview !== null && preview !== "") {
            // external player available
            return <iframe title="Preview" className="previewIframe" id="embeddedVideo" src={preview} width="500" height="380"/>;
        }
        
        if(mediaUrl !== "") {
            // emysound driven storage
            return <audio controls>
                <source type="audio/mp3" src={mediaUrl}/>
                <p>Your browser does not support the audio element.</p>
            </audio>;
        }

        return "No Playback Available";
    }

    getRelativeCoverage(queryMatch: Components.Schemas.QueryMatch | null | undefined) {
        const coverageLength = queryMatch?.coverage?.trackCoverage ?? 0;
        if (coverageLength === 0) {
            return "n/a";
        }

        return Number(Math.min(coverageLength, 1)).toFixed(2);
    }

    getTrackLength(queryMatch: any) {
        if (queryMatch?.coverage?.trackLength) {
            return formatNumber(queryMatch?.coverage?.trackLength);
        }

        return "n/a";
    }

    getCoverageLength(coverage: Components.Schemas.Coverage | null | undefined) {
        if (coverage?.trackCoverageLength) {
            return formatNumber(coverage?.trackCoverageLength);
        }

        return "n/a";
    }

    getGaps(gaps: Components.Schemas.Gap[] | null | undefined) {
        if (!gaps || gaps.length === 0)
            return "[]";
        const g =
            gaps.map(gap => {
                return Number(gap.start).toFixed(2) + ".." + Number(gap.end).toFixed(2);
            })
                .join(", ");
        return "[" + g + "]";
    }

    getTitle(queryMatch: Components.Schemas.AVQueryMatch | null | undefined) {
        return queryMatch?.track?.title;
    }

    getArtist(queryMatch: Components.Schemas.AVQueryMatch | null | undefined) {
        return queryMatch?.track?.artist;
    }

    render() {
        const {avQueryMatch} = this.props;
        return <div className="card">
            <div className="card-body">
                <TrackDetails track={avQueryMatch?.track} />
                <div className="row mb-2">
                    <div className="col-12 text-larger">Match Details</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-bordered table-sm table-hover">
                            <thead>
                            <tr>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">Audio</th>
                                <th scope="col">Video</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Track Length</th>
                                <td className="text-monospace">{this.getTrackLength(avQueryMatch?.audio)}</td>
                                <td className="text-monospace">{this.getTrackLength(avQueryMatch?.video)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Coverage Length</th>
                                <td className="text-monospace">{this.getCoverageLength(avQueryMatch?.audio?.coverage)}</td>
                                <td className="text-monospace">{this.getCoverageLength(avQueryMatch?.video?.coverage)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Relative Coverage</th>
                                <td className="text-monospace">{this.getRelativeCoverage(avQueryMatch?.audio)}</td>
                                <td className="text-monospace">{this.getRelativeCoverage(avQueryMatch?.video)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Matched At</th>
                                <td className="text-monospace">{getMatchedAt(avQueryMatch?.audio?.matchedAt)}</td>
                                <td className="text-monospace">{getMatchedAt(avQueryMatch?.video?.matchedAt)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Query Gaps</th>
                                <td className="text-monospace">{this.getGaps(avQueryMatch?.audio?.coverage?.queryGaps)}</td>
                                <td className="text-monospace">{this.getGaps(avQueryMatch?.video?.coverage?.queryGaps)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Track Gaps</th>
                                <td className="text-monospace">{this.getGaps(avQueryMatch?.audio?.coverage?.trackGaps)}</td>
                                <td className="text-monospace">{this.getGaps(avQueryMatch?.video?.coverage?.trackGaps)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12 text-center">
                        {this.buildPreview()}
                    </div>
                </div>
            </div>
        </div>;
    }
}