import 'isomorphic-fetch';

class ConfigurationService {
    _appConfiguration: Components.Schemas.AppConfiguration | null;
    _appConfigInitialized: boolean;
    _tenantToken: string;
    _role: string;
    _clientId: string;
    _netApiKey: string;
    _httpKey: string;

    constructor() {
        this._appConfiguration = {
            tagging: {
                previewLink: "",
                datePattern: ""
            },
            duplicates: {
                previewLink: "",
                datePattern: ""
            },
            emy: {
                communityEdition: false,
                serverMode: "Standard",
                previewLink: "",
                datePattern: "",
                substitute: {},
                apiHostname: "",
                jsonApiPort: 3340,
                dotNetApiPort: 3399,
                enableTvStreams: false
            },
            stripe: {
                publishableKey: ''
            }
        };
        
        this._tenantToken = '';
        this._role = '';
        this._netApiKey = '';
        this._clientId = '';
        this._httpKey = '';
        this._appConfigInitialized = false;
    }
    
    async getHttpKey(): Promise<string> {
        await this.ensureClientIsInitialized();
        return this._httpKey;
    }
    
    async getClientId(): Promise<string> {
        await this.ensureClientIsInitialized();
        return this._clientId;
    }
    
    async getDotNetKey(): Promise<string> {
        await this.ensureClientIsInitialized();
        return this._netApiKey;
    }
    
    async getRole(): Promise<string> {
        await this.ensureClientIsInitialized();
        return this._role;
    }

    async getTenantToken(): Promise<string> {
        await this.ensureClientIsInitialized();
        return this._tenantToken!;
    }

    async getAppConfig(): Promise<Components.Schemas.AppConfiguration> {
        await this.ensureAppConfigIsInitialized();
        return this._appConfiguration!;
    }

    async ensureClientIsInitialized() {
        if(this._clientId !== '') {
            return;
        } 
        
        let client = await fetch(`/api/client/current`)
            .then(response => response.json() as Promise<Components.Schemas.Client>);

        if (client != null) {
            let credentials = `${client.clientId}:${atob(client.httpAuthKey)}`;
            this._tenantToken = btoa(credentials);
            this._role = client.role;
            this._clientId = client.clientId;
            this._netApiKey = client.apiKey;
            this._httpKey = atob(client.httpAuthKey);
        }
    }

    async ensureAppConfigIsInitialized() {
        if(this._appConfigInitialized) {
            return;
        }
        
        this._appConfiguration = await fetch(`/spa/v1/configuration`)
            .then(response => response.json() as Promise<Paths.SpaV1Configuration.Get.Responses.$200>);
        this._appConfigInitialized = true;
    }
}

const configurationService = new ConfigurationService();

export default configurationService;