import * as React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Home} from './Home';
import Cluster from '../components/Cluster';
import Tracks from '../components/Tracks';
import DuplicatesSearch from '../components/DuplicatesSearch';
import ApiKeys from "../components/ApiKeys";
import Matches from '../components/Matches';
import {NotFound} from './NotFound';
import Clients from './Clients';
import {QueryMatchPage} from "./QueryMatchPage";
import IRouteProps from "../common/IRouteProps";
import Layout from "./Layout";
import Tagging from "../components/Tagging";
import Billing from "../components/Billing";
import configurationService from "../common/services";
import ExternalStreams from "./streams/ExternalStreams";
import TVStreams from "./streams/TVStreams";

interface IClientProps extends IRouteProps {}

interface IClientState {
    ready: boolean,
    appConfig: Components.Schemas.AppConfiguration,
    tenantToken: string
}

class Client extends React.Component<IClientProps, IClientState> {

    constructor(props: IClientProps) {
        super(props);
        this.state = {
            appConfig: {
                emy: {
                    communityEdition: true,
                    previewLink: '',
                    serverMode: "Standard",
                    substitute: {},
                    datePattern: '',
                    apiHostname: '',
                    jsonApiPort: 3340,
                    dotNetApiPort: 3399,
                    enableTvStreams: false
                },
                tagging: {
                    datePattern: '',
                    previewLink: ''
                },
                duplicates: {
                    datePattern: '',
                    previewLink: ''
                },
                stripe: {
                    publishableKey: ''
                }
            },
            tenantToken: '',
            ready: false
        };
    }
    
    async componentDidMount(): Promise<void> {
        let appConfig = await configurationService.getAppConfig();
        let tenantToken = await configurationService.getTenantToken();
        this.setState({
            appConfig: appConfig,
            tenantToken: tenantToken,
            ready: true
        });
    }

    render() {
        const {ready, appConfig, tenantToken} = this.state;
        if(!ready) {
            return <div>Loading configurations...</div>
        }
            
        return (
            <Layout communityEdition={appConfig.emy?.communityEdition ?? false} enableTvStreams={appConfig.emy?.enableTvStreams ?? false}>
                <Switch>
                    <Route exact path={`/`} render={(props) => <Home {...props} tenantToken={tenantToken} appConfig={appConfig.emy!} /> } />
                    <Route exact path={`/matches`} render={(props) => <Matches {...props} tenantToken={tenantToken} appConfig={appConfig.emy!} /> } />
                    <Route exact path={`/matches/:matchId`} render={ (props) => <QueryMatchPage {...props} tenantToken={tenantToken} appConfig={appConfig.emy!} /> } />
                    <Route exact path={`/cluster`} render={ (props) => <Cluster {...props} tenantToken={tenantToken} appConfig={appConfig.emy!} /> } />
                    <Route exact path={`/tracks`} render={ (props) => <Tracks {...props} tenantToken={tenantToken} appConfig={appConfig.emy!} /> } />
                    <Route exact path={`/adsSearch`} render={(props) => <DuplicatesSearch {...props} tenantToken={tenantToken} /> } />
                    <Route exact path={`/tagging`} render={(props) =>  <Tagging {...props} tenantToken={tenantToken} taggingConfiguration={appConfig.tagging!} /> } />
                    <Route exact path={`/clients`} render={(props) => <Clients {...props} tenantToken={tenantToken} /> } />
                    <Route exact path={`/billing`} render={(props) => <Billing {...props} tenantToken={tenantToken} stripeConfig={appConfig.stripe!} /> } />
                    <Route exact path={'/api-keys'} render={(props) => <ApiKeys {...props} tenantToken={tenantToken} /> } />
                    <Route exact path={'/us-streams'} render={(props) => <TVStreams {...props} tenantToken={tenantToken}  /> } />
                    <Route exact path={'/external-streams'} render={(props) => <ExternalStreams {...props} tenantToken={tenantToken}  /> } />
                    <Route component={NotFound}/>
                </Switch>
            </Layout>);
    }
}

export default withRouter(Client);
