import * as React from 'react';
import {Product, SubscriptionCreated, SubscriptionError} from "./billing/billing-types";
import {loadStripe, Stripe, StripeElements} from "@stripe/stripe-js";
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {FormEvent} from "react";
import ErrorMessage from "./ErrorMessage";
import SubscriptionStore from "./stores/SubscriptionStore";

interface IPaymentFormProps {
    stripeConfig: Components.Schemas.StripeConfiguration,
    product: Product,
    tenantToken: string,
    subscriptionStore: SubscriptionStore,
    elements: StripeElements,
    stripe: Stripe
}

interface IPaymentFormState {
    subscribing: boolean,
    errorToDisplay: string,
    firstLastName: string
}

/**
 * Payment form handles the following use cases
 * 1. No previous subscription, customer is asked to enter Card details to get a subscription
 * 2. Customer has a previous subscription with latest_invoice?.payment_intent?.status === requires_payment_method
 *   - This happens either during first time subscription creation, when card is declined for payment reasons (i.e. 3D authentication, or card is declined by the bank)
 *   - During recurring subscription payments card is rejected
 *   In these cases we need to perform the so called PAYMENT FAILURE FLOW, ask for new card and confirmCardPayment() that will try paying the invoice.
 * 3. Customer has a previous subscription with latest_invoice?.payment_intent?.status === requires_action
 *   - Happens when 3D secure action is required on recurring invoice payment
 *   In this case we need to finalize payment setup, no need to ask for new credit card details.
 * 4. Customer has a previous subscription with pending_setup_intent != null
 *   - Happens when the 3D secure setup didn't finalize correctly last time we tried to perform it.
 *   In this case we need to finalize payment setup, no need to ask for new credit card details similar to point 3 & 4
 
 *   Since 3 & 4 requires payment reconfirmation which generates too many edge cases, we will ask for the Credit Card details once again.
 *   For cases 3 & 4 Stripe will send emails asking for the customer to finalize the payment.
 *   In our case, when the customer log-in in the backoffice he will notice a warning notifying that customer action is required, though we will ask for credit card details anyways.
 */

class PaymentForm extends React.Component<IPaymentFormProps, IPaymentFormState> {

    constructor(props: IPaymentFormProps) {
        super(props);

        this.state = {
            subscribing: false,
            errorToDisplay: '',
            firstLastName: ''
        }

        this.handleNameChanged = this.handleNameChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createSubscription = this.createSubscription.bind(this);
        this.handleCardSetupRequired = this.handleCardSetupRequired.bind(this);
        this.handlePaymentThatRequiresCustomerAction = this.handlePaymentThatRequiresCustomerAction.bind(this);
        this.handleRequiresPaymentMethod = this.handleRequiresPaymentMethod.bind(this);
        this.onSubscriptionComplete = this.onSubscriptionComplete.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    async handleSubmit(event: FormEvent) {

        event.preventDefault();

        this.setState({
            subscribing: true,
            errorToDisplay: ''
        });

        const {elements, stripe} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!
        });

        if (error) {
            console.log('[CREATE_PAYMENT_METHOD]', error);
            this.setState({
                subscribing: false,
                errorToDisplay: error?.message ?? 'Could not create payment method. Please contact support.'
            });
            return;
        }

        console.log('[PAYMENT_METHOD]', paymentMethod);
        const paymentMethodId = paymentMethod!.id;

        const {tenantToken} = this.props;
        // Attach payment method to current customer
        let response = await fetch(`/spa/v1/customer/payment`, {
            method: "PATCH",
            headers: {
                'Authorization': `Basic ${tenantToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({stripePaymentMethodId: paymentMethodId})
        });

        if (!response.ok) {
            let error = await response.json();
            console.log('[CUSTOMER PAYMENT ATTACH]', error);
            this.setState({
                subscribing: false,
                errorToDisplay: error.message
            });
            return;
        }

        const updatedCustomer = await response.json();

        const {product} = this.props;
        // we can only have one subscription for the same price
        // let's see if the customer has already created the subscription in previous subscription flows
        const subscription: any | undefined = updatedCustomer.subscriptions.data.find((subscription: any) => {
            return subscription.items.data[0].plan.id === product.stripePriceId;
        });

        let createOrUsePreviousSubscription: Promise<SubscriptionCreated> = subscription ?
            Promise.resolve(new SubscriptionCreated(subscription, paymentMethodId, product.stripePriceId ?? '', true))
            : this.createSubscription(paymentMethodId);

        createOrUsePreviousSubscription
            // Some payment methods require a customer to do additional
            // authentication with their financial institution.
            // Eg: 2FA for cards.
            .then(this.handleCardSetupRequired)
            // Some payments require SMS validation before payment is setup
            .then(this.handlePaymentThatRequiresCustomerAction)
            // If attaching this card to a Customer object succeeds,
            // but attempts to charge the customer fail. You will
            // get a requires_payment_method error.
            .then(this.handleRequiresPaymentMethod)
            // no more actions required. Provision your service for the user.
            .then(this.onSubscriptionComplete)
            .catch(this.handleError);
    }

    createSubscription(paymentMethodId: string): Promise<SubscriptionCreated> {
        const {product, tenantToken} = this.props;

        console.log('[CREATE SUBSCRIPTION]', product);

        const stripePriceId = product?.stripePriceId ?? '';
        let createSubscriptionBody = {
            stripePriceId: stripePriceId,
            stripePaymentMethodId: paymentMethodId
        };

        return fetch(`/spa/v1/subscriptions`, {
            method: "POST",
            headers: {
                'Authorization': `Basic ${tenantToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(createSubscriptionBody)
        }).then(async (response) => {
            if (response.ok) {
                return await response.json();
            }

            let error = await response.json();
            throw new SubscriptionError(error.message, undefined);
        }).then(subscription => {
            return new SubscriptionCreated(subscription, paymentMethodId, product?.stripePriceId || '', false);
        });
    }

    handleCardSetupRequired(subscriptionCreated: SubscriptionCreated): Promise<SubscriptionCreated> {
        const {stripe} = this.props;
        const {subscription, stripePaymentMethodId} = subscriptionCreated;
        let setupIntent = subscription.pending_setup_intent;
        if (setupIntent && setupIntent.status === 'requires_action') {
            // setup intent requires validation
            return stripe
                .confirmCardSetup(setupIntent.client_secret, {payment_method: stripePaymentMethodId})
                .then(result => {
                    if (result.error) {
                        throw new SubscriptionError(result.error.message ?? "Could not confirm your card.", subscription.id);
                    }

                    return subscriptionCreated;
                });
        }

        return Promise.resolve(subscriptionCreated);
    }

    handlePaymentThatRequiresCustomerAction(subscriptionCreated: SubscriptionCreated) {
        const {stripe} = this.props;
        const {subscription, stripePaymentMethodId} = subscriptionCreated;
        if (subscription && subscription.status === 'active') {
            // subscription is active, no customer actions required.
            return subscriptionCreated;
        }
        
        // if it's a first payment attempt, the payment intent is on the subscription latest invoice.
        const paymentIntent = subscription.latest_invoice.payment_intent;
        if (paymentIntent?.status === 'requires_action' || (subscriptionCreated.subscriptionUpdated && paymentIntent?.status === 'requires_payment_method')) {
            return stripe
                .confirmCardPayment(paymentIntent.client_secret, {payment_method: stripePaymentMethodId})
                .then((result) => {
                    if (result.error) {
                        // start code flow to handle updating the payment details
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        throw new SubscriptionError(result.error.message || "Card declined", subscription.id);
                    } else {
                        if (result.paymentIntent?.status === 'succeeded') {
                            // There's a risk of the customer closing the window before callback
                            // execution. To handle this case, set up a webhook endpoint and
                            // listen to invoice.payment_succeeded. This webhook endpoint returns an Invoice.
                            return subscriptionCreated;
                        }

                        return subscriptionCreated;
                    }
                });
        }

        // No customer action needed
        return subscriptionCreated;
    }

    handleRequiresPaymentMethod(subscriptionCreated: SubscriptionCreated) {
        const {subscription} = subscriptionCreated;
        if (subscription.status === 'active') {
            // subscription is active, no customer actions required.
            return subscriptionCreated;
        } 
        else if(subscriptionCreated.subscriptionUpdated)
        {
            // subscription object is just not updated yet here
            // through it's internal Stripe status will be active
            return subscriptionCreated;
        }
        else if (subscription.latest_invoice && subscription.latest_invoice.payment_intent && subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
            throw new SubscriptionError("Your card was declined.", subscription.id);
        } else {
            return subscriptionCreated;
        }
    }

    onSubscriptionComplete(subscriptionCreated: SubscriptionCreated) {
        // Payment was successful. Provision access to your service.
        // Cleanup resources if need be.
        this.setState({
            subscribing: false
        });
        
        // notifying parent components that payment has succeeded, and we need to update the UI to reflect 
        // latest changes in Stripe subscription
        const {subscriptionStore} = this.props;
        subscriptionStore.subscriptionUpdated = true;
 
        // subscription object is not up-to-date at the end of this flow
        // if you want to make any assumptions in regards to it's validity, then read it again from the customer
        console.log('[SUBSCRIPTION CREATED]', subscriptionCreated);
        return subscriptionCreated
    }

    handleNameChanged(element: HTMLInputElement) {
        this.setState({
            firstLastName: element.value
        });
    }

    handleError(error: SubscriptionError) {
        console.log('[CREATE_SUBSCRIPTION_ERROR]', error);
        this.setState({
            subscribing: false,
            errorToDisplay: error.message
        });
    }

    render() {
        const {subscribing, errorToDisplay, firstLastName} = this.state;
        const {product} = this.props;
        return <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Payment powered by Stripe</h4>
                        <footer className="blockquote-footer">we do not store sensitive payment information on our servers</footer>
                        <p>Enter your card details.</p>
                        <p>Your subscription will start now.</p>
                        <p>
                            → Total {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: product.currency
                        }).format(product?.tier1Price ?? 0)}
                        </p>
                        <p>
                            → Subscribing to{' '}<b>{product?.name}</b>
                        </p>

                        <form id="payment-form" onSubmit={(event) => this.handleSubmit(event)}>
                            <div className="form-group">
                                <label htmlFor="name">First and last name</label>
                                <input id="name" className="form-control" value={firstLastName}
                                       onChange={(event) => this.handleNameChanged(event.target)}
                                       type="text"
                                       placeholder="Example: John Smith" min={1}/>
                            </div>
                            <br/>
                            <div id="card-element">
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#32325d',
                                                fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                                                '::placeholder': {
                                                    color: '#a0aec0',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </div>
                            <br/>
                            <div>
                                <ErrorMessage errorToDisplay={errorToDisplay} />
                            </div>
                            <br/>
                            <div className="text-center">
                                <img className="m-3" width="30px" src='/scb_small.png'  alt="Stripe climate badge"/>
                                <p className="text-muted">
                                    EmySound, LLC will contribute 1% of your purchase to remove CO₂ from the atmosphere.
                                </p>
                            </div>
                            <br/>
                            <button className="btn btn-success float-end"
                                    type="submit"
                                    disabled={subscribing}>{subscribing ? 'Subscribing...' : 'Subscribe'}</button>
                        </form>
                    </div>
                </div>
    }
}

const PaymentFormElement = (props: any) => {
    const stripePromise = loadStripe(props.stripeConfig.publishableKey);
    return (
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <PaymentForm stripe={stripe} elements={elements} {...props} />
                )}
            </ElementsConsumer>
        </Elements>);
}

export default PaymentFormElement;


