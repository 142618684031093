import * as React from 'react';
import 'isomorphic-fetch';

import IRouteProps from '../common/IRouteProps';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from "../common/Loader";

interface IHomeProps extends IRouteProps {
    tenantToken: string
    appConfig: Components.Schemas.EmyConfiguration,
}

interface IHomeState {
    data: Components.Schemas.HomeData,
    loading: boolean
}

export class Home extends React.Component<IHomeProps, IHomeState> {

    constructor(props: IHomeProps) {
        super(props);
        
        this.state = {
            data: {
                header: "Welcome to EmySound",
                plan: "Free",
                tracks: 0,
                last30DaysUsageInfo: {
                    httpStreams: 0,
                    queryRequestsCount: 0,
                    usedSpaceSeconds: 0,
                    usedUnits: 0,
                    relativeUsedHttpStreams: 0,
                    relativeUsedSpaceSeconds: 0
                }
            },
            loading: false
        };
    }
    
    async componentDidMount(): Promise<void> {
        let {tenantToken} = this.props;
        this.setState({loading: true});
        fetch(`/spa/home`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Components.Schemas.HomeData>)
        .then(response => {
            this.setState({
                data:response,
                loading: false
            });
        })
        .catch(error => {
            this.setState({loading: false});
            throw error;
        });
    }

    public render() {
        const {header, tracks, plan, last30DaysUsageInfo} = this.state.data;
        const {loading} = this.state;
        return (<React.Fragment>
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>{header}</h1>
                    <hr />
                    <div className="card-deck m-1">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Overview</h5>
                                {
                                    loading ? <Loader /> :
                                    <ul className="list-group list-group-flush">
                                        {!this.props.appConfig.communityEdition ?
                                            <li className="list-group-item fw-light">
                                                <div className="row">
                                                    <div className="col-6">Used units in last 30 days</div>
                                                    <div className="col-6">
                                                        {last30DaysUsageInfo?.usedUnits}
                                                    </div>
                                                </div>
                                            </li> : null
                                        }
                                        <li className="list-group-item fw-light">
                                            <div className="row">
                                                <div className="col-6">Tracks</div>
                                                <div className="col-6">
                                                    {tracks}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item fw-light">
                                            <div className="row">
                                                <div className="col-6">Hours stored</div>
                                                <div className="col-6">
                                                    {Number((last30DaysUsageInfo?.usedSpaceSeconds ?? 0) / 3_600).toFixed(1)}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item fw-light">
                                            <div className="row">
                                                <div className="col-6">Requests in last 30 days</div>
                                                <div className="col-6">
                                                    {last30DaysUsageInfo?.queryRequestsCount}
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item fw-light">
                                            <div className="row">
                                                <div className="col-6">Payment plan</div>
                                                <div className="col-6">
                                                    {plan}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-group">
                        <div className="card m-1">
                            <div className="card-body">
                                <h5 className="card-title">View matched results</h5>
                                <p className="card-text">Search through the results gathered by querying the datastore</p>
                                <p className="card-text float-end">
                                    <Link to={`/matches`} className="btn btn-primary">Matches</Link>
                                </p>
                            </div>
                        </div>
                        <div className="card m-1">
                            <div className="card-body">
                                <h5 className="card-title">View stored tracks</h5>
                                <p className="card-text">Search through stored tracks.</p>
                                <p className="card-text float-end">
                                    <Link to={`/tracks`} className="btn btn-primary">Tracks</Link>
                                </p>
                            </div>
                        </div>
                        <div className="card m-1">
                            <div className="card-body">
                                <h5 className="card-title">View monitoring streams</h5>
                                <p className="card-text">Check broadcasting streams</p>
                                <p className="card-text float-end">
                                    <Link to={`/streams`} className="btn btn-primary">Streams</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            
        </React.Fragment>);
    }
}

export default withRouter(Home);
