import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import TrackDetails from "./TrackDetails";
import React from "react";

interface IModelTrackProps {
    track: Components.Schemas.TrackInfo | undefined,
    toggleModal: () => void;
}

export default class ModalTrack extends React.Component<IModelTrackProps, any> {
    
    render() {
        const {track, toggleModal} = this.props;
        return <Modal isOpen={track !== undefined} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>{track?.title ?? ""} {(track?.artist ?? "")}</ModalHeader>
            <ModalBody className="match-modal">
                <TrackDetails track={track!} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggleModal}>Close</Button>{' '}
            </ModalFooter>
        </Modal>
    }
}