import * as React from 'react';
import moment from "moment";
import {Product} from "./billing/billing-types";
import PaymentForm from "./PaymentForm";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SubscriptionStore from "./stores/SubscriptionStore";
import ErrorMessage from "./ErrorMessage";

interface ISubscriptionState {
    product: Product | undefined,
    addNewPaymentMethod: boolean,
    isOpen: boolean,
    cancelling: boolean,
    cancelError: string,
    upcomingInvoice: any | undefined,
    errorToDisplay: string
}

interface ISubscriptionProps {
    stripeConfig: Components.Schemas.StripeConfiguration,
    subscription: any,
    tenantToken: string,
    subscriptionStore: SubscriptionStore
}

export default class Subscription extends React.Component<ISubscriptionProps, ISubscriptionState> {
    constructor(props: ISubscriptionProps) {
        super(props);
        
        this.state = {
            product: undefined,
            addNewPaymentMethod: false,
            isOpen: false,
            cancelling: false,
            cancelError: '',
            upcomingInvoice: undefined,
            errorToDisplay: ''
        }
        
        this.handleNewPaymentMethodAdded = this.handleNewPaymentMethodAdded.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    
    componentDidMount() {
        const {subscription, tenantToken} = this.props;
        let stripePriceId = subscription.items.data[0].plan.id
        fetch(`/spa/v1/products/${stripePriceId}`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async (result) => {
            if(result.ok) {
                return await result.json() as Product;
            }
            
            let error = await result.json();
            throw new Error(error.message);
        })
        .then(product => {
            this.setState({
                product: product
            })
        }).catch(error => {
            this.setState({
                errorToDisplay: error.message
            });
        });

        fetch(`/spa/v1/subscriptions/${subscription.id}/upcoming-invoice`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async (result) => {
            if(result.ok) {
                let upcomingInvoice = await result.json();
                this.setState({
                    upcomingInvoice: upcomingInvoice
                })
            }
        })
    }
    
    handleNewPaymentMethodAdded() {
        this.setState({
            addNewPaymentMethod: true
        })
    }
    
    handleCancel() {
        this.setState({
            cancelling: true
        });

        const {subscription, tenantToken} = this.props;
        fetch(`/spa/v1/subscriptions/${subscription.id}`, {
            method: 'DELETE',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(async (response) => {
            this.setState({
                cancelling: false
            });
            
            if(!response.ok) {
                let error = await response.json();
                this.setState({
                    cancelError: error.message
                });
                
                return;
            }
            
            this.props.subscriptionStore.subscriptionUpdated = true;
            this.toggle();
        })
    }
    
    toggle() {
        const {isOpen} = this.state;
        this.setState({
            isOpen: !isOpen
        });
    }
    
    render() {
        const {subscription} = this.props;
        const {product, addNewPaymentMethod, isOpen, cancelling, cancelError, upcomingInvoice, errorToDisplay} = this.state;
        const totalUnits = upcomingInvoice?.lines.data.length > 0 ? upcomingInvoice?.lines.data.map((line:any) => line.quantity).reduce((a:number, b:number) => a + b) : 0;
        
        return <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Subscription</h4>
                        <ErrorMessage errorToDisplay={errorToDisplay} />
                        <div className="row mb-2">
                            <div className="col-4">
                                {
                                    subscription.status === 'active'? <span className="badge rounded-pill bg-primary">{subscription.status}</span> :
                                        <span className="badge rounded-pill bg-warning">{subscription.status}</span>
                                }
                            </div>
                        </div>
                        {
                            subscription.latest_invoice?.payment_intent?.status === "requires_payment_method" ?
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <div className="alert alert-danger" role="alert">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>We are sorry, there was an error processing your payment. Please try again with a different payment method</p>
                                                    <button className="btn btn-sm btn-primary float-end" onClick={this.handleNewPaymentMethodAdded}>Add new payment method</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                : (subscription?.latest_invoice?.payment_intent?.status === "requires_action" || 
                                      subscription.pending_setup_intent?.status === 'requires_payment_method' || 
                                      subscription.pending_setup_intent?.status === 'requires_action') ?
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <div className="alert alert-warning" role="alert">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p>We are sorry, there was an error setting up your payment. Please continue setup or try again with a different payment method</p>
                                                        <button className="btn btn-sm btn-primary float-end" onClick={this.handleNewPaymentMethodAdded}>Add new payment method</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                        }
                        <div className="row mb-2">
                            <div className="col-6">
                                Plan
                            </div>
                            <div className="col-6">
                                {product?.name}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                Started
                            </div>
                            <div className="col-6">
                                {moment.unix(subscription.start_date).format('LLLL')}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                Card
                            </div>
                            <div className="col-6">
                                {subscription.default_payment_method?.card?.brand.toUpperCase()} •••• {subscription.default_payment_method?.card?.last4}
                            </div>
                        </div>
                        {
                            upcomingInvoice ?
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <p>Upcoming Invoice</p>
                                        <p>Next payment date {moment.unix(upcomingInvoice.next_payment_attempt).format('LL')}</p>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                upcomingInvoice.lines.data.map((line: any) => {
                                                    return <tr key={line.id}>
                                                        <td>{line.description}</td>
                                                        <td>{line.quantity}</td>
                                                        <td>{new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: line.currency
                                                        }).format(line.amount / 100)}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            <tr>
                                               <td>Totals</td>
                                               <td>{totalUnits}</td>
                                                <td>{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: upcomingInvoice.currency
                                                }).format(upcomingInvoice.total / 100)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null
                        }
                        <div className="row mb-2">
                            <div className="col-6"/>
                            <div className="col-6">
                                <button className="btn btn-sm btn-danger float-end mr-3" onClick={this.toggle}>Cancel</button>
                                <Modal isOpen={isOpen} toggle={this.toggle} size="lg">
                                    <ModalHeader toggle={this.toggle}>Cancelling Subscription</ModalHeader>
                                    <ModalBody className="match-modal">
                                        Sorry to see you go.
                                        We will keep your data for 30 days before permanentely deleting it.
                                        {
                                            cancelError !== '' ?
                                                <div className="alert alert-danger" role="alert">
                                                    {cancelError}
                                                </div> : null
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
                                        <Button color="danger" onClick={this.handleCancel} disabled={cancelling}>
                                            {
                                                cancelling ?
                                                    <React.Fragment>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                        Cancelling...
                                                    </React.Fragment>
                                                     : <span>Cancel Subscription</span>
                                            }
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                        {
                            addNewPaymentMethod ? <PaymentForm product={this.state.product} 
                                                               tenantToken={this.props.tenantToken} 
                                                               stripeConfig={this.props.stripeConfig}
                                                               subscriptionStore={this.props.subscriptionStore} /> : null
                        }
                    </div>
                </div>;
    }
}