import * as React from 'react';
import IRouteProps from "../common/IRouteProps";
import {withRouter} from "react-router";
import PricePlans from "./PricePlans";
import Subscription from "./Subscription";
import Invoices from "./Invoices";
import ErrorMessage from "./ErrorMessage";
import SubscriptionStore from "./stores/SubscriptionStore";
import {Lambda, observe} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

interface IBillingState {
    loading: boolean;
    productName: string,
    paidPlan: boolean,
    stripeCustomerId?: string,
    customer: any,
    subscriptions: Array<any>,
    errorToDisplay: string
}

interface IBillingProps extends IRouteProps {
    stripeConfig: Components.Schemas.StripeConfiguration,
    tenantToken: string
}

class Billing extends React.Component<IBillingProps, IBillingState> {
    
    disposer: Lambda | undefined;
    subscriptionStore: SubscriptionStore;
    
    constructor(props: IBillingProps) {
        super(props);
        this.state = {
            loading: true,
            stripeCustomerId: '',
            paidPlan: false,
            productName: '',
            customer: {},
            subscriptions: [],
            errorToDisplay: ''
        }
        
        this.subscriptionStore = new SubscriptionStore();
        this.getBillingData = this.getBillingData.bind(this);
        this.getCustomersSubscriptions = this.getCustomersSubscriptions.bind(this);
    }

    async componentDidMount() {
        await this.getBillingData();
        this.disposer = observe(this.subscriptionStore, 'subscriptionUpdated', change => {
            if (change.newValue === true) {
                this.getBillingData()
                    .then(_ => {
                        this.subscriptionStore.subscriptionUpdated = false;
                    })
            }
        });
    }
    
    async componentWillUnmount() {
        if (this.disposer) {
            this.disposer();
        }
    }

    getBillingData(): Promise<any> {
        this.setState({
            loading: true
        });

        const {tenantToken} = this.props;
        return fetch(`/spa/v1/billing`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => response.json() as Promise<Components.Schemas.BillingData>)
        .then(billingData => {
            this.setState({
                productName: billingData.productName,
                paidPlan: billingData.paidPlan,
                stripeCustomerId: billingData.stripeCustomerId
            }, async () => {
                if (billingData.stripeCustomerId) {
                    await this.getCustomersSubscriptions();
                }

                this.setState({
                    loading: false
                });
            });
        })
        .catch(() => {
            this.setState({
                loading: false
            });
        })
    }

    getCustomersSubscriptions() : Promise<any> {
        const {tenantToken} = this.props;
        return fetch(`/spa/v1/customer`, {
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            throw new Error("Could not retrieve current billing details. Please contact support.");
        })
        .then(customer => {
            this.setState({
                customer: customer,
                subscriptions: customer.subscriptions.data
            });
        })
        .catch(error => {
            this.setState({
                errorToDisplay: error.message
            });
        }); 
    }

    render() {
        const {errorToDisplay, loading} = this.state;
        return <React.Fragment>
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>Billing</h1>
                    <hr/>
                    <ErrorMessage errorToDisplay={errorToDisplay} />
                    {
                        loading ?
                            <div className="card">
                                <div className="card-body kaiser-list-group">
                                    <h5 className="text-center m-0">
                                        <FontAwesomeIcon icon={faSpinner} spin/> Loading...
                                    </h5>
                                </div>
                            </div> : this.renderSubscriptionDetails()
                    }
                    
                </div>
            </div>
        </React.Fragment>
    }
    
    renderSubscriptionDetails() {
        const {tenantToken, stripeConfig} = this.props;
        const {subscriptions, paidPlan, stripeCustomerId, productName} = this.state;
        return <>
            {
                !paidPlan ?
                    <div className="card mb-3">
                        <div className="card-body">
                            <PricePlans tenantToken={tenantToken} stripeConfig={stripeConfig}
                                        subscriptionStore={this.subscriptionStore}/>
                        </div>
                    </div> : null
            }
            {
                subscriptions.length > 0 ?
                    subscriptions.map(subscription => {
                        return <div key={subscription.id} className="row">
                            <div className="col-6">
                                <Subscription
                                    subscription={subscription}
                                    tenantToken={tenantToken}
                                    stripeConfig={stripeConfig}
                                    subscriptionStore={this.subscriptionStore}/>
                            </div>
                        </div>
                    }) : paidPlan ? <div className="row mt-3">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <p className="m-0">
                                        You are subscribed to Emysound {productName}.
                                    </p>
                                    <small>
                                        No Stripe subscription is attached to the account.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
            {
                stripeCustomerId ?
                    <div className="row mt-3">
                        <div className="col-12">
                            <Invoices stripeConfig={stripeConfig} tenantToken={tenantToken}/>
                        </div>
                    </div> : null
            }
        </>
    }
}

export default withRouter(Billing);
