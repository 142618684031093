import React, {Fragment} from 'react';
import 'isomorphic-fetch';
import moment from 'moment';
import {buildPreviewLinkForDuplicatesForExternalPlayer, buildPreviewWindowForDuplicatesWithExternalPlayer} from '../common/common';
import {PlayerControls} from "./PlayerControls";
import PlayerControlsStore from "./stores/PlayerControlsStore";
import {computed} from "mobx";
import {observer} from "mobx-react";

interface ITaggingMatchProps {
    match: Components.Schemas.Match;
    previewLinkPattern: string;
    datePattern: string;
}

interface ITaggingMatchState {
    reviewing: boolean;
}

@observer
export class TaggingMatch extends React.Component<ITaggingMatchProps, ITaggingMatchState> {

    playerControlsStore: PlayerControlsStore = new PlayerControlsStore();

    constructor(props: ITaggingMatchProps) {
        super(props);
        const {match} = props;

        this.state = {
            reviewing: false
        };

        this.playerControlsStore.controls.startsAt = moment.utc(match.startsAt).toDate();
        this.playerControlsStore.controls.endsAt = moment.utc(match.startsAt).add(match.length, "seconds").toDate();

        this.review = this.review.bind(this);
    }

    review() {
        this.setState({reviewing: !this.state.reviewing});
    }

    @computed get previewLink(): string {
        const {match} = this.props;
        const {previewLinkPattern, datePattern} = this.props;
        let startsAt = this.playerControlsStore.controls.startsAt;
        let endsAt = this.playerControlsStore.controls.endsAt;
        let streamId = match.streamId ?? '';
        return buildPreviewLinkForDuplicatesForExternalPlayer(previewLinkPattern, datePattern, streamId, startsAt, endsAt);
    }

    render() {
        const {match} = this.props;
        const {reviewing} = this.state;
        return <Fragment>
            <div className="row pt-2 px-2">
                <div className="col-1">
                    {match.streamId}
                </div>
                <div className="col-2">
                    {moment.utc(match.startsAt).toISOString()}
                </div>
                <div className="col-2">
                    {moment.duration(Number(match.length), "seconds").format("mm:ss.SS", {trim: false})}
                </div>
                <div className="col-3"/>
                <div className="col-4">
                    <button type="button" className="btn btn-primary btn-sm mx-1" onClick={this.review}>
                        {
                            !reviewing ?
                                <span className="glyphicon glyphicon-chevron-down">&nbsp;Review</span> :
                                <span className="glyphicon glyphicon-chevron-up">&nbsp;Close</span>
                        }
                    </button>
                </div>
            </div>
            {
                reviewing ?
                    <div className="row">
                        <div className="col-6">
                            {buildPreviewWindowForDuplicatesWithExternalPlayer(this.previewLink)}
                        </div>
                        <div className="col-6">
                            <PlayerControls store={this.playerControlsStore} title={match.streamId}/>
                        </div>
                    </div> : null
            }
        </Fragment>
    }
}
