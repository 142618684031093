import React, {Fragment} from 'react';
import 'isomorphic-fetch';
import moment from 'moment';
import {buildPreviewLinkForDuplicatesForExternalPlayer, buildPreviewWindowForDuplicatesWithExternalPlayer, IMatch} from '../common/common';
import {PlayerControls} from "./PlayerControls";
import PlayerControlsStore from "./stores/PlayerControlsStore";
import {computed} from "mobx";
import {observer} from "mobx-react";

interface IDuplicateMatchProps {
    match: IMatch;
    previewLinkPattern: string;
    datePattern: string;
}

interface IDuplicateMatchState {
    match: IMatch;
    previewLinkPattern: string;
    datePattern: string;

}

@observer
export class DuplicateMatch extends React.Component<IDuplicateMatchProps, IDuplicateMatchState> {

    playerControlsStore: PlayerControlsStore = new PlayerControlsStore();
    
    constructor(props: any) {
        super(props);

        this.state = {
            match: this.props.match,
            previewLinkPattern: this.props.previewLinkPattern,
            datePattern: this.props.datePattern
        };
        
        this.playerControlsStore.controls.startsAt = moment.utc(props.match.resultMatch.start).toDate();
        this.playerControlsStore.controls.endsAt = moment.utc(props.match.resultMatch.end).toDate();
    }

    @computed get previewLink(): string {
        let startsAt = this.playerControlsStore.controls.startsAt;
        let endsAt = this.playerControlsStore.controls.endsAt;
        let streamId = this.state.match.resultMatch.streamId;
        const {previewLinkPattern, datePattern} = this.state;
        return buildPreviewLinkForDuplicatesForExternalPlayer(previewLinkPattern, datePattern, streamId, startsAt, endsAt);
    }
    
    public render() {
        return <Fragment>
            <div className="col-6">
                {buildPreviewWindowForDuplicatesWithExternalPlayer(this.previewLink)}
            </div>
            <div className="col-6">
                <PlayerControls store = {this.playerControlsStore} title={this.state.match.resultMatch.streamId} />
            </div>
        </Fragment>;
    }
}