import * as React from 'react';
import IRouteProps from '../common/IRouteProps';
import {getEmptyAVQueryMatch} from "../common/common";
import QueryMatch from "../common/QueryMatch";

interface IQueryMatchPageState {
    queryMatch: Components.Schemas.AVQueryMatch,
    notFound: boolean,
    loading: boolean
}

interface IQueryMatchPageProps extends IRouteProps {
    appConfig: Components.Schemas.EmyConfiguration,
    tenantToken: string,
}

export class QueryMatchPage extends React.Component<IQueryMatchPageProps, IQueryMatchPageState> {
    constructor(props: IQueryMatchPageProps) {
        super(props);
        this.state = {
            queryMatch: getEmptyAVQueryMatch(),
            notFound: false,
            loading: true
        }
    }

    async componentDidMount(): Promise<void> {
        const {matchId} = this.props.match.params;
        const {tenantToken} = this.props;

        fetch(`/api/v1/matches/${matchId}`, {
            method: 'GET',
            headers: {'Authorization': `Basic ${tenantToken}`}
        })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<Paths.ApiV1Matches$AvQueryMatchId.Get.Responses.$200>;
                }

                return Promise.reject();
            })
            .then(result => {
                    this.setState({
                        queryMatch: result,
                        notFound: false,
                        loading: false
                    });
                },
                () => {
                    this.setState({
                        notFound: true,
                        loading: false
                    });
                });
    }

    render() {
        const {queryMatch, notFound, loading} = this.state;
        const {appConfig, tenantToken} = this.props;
        const {matchId} = this.props.match.params;
        return (
            <div className="row stacked-card">
                <div className="col-12">
                    <h1>Query Match {queryMatch.id}</h1>
                    <hr/>
                    {loading ? <p>Searching {matchId}</p> : notFound ? <p>Id {matchId} not found</p> : <QueryMatch appConfig={appConfig} avQueryMatch={queryMatch} tenantToken={tenantToken} /> }
                </div>
            </div>
        );
    }
}
